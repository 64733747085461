import { createSelector } from '@ngrx/store';
import { RequirementState } from '../requirement.state';
import {
  selectRequirementState,
  selectUnremovedRequirements
} from '../requirement.selectors';
import { requirementLockAdapter } from './requirement-lock.state';
import { selectProjectMembers } from '../../../project.selectors';
import { User } from '../../../../../shared/models/user';
import {
  Requirement,
  RequirementLock,
  RequirementLockView
} from '../../../../../shared/models/requirement-models';

/**
 * This selector selects the whole requirement lock state from the requirement store.
 */
export const selectRequirementLockState = createSelector(
  selectRequirementState,
  (state: RequirementState) => state.requirementLockState
);

/**
 * This selector selects all requirement locks from the store as requirement lock views.
 */
export const selectRequirementLockViews = () =>
  createSelector(
    selectAllRequirementLocks,
    selectUnremovedRequirements,
    selectProjectMembers,
    (
      locks: RequirementLock[],
      requirements: Requirement[],
      members: User[]
    ): RequirementLockView[] =>
      locks.map((lock: RequirementLock) => {
        const requirement = requirements.find(
          (req: Requirement) => req.id === lock.requirement
        );
        return {
          requirement: requirement.identifier + ': ' + requirement.name,
          user: members.find((member: User) => member.id === lock.user),
          lock_time: lock.lock_time
        };
      })
  );

/**
 * The default requirement lock store selectors provided by the requirement lock adapter.
 */
export const {
  selectAll: selectAllRequirementLocks,
  selectEntities: selectRequirementLockEntities,
  selectIds: selectRequirementLockIds,
  selectTotal: selectRequirementLocksTotal
} = requirementLockAdapter.getSelectors(selectRequirementLockState);

/**
 * This selector takes a requirement id and selects the corresponding requirement lock.
 */
export const selectRequirementLockByRequirementId = createSelector(
  selectAllRequirementLocks,
  (requirementLocks: RequirementLock[], props: { requirementId: number }) =>
    requirementLocks.find(
      (requirementLock: RequirementLock) =>
        requirementLock.requirement === props.requirementId
    )
);

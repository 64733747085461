import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { httpOptions } from '../miscellaneous/globals';
import { User } from '../models/user';
import { forkJoin, Observable, of } from 'rxjs';
import { Project, ProjectLock } from '../models/project-models';
import { AppConfigurationService } from '../configuration/app-configuration.service';
import { map } from 'rxjs/operators';

/**
 * The project service.
 */
@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  /**
   * The constructor of the ProjectsService.
   * @param http Dependency injection of the HttpClient
   * @param configService Dependency injection of the AppConfigurationService
   */
  constructor(
    private http: HttpClient,
    private configService: AppConfigurationService
  ) {}

  /**
   * Fetches the projects of a user from the backend.
   * @returns An observable containing the projects of the user.
   */
  public getUsersProjects(): Observable<Project[]> {
    return this.http.get<Project[]>(this.configService.url + 'projects/');
  }

  /**
   * Fetches the members of a project from the backend.
   * @param projectId The ID of the project for which the members will be fetched.
   * @returns An observable containing the members of the project.
   */
  public getProjectMembers(projectId: number): Observable<User[]> {
    return this.http.get<User[]>(
      this.configService.url + 'projects/' + projectId + '/members/'
    );
  }

  /**
   * NOTE: NOT IMPLEMENTED RIGHT NOW!! STUB ONLY
   * Fetches the project lock of a specific project from the backend.
   * @param projectId The project ID for which the project lock is to be fetched.
   * @returns An observable containing the fetched project lock.
   */
  public getProjectLock(projectId: number): Observable<ProjectLock> {
    return of(null); // project lock is currently not implemented (Therefore the project is never locked)
  }

  /**
   * Retrieves the full project from the server.
   * @param projectId The id of the project that shall be retrieved.
   */
  public getFullProject(projectId): Observable<any> {
    const project_url = this.configService.url + 'projects/' + projectId + '/';

    let project = this.http.get(project_url);
    let requirement_groups = this.http.get(project_url + 'requirement-groups/');
    let requirements = this.http.get(project_url + 'requirements/');
    let members = this.http.get(project_url + 'members/');
    let glossary = this.http.get(project_url + 'terms/');

    function buildFullProject([
      project,
      requirement_groups,
      requirements,
      members,
      glossary
    ]) {
      return {
        title: project['title'],
        supervisor: project['supervisor'],
        end_date: project['end_date'],
        members: members,
        requirement_groups: requirement_groups,
        requirements: requirements,
        requirement_locks: [],
        terms: glossary,
        analysis_results: [],
        lock: null
      };
    }

    return forkJoin([
      project,
      requirement_groups,
      requirements,
      members,
      glossary
    ]).pipe(map((results) => buildFullProject(results)));
  }

  /**
   * Adds a new member to a project.
   * @param email The email of the new member to be added.
   * @param projectId The id of the project the user should be added to.
   * @returns An observable containing the result of the addition.
   */
  public addProjectMember(email: string, projectId: number): Observable<any> {
    return this.http.post(
      this.configService.url + 'projects/' + projectId + '/members/',
      // TODO: Update role when implementing support for different permissions
      JSON.stringify({ user: email, project: projectId, role: 5 }),
      httpOptions
    );
  }

  /**
   * Initiates the deletion of a project in the backend.
   * @param projectId The ID of the project to be deleted.
   * @returns An observable containing the result of the deletion.
   */
  public deleteProjectOnServer(projectId: number): Observable<any> {
    return this.http.delete(
      this.configService.url + 'projects/' + projectId + '/'
    );
  }

  /**
   * Initiates the creation of a project in the backend.
   * @param data The data describing the new project.
   * @returns An observable containing the result of the creation.
   */
  public createProjectOnServer(data): Observable<any> {
    return this.http.post<Project>(
      this.configService.url + 'projects/',
      JSON.stringify(data),
      httpOptions
    );
  }

  /**
   * Initiates the update of a project in the backend.
   * @param projectId The ID of the project to be updated.
   * @param data The data describing the changes of the project.
   * @returns An observable containing the result of the update.
   */
  public updateProjectOnServer(projectId: number, data): Observable<any> {
    const body = JSON.stringify(data);
    return this.http.patch(
      this.configService.url + 'projects/' + projectId + '/',
      body,
      httpOptions
    );
  }

  /**
   * NOTE: NOT IMPLEMENTED RIGHT NOW!! STUB ONLY
   * Initiates the creation of a project lock in the backend.
   * @param projectLock The data describing the project lock to be created.
   * @returns An observable containing the result of the creation.
   */
  public createProjectLockOnServer(projectLock: ProjectLock): Observable<any> {
    const body = JSON.stringify(projectLock);
    // project lock is currently not implemented (Therefore the project is never locked)
    throw new Error('Project Lock is not implemented right now');
    // return this.http.post(
    //   this.configService.url + 'project-lock/',
    //   body,
    //   httpOptions
    // );
  }

  /**
   * NOTE: NOT IMPLEMENTED RIGHT NOW!! STUB ONLY
   * Initiates the deletion of a project lock in the backend.
   * @param projectId The ID of the project for which its project lock is to be deleted.
   * @returns An observable containing the result of the deletion.
   */
  public deleteProjectLockOnServer(projectId: number): Observable<any> {
    // project lock is currently not implemented (Therefore the project is never locked)
    // const options = {
    //   headers: httpOptions.headers,
    //   body: {
    //     projectId
    //   }
    // };
    //
    // return this.http.delete(
    //   this.configService.url + 'project/' + projectId + '/delete-project-lock',
    //   options
    // );
    return of(null);
  }
}

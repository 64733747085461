import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable, of } from 'rxjs';
import { User } from '../models/user';

/**
 * Used to manage the JWT of the logged in user in the local storage of the browser.
 */
@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  /**
   * The key by which the user data will be saved to the local storage.
   */
  private storageName = 'user';

  /**
   * The constructor of the LocalStorageService.
   */
  constructor(private cookieService: CookieService) {}

  /**
   * Used to parse the given user data and save the JWT and the refresh token in the local storage.
   * @param userData The data containing the information about the logged in user.
   */
  public setLoggedInUser(userData): void {
    const user: User = {
      id: userData.user.id,
      first_name: userData.user.first_name,
      last_name: userData.user.last_name,
      last_login: userData.user.last_login,
      username: userData.user.email,
      access_token: userData.token.access,
      refresh_token: userData.token.refresh
    };

    this.setToken('auth_token', userData.token.access);
    this.setRefreshToken(userData.token.refresh);
    localStorage.setItem(this.storageName, JSON.stringify(user));
  }

  /**
   * Saves the given key with value in the local storage of the browser and as cookie.
   * @param key
   * @param value
   */
  public setToken(key, value): void {
    localStorage.setItem(key, value);
  }

  /**
   * Saves the users jwt refresh token in the local storage of the browser.
   * @param refreshToken The users JWT refresh token.
   */
  public setRefreshToken(refreshToken): void {
    localStorage.setItem('refresh_token', refreshToken);
  }

  /**
   * Returns the users jwt token if saved in the local storage of the browser.
   * @returns The users JWT token.
   */
  public getToken(): string {
    return localStorage.getItem('auth_token');
  }

  /**
   * Returns the users jwt token if saved in the local storage of the browser.
   * @returns The users JWT refresh token.
   */
  public getRefreshToken(): string {
    return localStorage.getItem('refresh_token');
  }

  /**
   * Used to get the currently logged in user.
   * @returns The currently logged in user.
   */
  public getLoggedInUser(): Observable<string> {
    return of(localStorage.getItem(this.storageName));
  }

  /**
   * Removes the user from the local storage.
   */
  clearState(): void {
    localStorage.removeItem(this.storageName);
  }

  /**
   * Clears the whole local storage.
   */
  cleanAll(): void {
    localStorage.clear();
    this.cookieService.delete('auth_token');
  }
}

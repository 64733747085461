import { Component } from '@angular/core';

/**
 * Displays the privacy policy.
 */
@Component({
  selector: 'scone-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent {
  /**
   * The constructor of the privacy policy component.
   */
  constructor() {}
}

import { Component, HostBinding, OnInit } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import { AuthStoreService } from '../../shared/services/auth-store.service';

/**
 * The component for the navigation.
 */
@Component({
  selector: 'scone-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  /**
   * The currently logged in user.
   */
  public loggedInUser$;

  /**
   * The constructor of the NavigationComponent.
   * @param authStoreService Dependency injection of the AuthStoreService.
   * @param overlayContainer Dependency injection of the OverlayContainer.
   */
  constructor(
    private authStoreService: AuthStoreService,
    public overlayContainer: OverlayContainer
  ) {}

  /**
   * The lifecycle hook that is called after Angular has initialized all data-bound properties.
   * It sets the currently logged in user.
   */
  ngOnInit(): void {
    this.loggedInUser$ = this.authStoreService.selectAuthUser();
  }

  /**
   * Used to log out the user.
   */
  logout(): void {
    this.authStoreService.dispatchLogoutRequest();
  }
}

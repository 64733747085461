import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { User } from '../../shared/models/user';
import {
  initialRequirementRootState,
  RequirementRootState
} from './requirement-root-store/requirement-root.state';
import { GlossaryState } from './glossary-store';
import { initialGlossaryState } from './glossary-store/glossary.state';
import {
  AnalysisState,
  initialAnalysisState
} from './analysis-store/analysis.state';
import { WebsocketMessage } from '../../shared/models/websocketMessage';
import { Project, ProjectLock } from '../../shared/models/project-models';

/**
 * The feature key of the project store.
 */
export const projectFeatureKey = 'project';

/**
 * The interface of the project store state.
 */
export interface ProjectState extends EntityState<Project> {
  /**
   * The id of the currently open project.
   */
  currentProjectId: number;
  /**
   * The project of the currently open project.
   */
  projectLock: ProjectLock;
  /**
   * The members of the currently open project.
   */
  projectMembers: User[];
  /**
   * Indicates whether data is currently being loaded.
   */
  isLoading: boolean;
  /**
   * Indicates whether the data has been loaded successfully.
   */
  loaded: boolean;
  /**
   * The last error message that was received from the server.
   */
  errorMessage: string;
  /**
   * The last success message that was received from the server.
   */
  successMessage: string;
  /**
   * The last websocket message that was received.
   */
  websocketMessage: WebsocketMessage;
  /**
   * The requirement root state.
   */
  requirements: RequirementRootState;
  /**
   * The glossary state.
   */
  glossary: GlossaryState;
  /**
   * The analysis state.
   */
  analysis: AnalysisState;
}

/**
 * The adapter of the project store.
 */
export const projectAdapter: EntityAdapter<Project> =
  createEntityAdapter<Project>();

/**
 * The initial state of the project store.
 */
export const initialState: ProjectState = projectAdapter.getInitialState({
  currentProjectId: null,
  projectLock: null,
  projectMembers: null,
  isLoading: false,
  loaded: false,
  errorMessage: null,
  successMessage: null,
  websocketMessage: null,
  requirements: initialRequirementRootState,
  glossary: initialGlossaryState,
  analysis: initialAnalysisState
});

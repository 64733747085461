import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../models/user';
import { AuthActions, AuthSelectors, AuthState } from '../../store/auth-store';
import { Store } from '@ngrx/store';

/**
 * The auth store service.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthStoreService {
  /**
   * The constructor of the auth store service.
   * @param authStore Dependency injection of the AuthStore.
   */
  constructor(private authStore: Store<AuthState>) {}

  // ----------------------------------------------
  // SELECT FUNCTIONS
  // ----------------------------------------------

  /**
   * Calls the selectAuthUser selector in the AuthStore and
   * returns an observable of generic type User.
   * @returns Observable of generic type User
   * that emits the current value of user (slice of AuthState) from the AuthStore.
   */
  public selectAuthUser(): Observable<User> {
    return this.authStore.select(AuthSelectors.selectAuthUser);
  }

  /**
   * Calls the selectAuthIsAuthenticated selector in the AuthStore and
   * returns an observable of generic type boolean.
   * @returns Observable of generic type boolean
   * that emits the current value of isAuthenticated (slice of AuthState) from the AuthStore.
   */
  public selectIsAuthenticated(): Observable<boolean> {
    return this.authStore.select(AuthSelectors.selectIsAuthenticated);
  }

  /**
   * Gets the id of the currently logged in user
   * @returns The id of the currently logged in user
   */
  public getUserID(): number {
    let userID: number = null;
    this.selectAuthUser()
      .subscribe((user) => (userID = user.id))
      .unsubscribe();
    return userID;
  }

  // ----------------------------------------------
  // DISPATCH FUNCTIONS
  // ----------------------------------------------

  /**
   * Dispatches the LoginRequestAction from the AuthStore.
   * @param data The users login data. Consists of e-mail address and password.
   */
  public dispatchLoginRequest(data: any): void {
    this.authStore.dispatch(AuthActions.loginRequest(data));
  }

  /**
   * Dispatches the LogoutRequestAction from the AuthStore.
   */
  public dispatchLogoutRequest(): void {
    this.authStore.dispatch(AuthActions.logoutRequest());
  }

  /**
   * Dispatches the RefreshRequestAction from the AuthStore.
   * This is necessary to refill the store after a page reload.
   */
  public dispatchRefreshRequest(): void {
    this.authStore.dispatch(AuthActions.refreshRequest());
  }

  /**
   * Dispatches a SignUpRequestAction to the store.
   * @param user The user which is to be created.
   */
  public dispatchSignUp(user: User): void {
    this.authStore.dispatch(AuthActions.signUpRequest({ user }));
  }

  /**
   * Dispatches a SetErrorMessageAction to the store.
   * @param error The error message which is to be set.
   */
  public dispatchSetErrorMessage(error: string): void {
    this.authStore.dispatch(AuthActions.setErrorMessage({ error }));
  }
}

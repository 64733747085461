import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { RequirementGroup } from '../../../../shared/models/requirement-group-models';

/**
 * The interface of the requirement group store state.
 */
export interface RequirementGroupState extends EntityState<RequirementGroup> {
  /**
   * Indicates whether the data has been loaded successfully.
   */
  loaded: boolean;
  /**
   * Indicates whether data is currently being loaded.
   */
  isLoading: boolean;
  /**
   * The last error message that was received from the server.
   */
  errorMessage: string;
  /**
   * The last success message that was received from the server.
   */
  successMessage: string;
}

/**
 * The adapter of the requirement group store.
 */
export const requirementGroupAdapter = createEntityAdapter<RequirementGroup>();

/**
 * The initial state of the requirement group store.
 */
export const requirementGroupInitialState: RequirementGroupState =
  requirementGroupAdapter.getInitialState({
    loaded: false,
    isLoading: false,
    errorMessage: null,
    successMessage: null
  });

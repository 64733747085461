<form
  novalidate
  name="loginForm"
  [formGroup]="form"
  (ngSubmit)="login(form.value)"
>
  <mat-form-field>
    <mat-label>Email</mat-label>
    <input
      matInput
      [formControl]="form.controls['username']"
      autofocus
      required
    />
    <mat-icon matPrefix>alternate_email</mat-icon>
    <mat-error *ngIf="true">This field is required.</mat-error>
  </mat-form-field>
  <br />

  <mat-form-field>
    <mat-label>Password</mat-label>
    <input
      matInput
      type="password"
      [formControl]="form.controls['password']"
      required
    />
    <mat-icon matPrefix>lock_outline</mat-icon>
    <mat-error *ngIf="true">This field is required.</mat-error>
  </mat-form-field>
  <br />

  <button
    mat-raised-button
    color="primary"
    type="submit"
    [disabled]="!form.valid"
  >
    Login
  </button>
</form>

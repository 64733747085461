import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { RequirementLock } from '../../../../../shared/models/requirement-models';

/**
 * The interface of the requirement lock store state.
 */
export interface RequirementLockState extends EntityState<RequirementLock> {
  /**
   * Indicates whether the data has been loaded successfully.
   */
  loaded: boolean;
  /**
   * Indicates whether the data is currently being loaded.
   */
  isLoading: boolean;
  /**
   * The last error message that was received from the server.
   */
  errorMessage: string;
  /**
   * The last success message that was received from the server.
   */
  successMessage: string;
}

/**
 * The adapter of the requirement lock store.
 */
export const requirementLockAdapter = createEntityAdapter<RequirementLock>({
  selectId: (lock: RequirementLock) => lock.requirement
});

/**
 * The initial state of the requirement lock store.
 */
export const initialRequirementLockState: RequirementLockState =
  requirementLockAdapter.getInitialState({
    loaded: false,
    isLoading: false,
    errorMessage: null,
    successMessage: null
  });

import { createSelector } from '@ngrx/store';
import { selectProjectState } from '../project.selectors';
import { ProjectState } from '../project.state';

/**
 * This selector selects the whole requirement root state from the project store.
 */
export const selectRequirementRootState = createSelector(
  selectProjectState,
  (state: ProjectState) => state.requirements
);

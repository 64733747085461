import { Component } from '@angular/core';

/**
 * Custom 404 page that gets displayed when a page could not be found.
 */
@Component({
  selector: 'scone-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent {
  /**
   * The constructor of the page not found component.
   */
  constructor() {}
}

import { Component } from '@angular/core';

/**
 * The component that gets displayed upon opening scone. It shows
 * among other things the login/sign-up.
 */
@Component({
  selector: 'scone-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent {
  /**
   * The constructor of the landing page component.
   */
  constructor() {}
}

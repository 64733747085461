<form
  class="signupForm"
  [formGroup]="form"
  #formDirective="ngForm"
  (ngSubmit)="createUserAccount(form.value, formDirective)"
>
  <mat-form-field>
    <input
      matInput
      placeholder="First Name"
      [formControl]="form.controls['first_name']"
      required
      autofocus
    />
    <mat-icon matPrefix>person_outline</mat-icon>
    <mat-error
      *ngIf="
        form.controls['first_name'].invalid &&
        form.controls['first_name'].dirty &&
        form.controls['first_name'].touched
      "
    >
      You must enter a valid first name.
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <input
      matInput
      placeholder="Last Name"
      [formControl]="form.controls['last_name']"
      required
    />
    <mat-icon matPrefix>person_outline</mat-icon>
    <mat-error
      *ngIf="
        form.controls['last_name'].invalid &&
        form.controls['last_name'].dirty &&
        form.controls['last_name'].touched
      "
    >
      You must enter a valid last name.
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <input
      matInput
      placeholder="Email"
      [formControl]="form.controls['username']"
      required
    />
    <mat-icon matPrefix>alternate_email</mat-icon>
    <mat-icon
      matSuffix
      [matTooltipPosition]="'right'"
      matTooltip="You can only sign up using an email address ending
    with '@hochschule-trier.de' or '@fh-trier.de'."
      style="color: gray"
      >info
    </mat-icon>
    <mat-error
      *ngIf="
        form.controls['username'].invalid &&
        form.controls['username'].dirty &&
        form.controls['username'].touched
      "
    >
      You must enter a valid email address issued by the Hochschule Trier.
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <input
      matInput
      placeholder="Password"
      type="password"
      [formControl]="form.controls['password1']"
      required
    />
    <mat-icon matPrefix>lock_outline</mat-icon>
    <mat-icon
      matSuffix
      [matTooltipPosition]="'right'"
      matTooltip="Valid passwords have at
          least 12 characters, contain at least one letter and one number and may contain special characters."
      style="color: gray"
      >info
    </mat-icon>
    <mat-error
      *ngIf="
        form.controls['password1'].invalid &&
        form.controls['password1'].dirty &&
        form.controls['password1'].touched
      "
    >
      You must enter a valid password.
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <input
      matInput
      placeholder="Confirm password"
      type="password"
      [formControl]="form.controls['password2']"
      required
    />
    <mat-icon matPrefix>lock_outline</mat-icon>
    <mat-error
      *ngIf="
        (form.controls['password2'].invalid ||
          form.controls['password2'].errors?.MatchFields) &&
        form.controls['password2'].dirty &&
        form.controls['password2'].touched
      "
    >
      You must enter the same password again.
    </mat-error>
  </mat-form-field>

  <mat-checkbox [formControl]="form.controls['confirmation']">
    I have read and consent to the processing of my data as described in the
    <a target="_blank" routerLink="./privacy-policy">privacy policy</a>
  </mat-checkbox>

  <div style="font-size: 0.8em; color: #8c8c8c">* required</div>

  <button
    mat-raised-button
    color="primary"
    type="submit"
    [disabled]="!form.valid"
  >
    <mat-icon>person_add</mat-icon>
    Sign up
  </button>
</form>

import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { RequirementGroup } from '../../../../shared/models/requirement-group-models';

/**
 * Action for the creation of a requirement group, Takes a requirement group object.
 */
export const createRequirementGroupRequest = createAction(
  '[Requirements] Create Requirement Group Request',
  props<{ requirementGroup: RequirementGroup }>()
);
/**
 * Action that is triggered if the creation of requirement groups was successful.
 */
export const createRequirementGroupSuccess = createAction(
  '[Requirements] Create Requirement Group Success',
  props<{ requirementGroup: RequirementGroup }>()
);
/**
 * Action that is triggered if the creation of requirement groups failed.
 */
export const createRequirementGroupFailure = createAction(
  '[Requirements] Create Requirement Group Failure',
  props<{ error: string }>()
);
/**
 * Action for the update of a requirement group, Takes a Update<RequirementGroup> object.
 */
export const updateRequirementGroupRequest = createAction(
  '[Requirements] Update Requirement Group Request',
  props<{ requirementGroup: Update<RequirementGroup> }>()
);
/**
 * Action that is triggered if the update of requirement groups was successful.
 */
export const updateRequirementGroupSuccess = createAction(
  '[Requirements] Update Requirement Group Success',
  props<{ requirementGroup: RequirementGroup }>()
);
/**
 * Action that is triggered if the update of requirement groups failed.
 */
export const updateRequirementGroupFailure = createAction(
  '[Requirements] Update Requirement Group Failure',
  props<{ error: string }>()
);
/**
 * Action for the deletion of a requirement group, Takes a requirement group id.
 */
export const deleteRequirementGroupRequest = createAction(
  '[Requirements] Delete Requirement Group Request',
  props<{ id: number }>()
);
/**
 * Action that is triggered if the deletion of requirement groups was successful.
 */
export const deleteRequirementGroupSuccess = createAction(
  '[Requirements] Delete Requirement Group Success',
  props<{ requirementGroupId: number }>()
);
/**
 * Action that is triggered if the deletion of requirement groups failed.
 */
export const deleteRequirementGroupFailure = createAction(
  '[Requirements] Delete Requirement Group Failure',
  props<{ error: string }>()
);
/**
 * Action to retrieve all requirement groups of a project. Takes a project id.
 */
export const retrieveAllRequirementGroups = createAction(
  '[Requirements] Retrieve All Requirement Groups',
  props<{ projectId: number }>()
);
/**
 * Action that is triggered if the retrieval of all requirement groups was successful.
 */
export const setAllRequirementGroups = createAction(
  '[Requirements] Set All Requirement Groups',
  props<{ requirementGroups: RequirementGroup[] }>()
);

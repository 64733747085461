import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './core/page-not-found/page-not-found.component';
import { RootGuard } from './shared/guards/root.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [RootGuard],
    loadChildren: () => import('./core/core.module').then((m) => m.CoreModule)
  },
  {
    path: 'help',
    canActivate: [RootGuard],
    loadChildren: () => import('./help/help.module').then((m) => m.HelpModule)
  },
  {
    path: 'projects',
    canActivate: [RootGuard],
    loadChildren: () =>
      import('./project-list/project-list.module').then(
        (m) => m.ProjectListModule
      )
  },
  {
    path: 'project',
    canActivate: [RootGuard],
    loadChildren: () =>
      import('./project-list/project/project.module').then(
        (m) => m.ProjectModule
      )
  },
  { path: '**', canActivate: [RootGuard], component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}

/**
 * Returns the user's token. This function simply needs to make a retrieval
 * call to wherever the token is stored. In many cases, the token will be
 * stored in local storage or session storage.
 * @returns Returns the user's token.
 */
export function tokenGetter(): string {
  return localStorage.getItem('auth_token');
}

/**
 * Factory for generating the configuration for the JwtModule.
 * @returns an object containing the desired options under the 'options' property
 * and a method to update the allowedDomains.
 */
export function jwtConfigurationFactory(): {
  options: () => {
    tokenGetter: () => string;
    headerName: string;
    allowedDomains: any[];
    skipWhenExpired: boolean;
    authScheme: string;
  };
  updateAllowedDomains: (newAllowedDomains: [string]) => void;
} {
  const allowedDomains = [];

  /**
   * Clears the old entries and sets the allowed domains to the given value
   */
  function updateAllowedDomains(newAllowedDomains: [string]): void {
    while (allowedDomains.length > 0) {
      allowedDomains.pop();
    }

    allowedDomains.push(...newAllowedDomains);
  }

  return {
    updateAllowedDomains,
    options: () => ({
      tokenGetter,
      headerName: 'Authorization',
      authScheme: 'Bearer ',
      allowedDomains,
      skipWhenExpired: false
    })
  };
}

/**
 * Saves an instance of the object returned by jwtConfigurationFactory.
 * the options property can be passed to angular-jwt.
 * This object can be used to access the allowedDomains afterwards.
 */
export const jwtOptions = jwtConfigurationFactory();

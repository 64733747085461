import { Component } from '@angular/core';

/**
 * Displays the license information of the used packages.
 */
@Component({
  selector: 'scone-license-information',
  templateUrl: './license-information.component.html',
  styleUrls: ['./license-information.component.scss']
})
export class LicenseInformationComponent {
  /**
   * The constructor of the license information component.
   */
  constructor() {}
}

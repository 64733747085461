import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Requirement } from '../../../../shared/models/requirement-models';
import { RequirementLockState } from './requirement-lock-store';
import { initialRequirementLockState } from './requirement-lock-store/requirement-lock.state';

/**
 * The interface of the requirement store state.
 */
export interface RequirementState extends EntityState<Requirement> {
  /**
   * The requirement lock state.
   */
  requirementLockState: RequirementLockState;
  /**
   * Indicates whether the data has been loaded successfully.
   */
  loaded: boolean;
  /**
   * Indicates whether the data is currently being loaded.
   */
  isLoading: boolean;
  /**
   * The last error message that was received from the server.
   */
  errorMessage: string;
  /**
   * The last success message that was received from the server.
   */
  successMessage: string;
}

/**
 * The adapter of the requirement store.
 */
export const requirementAdapter = createEntityAdapter<Requirement>({
  selectId: (requirement: Requirement) => requirement.id
});

/**
 * The initial state of the requirement store.
 */
export const initialRequirementState: RequirementState =
  requirementAdapter.getInitialState({
    requirementLockState: initialRequirementLockState,
    loaded: false,
    isLoading: false,
    errorMessage: null,
    successMessage: null
  });

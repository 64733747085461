import { createSelector } from '@ngrx/store';
import { Requirement } from '../../shared/models/requirement-models';
import { selectUnremovedRequirements } from './requirement-root-store/requirement-store/requirement.selectors';
import { selectUnremovedTerms } from './glossary-store/glossary.selectors';
import { selectUnremovedRequirementGroups } from './requirement-root-store/requirement-group-store/requirement-group.selectors';
import { AnalysisSelectors } from './analysis-store';
import { RequirementGroup } from '../../shared/models/requirement-group-models';
import { Term } from '../../shared/models/glossary-models';
import { Statistics } from '../../shared/models/project-models';

/**
 * This selector selects the data which is needed to compute the project statistics
 * from the store and computes the statistics.
 */
export const selectStatistics = () =>
  createSelector(
    selectUnremovedRequirements,
    selectUnremovedRequirementGroups,
    selectUnremovedTerms,
    AnalysisSelectors.selectTotal,
    (
      requirements: Requirement[],
      requirementGroups: RequirementGroup[],
      terms: Term[],
      analysisResultsCount: number
    ): Statistics => {
      return {
        requirementsCount: requirements.length,
        requirementGroupsCount: requirementGroups.length,
        termsCount: terms.length,
        analysisResultsCount
      };
    }
  );

<mat-toolbar color="primary">
  <img
    src="assets/logo-light-new.png"
    height="30px"
    alt="scone logo"
    routerLink="/"
    style="cursor: pointer; margin-right: 10px"
  />
  <span
    *ngIf="loggedInUser$ | async as user"
    class="nav-text-button"
    routerLink="projects"
    >Projects</span
  >

  <span class="spacer"></span>

  <div fxFlex fxLayout fxLayoutAlign="flex-end" fxHide.xs>
    <button mat-icon-button [routerLink]="'/help'">
      <mat-icon>help_outline</mat-icon>
    </button>

    <span *ngIf="loggedInUser$ | async as user" class="navbar-nav my-2 my-lg-0">
      <span [routerLink]="'/account'">
        <button mat-icon-button>
          <mat-icon>person</mat-icon>
        </button>
      </span>
      <button mat-icon-button (click)="logout()">
        <mat-icon>exit_to_app</mat-icon>
      </button>
    </span>
  </div>
</mat-toolbar>

import { Action, createReducer, on } from '@ngrx/store';
import * as ProjectActions from './project.actions';
import { initialState, projectAdapter, ProjectState } from './project.state';
import { storeMessages } from '../../shared/miscellaneous/globals';
import { requirementAdapter } from './requirement-root-store/requirement-store/requirement.state';
import { glossaryAdapter } from './glossary-store/glossary.state';
import {
  analysisAdapter,
  initialAnalysisState
} from './analysis-store/analysis.state';
import { requirementGroupAdapter } from './requirement-root-store/requirement-group-store/requirement-group.state';
import * as RequirementActions from './requirement-root-store/requirement-store/requirement.actions';
import * as RequirementGroupActions from './requirement-root-store/requirement-group-store/requirement-group.actions';
import * as GlossaryActions from './glossary-store/glossary.actions';
import * as AnalysisActions from './analysis-store/analysis.actions';
import * as RequirementLockActions from './requirement-root-store/requirement-store/requirement-lock-store/requirement-lock.actions';
import { requirementLockAdapter } from './requirement-root-store/requirement-store/requirement-lock-store/requirement-lock.state';
import { initialRequirementRootState } from './requirement-root-store/';

/**
 * The project reducers.
 */
const projectReducer = createReducer(
  initialState,
  on(ProjectActions.openProjectSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    loaded: true,
    errorMessage: null,
    successMessage: null,
    projectLock: action.projectContent.lock,
    currentProjectId: action.projectId,
    projectMembers: action.projectContent.members,
    requirements: {
      ...state.requirements,
      requirementState: requirementAdapter.setAll(
        action.projectContent.requirements,
        {
          ...state.requirements.requirementState,
          loaded: true,
          isLoading: false,
          errorMessage: null,
          successMessage: null,
          requirementLockState: requirementLockAdapter.setAll(
            action.projectContent.requirement_locks,
            {
              ...state.requirements.requirementState.requirementLockState,
              loaded: true,
              isLoading: false,
              errorMessage: null,
              successMessage: null
            }
          )
        }
      ),
      requirementGroupState: requirementGroupAdapter.setAll(
        action.projectContent.requirement_groups,
        {
          ...state.requirements.requirementGroupState,
          loaded: true,
          isLoading: false,
          errorMessage: null,
          successMessage: null
        }
      ),
      isLoading: false,
      loaded: true
    },
    glossary: glossaryAdapter.setAll(action.projectContent.terms, {
      ...state.glossary,
      isLoading: false,
      loaded: true,
      errorMessage: null,
      successMessage: null
    }),
    analysis: analysisAdapter.setAll(action.projectContent.analysis_results, {
      ...state.analysis,
      isLoading: false,
      loaded: true,
      errorMessage: null,
      successMessage: null
    })
  })),
  on(ProjectActions.openProjectFailure, (state, action) => ({
    ...state,
    errorMessage: action.error,
    isLoading: false,
    loaded: false,
    successMessage: null
  })),
  on(ProjectActions.createProjectRequest, (state) => ({
    ...state,
    isLoading: true,
    loaded: false,
    errorMessage: null,
    successMessage: null
  })),
  on(ProjectActions.createProjectSuccess, (state, action) =>
    projectAdapter.addOne(action.project, {
      ...state,
      isLoading: false,
      loaded: true,
      successMessage: storeMessages.createProjectSuccess,
      errorMessage: null
    })
  ),
  on(ProjectActions.createProjectFailure, (state, action) => ({
    ...state,
    isLoading: false,
    loaded: false,
    errorMessage: action.error,
    successMessage: null
  })),
  on(ProjectActions.updateProjectRequest, (state) => ({
    ...state,
    isLoading: true,
    loaded: false,
    errorMessage: null,
    successMessage: null
  })),
  on(ProjectActions.updateProjectSuccess, (state, action) =>
    projectAdapter.updateOne(
      { id: action.project.id, changes: action.project },
      {
        ...state,
        isLoading: false,
        loaded: true,
        errorMessage: null,
        successMessage: storeMessages.updateProjectSuccess
      }
    )
  ),
  on(ProjectActions.updateProjectFailure, (state, action) => ({
    ...state,
    isLoading: false,
    loaded: false,
    errorMessage: action.error,
    successMessage: null
  })),
  on(ProjectActions.deleteProjectRequest, (state) => ({
    ...state,
    isLoading: true,
    loaded: false,
    successMessage: null,
    errorMessage: null
  })),
  on(ProjectActions.deleteProjectSuccess, (state, action) =>
    projectAdapter.removeOne(action.projectId, {
      ...state,
      isLoading: false,
      loaded: true,
      successMessage: storeMessages.deleteProjectSuccess,
      errorMessage: null
    })
  ),
  on(ProjectActions.deleteProjectFailure, (state, action) => ({
    ...state,
    isLoading: false,
    loaded: true,
    successMessage: null,
    errorMessage: action.error
  })),
  on(ProjectActions.retrieveAllProjects, (state) => ({
    ...state,
    isLoading: true,
    loaded: false
  })),
  on(ProjectActions.setAllProjects, (state, action) =>
    projectAdapter.setAll(action.projects, {
      ...state,
      isLoading: false,
      loaded: true,
      errorMessage: null,
      successMessage: null
    })
  ),
  on(ProjectActions.addProjectMemberRequest, (state) => ({
    ...state,
    isLoading: true,
    loaded: false,
    errorMessage: null,
    successMessage: null
  })),
  on(ProjectActions.addProjectMemberSuccess, (state, action) => ({
    ...state,
    projectMembers: [...state.projectMembers, action.user],
    isLoading: false,
    loaded: true,
    errorMessage: null,
    successMessage: storeMessages.addProjectMemberSuccess
  })),
  on(ProjectActions.addProjectMemberFailure, (state, action) => ({
    ...state,
    isLoading: false,
    loaded: false,
    errorMessage: action.error,
    successMessage: null
  })),
  on(ProjectActions.retrieveProjectMembersRequest, (state) => ({
    ...state,
    isLoading: true,
    loaded: false
  })),
  on(ProjectActions.retrieveProjectMembersSuccess, (state, action) => ({
    ...state,
    currentProjectId: action.projectId,
    projectMembers: action.projectMembers,
    isLoading: false,
    loaded: true,
    errorMessage: null
  })),
  on(ProjectActions.retrieveProjectMembersFailure, (state, action) => ({
    ...state,
    isLoading: false,
    loaded: false,
    errorMessage: action.error,
    successMessage: null
  })),
  on(ProjectActions.createProjectLockRequest, (state) => ({
    ...state,
    isLoading: true,
    loaded: false,
    errorMessage: null,
    successMessage: null
  })),
  on(ProjectActions.createProjectLockSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    loaded: true,
    projectLock: action.projectLock,
    successMessage: storeMessages.createProjectLockSuccess,
    errorMessage: null
  })),
  on(ProjectActions.createProjectLockFailure, (state, action) => ({
    ...state,
    isLoading: false,
    loaded: false,
    errorMessage: action.error,
    successMessage: null
  })),
  on(ProjectActions.deleteProjectLockRequest, (state) => ({
    ...state,
    isLoading: true,
    loaded: false,
    errorMessage: null,
    successMessage: null
  })),
  on(ProjectActions.deleteProjectLockSuccess, (state) => ({
    ...state,
    projectLock: null,
    isLoading: false,
    loaded: true,
    successMessage: storeMessages.deleteProjectLockSuccess,
    errorMessage: null
  })),
  on(ProjectActions.deleteProjectLockFailure, (state, action) => ({
    ...state,
    isLoading: false,
    errorMessage: action.error,
    successMessage: null
  })),
  on(ProjectActions.retrieveProjectLock, (state) => ({
    ...state,
    isLoading: true,
    loaded: false
  })),
  on(ProjectActions.setProjectLock, (state, action) => ({
    ...state,
    projectLock: action.projectLock,
    isLoading: false,
    loaded: true,
    successMessage: null,
    errorMessage: null
  })),
  on(ProjectActions.clearProject, (state) => ({
    ...state,
    currentProjectId: null,
    isLoading: false,
    loaded: false,
    errorMessage: null,
    successMessage: null
  })),
  on(ProjectActions.setWebsocketMessage, (state, action) => ({
    ...state,
    websocketMessage: action.websocketMessage
  })),
  on(ProjectActions.clearProjects, (state) => projectAdapter.removeAll(state)),
  on(RequirementActions.createRequirementRequest, (state) => ({
    ...state,
    requirements: {
      ...state.requirements,
      requirementState: {
        ...state.requirements.requirementState,
        isLoading: true,
        loaded: false,
        errorMessage: null,
        successMessage: null
      },
      isLoading: true
    }
  })),
  on(RequirementActions.createRequirementSuccess, (state, action) => ({
    ...state,
    requirements: {
      ...state.requirements,
      requirementState: requirementAdapter.addOne(action.requirement, {
        ...state.requirements.requirementState,
        isLoading: false,
        loaded: true,
        errorMessage: null,
        successMessage: storeMessages.createRequirementSuccess
      }),
      isLoading: false
    }
  })),
  on(RequirementActions.createRequirementFailure, (state, action) => ({
    ...state,
    requirements: {
      ...state.requirements,
      requirementState: {
        ...state.requirements.requirementState,
        isLoading: false,
        loaded: false,
        errorMessage: action.error,
        successMessage: null
      },
      isLoading: false
    }
  })),
  on(RequirementActions.updateRequirementRequest, (state) => ({
    ...state,
    requirements: {
      ...state.requirements,
      requirementState: {
        ...state.requirements.requirementState,
        isLoading: true,
        loaded: false,
        errorMessage: null,
        successMessage: null
      },
      isLoading: true
    }
  })),
  on(RequirementActions.updateRequirementSuccess, (state, action) => ({
    ...state,
    requirements: {
      ...state.requirements,
      requirementState: requirementAdapter.updateOne(
        { id: action.requirement.id, changes: action.requirement },
        {
          ...state.requirements.requirementState,
          isLoading: false,
          loaded: true,
          errorMessage: null,
          successMessage: storeMessages.updateRequirementSuccess
        }
      ),
      isLoading: false
    }
  })),
  on(RequirementActions.updateRequirementFailure, (state, action) => ({
    ...state,
    requirements: {
      ...state.requirements,
      requirementState: {
        ...state.requirements.requirementState,
        isLoading: false,
        loaded: false,
        errorMessage: action.error,
        successMessage: null
      },
      isLoading: false
    }
  })),
  on(RequirementActions.deleteRequirementRequest, (state) => ({
    ...state,
    requirements: {
      ...state.requirements,
      requirementState: {
        ...state.requirements.requirementState,
        isLoading: true,
        loaded: false,
        errorMessage: null,
        successMessage: null
      },
      isLoading: true
    }
  })),
  on(RequirementActions.deleteRequirementSuccess, (state, action) => ({
    ...state,
    requirements: {
      ...state.requirements,
      requirementState: requirementAdapter.updateOne(
        { id: action.requirementId, changes: { removed: true } },
        {
          ...state.requirements.requirementState,
          isLoading: false,
          loaded: true,
          errorMessage: null,
          successMessage: storeMessages.deleteRequirementSuccess
        }
      ),
      isLoading: false
    }
  })),
  on(RequirementActions.deleteRequirementFailure, (state, action) => ({
    ...state,
    requirements: {
      ...state.requirements,
      requirementState: {
        ...state.requirements.requirementState,
        isLoading: false,
        errorMessage: action.error,
        successMessage: null
      },
      isLoading: false
    }
  })),
  on(RequirementActions.retrieveAllRequirements, (state) => ({
    ...state,
    requirements: {
      ...state.requirements,
      requirementState: {
        ...state.requirements.requirementState,
        isLoading: true,
        loaded: false
      },
      isLoading: true
    }
  })),
  on(RequirementActions.setAllRequirements, (state, action) => ({
    ...state,
    requirements: {
      ...state.requirements,
      requirementState: requirementAdapter.setAll(action.requirements, {
        ...state.requirements.requirementState,
        isLoading: false,
        loaded: true
      }),
      isLoading: false
    }
  })),
  on(RequirementActions.setWebsocketMessage, (state, action) => ({
    ...state,
    requirements: {
      ...state.requirements,
      websocketMessage: action.websocketMessage
    }
  })),
  on(RequirementActions.clearRequirementStore, (state) => ({
    ...state,
    requirements: initialRequirementRootState
  })),
  on(RequirementLockActions.createRequirementLockRequest, (state) => ({
    ...state,
    requirements: {
      ...state.requirements,
      requirementState: {
        ...state.requirements.requirementState,
        requirementLockState: {
          ...state.requirements.requirementState.requirementLockState,
          isLoading: true,
          loaded: false,
          errorMessage: null,
          successMessage: null
        }
      }
    }
  })),
  on(RequirementLockActions.createRequirementLockSuccess, (state, action) => ({
    ...state,
    requirements: {
      ...state.requirements,
      requirementState: {
        ...state.requirements.requirementState,
        requirementLockState: requirementLockAdapter.addOne(
          action.requirementLock,
          {
            ...state.requirements.requirementState.requirementLockState,
            isLoading: false,
            loaded: true,
            errorMessage: null,
            successMessage: storeMessages.createRequirementLockSuccess
          }
        )
      }
    }
  })),
  on(RequirementLockActions.createRequirementLockFailure, (state, action) => ({
    ...state,
    requirements: {
      ...state.requirements,
      requirementState: {
        ...state.requirements.requirementState,
        requirementLockState: {
          ...state.requirements.requirementState.requirementLockState,
          isLoading: false,
          loaded: false,
          errorMessage: action.error,
          successMessage: null
        }
      }
    }
  })),
  on(RequirementLockActions.updateRequirementLockRequest, (state) => ({
    ...state,
    requirements: {
      ...state.requirements,
      requirementState: {
        ...state.requirements.requirementState,
        requirementLockState: {
          ...state.requirements.requirementState.requirementLockState,
          isLoading: true,
          loaded: false,
          errorMessage: null,
          successMessage: null
        }
      }
    }
  })),
  on(RequirementLockActions.updateRequirementLockSuccess, (state, action) => ({
    ...state,
    requirements: {
      ...state.requirements,
      requirementState: {
        ...state.requirements.requirementState,
        requirementLockState: requirementLockAdapter.updateOne(
          {
            id: action.requirementLock.requirement,
            changes: action.requirementLock
          },
          {
            ...state.requirements.requirementState.requirementLockState,
            isLoading: false,
            loaded: true,
            errorMessage: null,
            successMessage: storeMessages.updateRequirementLockSuccess
          }
        )
      }
    }
  })),
  on(RequirementLockActions.updateRequirementLockFailure, (state, action) => ({
    ...state,
    requirements: {
      ...state.requirements,
      requirementState: {
        ...state.requirements.requirementState,
        requirementLockState: {
          ...state.requirements.requirementState.requirementLockState,
          isLoading: false,
          loaded: false,
          errorMessage: action.error,
          successMessage: null
        }
      }
    }
  })),
  on(RequirementLockActions.deleteRequirementLockRequest, (state) => ({
    ...state,
    requirements: {
      ...state.requirements,
      requirementState: {
        ...state.requirements.requirementState,
        requirementLockState: {
          ...state.requirements.requirementState.requirementLockState,
          isLoading: true,
          loaded: false,
          errorMessage: null,
          successMessage: null
        }
      }
    }
  })),
  on(RequirementLockActions.deleteRequirementLockSuccess, (state, action) => ({
    ...state,
    requirements: {
      ...state.requirements,
      requirementState: {
        ...state.requirements.requirementState,
        requirementLockState: requirementLockAdapter.removeOne(
          action.requirementId,
          {
            ...state.requirements.requirementState.requirementLockState,
            isLoading: false,
            loaded: true,
            errorMessage: null,
            successMessage: storeMessages.deleteRequirementLockSuccess
          }
        )
      }
    }
  })),
  on(RequirementLockActions.deleteRequirementLockFailure, (state, action) => ({
    ...state,
    requirements: {
      ...state.requirements,
      requirementState: {
        ...state.requirements.requirementState,
        requirementLockState: {
          ...state.requirements.requirementState.requirementLockState,
          isLoading: false,
          errorMessage: action.error,
          successMessage: null
        }
      }
    }
  })),
  on(RequirementLockActions.retrieveAllRequirementLocks, (state) => ({
    ...state,
    requirements: {
      ...state.requirements,
      requirementState: {
        ...state.requirements.requirementState,
        requirementLockState: {
          ...state.requirements.requirementState.requirementLockState,
          isLoading: true,
          loaded: false
        }
      }
    }
  })),
  on(RequirementLockActions.setAllRequirementLocks, (state, action) => ({
    ...state,
    requirements: {
      ...state.requirements,
      requirementState: {
        ...state.requirements.requirementState,
        requirementLockState: requirementLockAdapter.setAll(
          action.requirementLocks,
          {
            ...state.requirements.requirementState.requirementLockState,
            isLoading: false,
            loaded: true,
            errorMessage: null,
            successMessage: null
          }
        )
      }
    }
  })),
  on(RequirementGroupActions.createRequirementGroupRequest, (state) => ({
    ...state,
    requirements: {
      ...state.requirements,
      requirementGroupState: {
        ...state.requirements.requirementGroupState,
        isLoading: true,
        loaded: false,
        errorMessage: null,
        successMessage: null
      },
      isLoading: true
    }
  })),
  on(
    RequirementGroupActions.createRequirementGroupSuccess,
    (state, action) => ({
      ...state,
      requirements: {
        ...state.requirements,
        requirementGroupState: requirementGroupAdapter.addOne(
          action.requirementGroup,
          {
            ...state.requirements.requirementGroupState,
            loaded: true,
            isLoading: false,
            errorMessage: null,
            successMessage: storeMessages.createRequirementGroupSuccess
          }
        ),
        isLoading: false
      }
    })
  ),
  on(
    RequirementGroupActions.createRequirementGroupFailure,
    (state, action) => ({
      ...state,
      requirements: {
        ...state.requirements,
        requirementGroupState: {
          ...state.requirements.requirementGroupState,
          isLoading: false,
          loaded: false,
          errorMessage: action.error,
          successMessage: null
        },
        isLoading: false
      }
    })
  ),
  on(RequirementGroupActions.updateRequirementGroupRequest, (state) => ({
    ...state,
    requirements: {
      ...state.requirements,
      requirementGroupState: {
        ...state.requirements.requirementGroupState,
        isLoading: true,
        loaded: false,
        errorMessage: null,
        successMessage: null
      },
      isLoading: true
    }
  })),
  on(
    RequirementGroupActions.updateRequirementGroupSuccess,
    (state, action) => ({
      ...state,
      requirements: {
        ...state.requirements,
        requirementGroupState: requirementGroupAdapter.updateOne(
          { id: action.requirementGroup.id, changes: action.requirementGroup },
          {
            ...state.requirements.requirementGroupState,
            isLoading: false,
            loaded: true,
            errorMessage: null,
            successMessage: storeMessages.updateRequirementGroupSuccess
          }
        ),
        isLoading: false
      }
    })
  ),
  on(
    RequirementGroupActions.updateRequirementGroupFailure,
    (state, action) => ({
      ...state,
      requirements: {
        ...state.requirements,
        requirementGroupState: {
          ...state.requirements.requirementGroupState,
          isLoading: false,
          loaded: false,
          errorMessage: action.error,
          successMessage: null
        },
        isLoading: false
      }
    })
  ),
  on(RequirementGroupActions.deleteRequirementGroupRequest, (state) => ({
    ...state,
    requirements: {
      ...state.requirements,
      requirementGroupState: {
        ...state.requirements.requirementGroupState,
        isLoading: true,
        loaded: false,
        successMessage: null,
        errorMessage: null
      },
      isLoading: true
    }
  })),
  on(
    RequirementGroupActions.deleteRequirementGroupSuccess,
    (state, action) => ({
      ...state,
      requirements: {
        ...state.requirements,
        requirementGroupState: requirementGroupAdapter.updateOne(
          { id: action.requirementGroupId, changes: { removed: true } },
          {
            ...state.requirements.requirementGroupState,
            isLoading: false,
            loaded: true,
            errorMessage: null,
            successMessage: storeMessages.deleteRequirementGroupSuccess
          }
        ),
        isLoading: false
      }
    })
  ),
  on(
    RequirementGroupActions.deleteRequirementGroupFailure,
    (state, action) => ({
      ...state,
      requirements: {
        ...state.requirements,
        requirementGroupState: {
          ...state.requirements.requirementGroupState,
          isLoading: false,
          errorMessage: action.error,
          successMessage: null
        },
        isLoading: false
      }
    })
  ),
  on(RequirementGroupActions.retrieveAllRequirementGroups, (state) => ({
    ...state,
    requirements: {
      ...state.requirements,
      requirementGroupState: {
        ...state.requirements.requirementGroupState,
        isLoading: true,
        loaded: false
      },
      isLoading: true
    }
  })),
  on(RequirementGroupActions.setAllRequirementGroups, (state, action) => ({
    ...state,
    requirements: {
      ...state.requirements,
      requirementGroupState: requirementGroupAdapter.setAll(
        action.requirementGroups,
        {
          ...state.requirements.requirementGroupState,
          isLoading: false,
          loaded: true,
          errorMessage: null,
          successMessage: null
        }
      ),
      isLoading: false
    }
  })),
  on(GlossaryActions.createTermRequest, (state) => ({
    ...state,
    glossary: {
      ...state.glossary,
      isLoading: true,
      loaded: false,
      errorMessage: null,
      successMessage: null
    }
  })),
  on(GlossaryActions.createTermSuccess, (state, action) => ({
    ...state,
    glossary: glossaryAdapter.addOne(action.term, {
      ...state.glossary,
      errorMessage: null,
      successMessage: storeMessages.createTermSuccess
    })
  })),
  on(GlossaryActions.createTermFailure, (state, action) => ({
    ...state,
    glossary: {
      ...state.glossary,
      isLoading: false,
      loaded: false,
      errorMessage: action.error,
      successMessage: null
    }
  })),
  on(GlossaryActions.updateTermRequest, (state) => ({
    ...state,
    glossary: {
      ...state.glossary,
      isLoading: true,
      loaded: false,
      errorMessage: null,
      successMessage: null
    }
  })),
  on(GlossaryActions.updateTermSuccess, (state, action) => ({
    ...state,
    glossary: glossaryAdapter.updateOne(
      { id: action.term.id, changes: action.term },
      {
        ...state.glossary,
        isLoading: false,
        loaded: true,
        successMessage: storeMessages.updateTermSuccess,
        errorMessage: null
      }
    )
  })),
  on(GlossaryActions.updateTermFailure, (state, action) => ({
    ...state,
    glossary: {
      ...state.glossary,
      isLoading: false,
      loaded: false,
      errorMessage: action.error,
      successMessage: null
    }
  })),
  on(GlossaryActions.deleteTermRequest, (state) => ({
    ...state,
    glossary: {
      ...state.glossary,
      isLoading: true,
      loaded: false,
      errorMessage: null,
      successMessage: null
    }
  })),
  on(GlossaryActions.deleteTermSuccess, (state, action) => ({
    ...state,
    glossary: glossaryAdapter.updateOne(
      { id: action.id, changes: { removed: true } },
      {
        ...state.glossary,
        isLoading: false,
        loaded: true,
        errorMessage: null,
        successMessage: storeMessages.deleteTermSuccess
      }
    )
  })),
  on(GlossaryActions.deleteTermFailure, (state, action) => ({
    ...state,
    glossary: {
      ...state.glossary,
      isLoading: false,
      loaded: false,
      errorMessage: action.error,
      successMessage: null
    }
  })),
  on(GlossaryActions.retrieveAllTerms, (state) => ({
    ...state,
    glossary: {
      ...state.glossary,
      isLoading: true,
      loaded: false
    }
  })),
  on(GlossaryActions.setAllTerms, (state, action) => ({
    ...state,
    glossary: glossaryAdapter.setAll(action.terms, {
      ...state.glossary,
      loaded: true,
      isLoading: false,
      errorMessage: null,
      successMessage: null
    })
  })),
  on(GlossaryActions.setWebsocketMessage, (state, action) => ({
    ...state,
    websocketMessage: action.websocketMessage
  })),
  on(GlossaryActions.clearGlossary, (state) => ({
    ...state,
    glossary: glossaryAdapter.removeAll(state.glossary)
  })),

  on(AnalysisActions.retrieveAllAnalysisResults, (state) => ({
    ...state,
    isLoading: true,
    loaded: false
  })),
  on(AnalysisActions.setAllAnalysisResults, (state, action) => ({
    ...state,
    analysis: analysisAdapter.setAll(action.analysisResults, {
      ...state.analysis,
      isLoading: true,
      loaded: false,
      errorMessage: null,
      successMessage: null
    })
  })),
  on(AnalysisActions.deleteAnalysisResultRequest, (state) => ({
    ...state,
    analysis: {
      ...state.analysis,
      isLoading: true,
      loaded: false,
      errorMessage: null,
      successMessage: null
    }
  })),
  on(AnalysisActions.deleteAnalysisResultSuccess, (state, action) => ({
    ...state,
    analysis: analysisAdapter.removeOne(action.analysisResultId, {
      ...state.analysis,
      loaded: true,
      isLoading: false,
      errorMessage: null,
      successMessage: storeMessages.deleteAnalysisResultSuccess
    })
  })),
  on(AnalysisActions.deleteAnalysisResultFailure, (state, action) => ({
    ...state,
    analysis: {
      ...state.analysis,
      isLoading: false,
      loaded: true,
      errorMessage: action.error,
      successMessage: null
    }
  })),
  on(AnalysisActions.clearAnalysis, (state) => ({
    ...state,
    analysis: initialAnalysisState
  }))
);

/**
 * The default project selectors provided by the project adapter.
 */
export const { selectIds, selectEntities, selectAll, selectTotal } =
  projectAdapter.getSelectors();

/**
 * Used to invoke the project reducers with the given state and action.
 * @param state The current project state before the dispatched action.
 * @param action The dispatched action that will be used by the reducer to change the current project state.
 */
export function reducer(state: ProjectState | undefined, action: Action) {
  return projectReducer(state, action);
}

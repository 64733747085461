import { projectAdapter, ProjectState } from './project.state';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { User } from '../../shared/models/user';
import { Dictionary } from '@ngrx/entity';
import {
  Project,
  ProjectLock,
  ProjectView
} from '../../shared/models/project-models';

/**
 * This selector selects the 'current project id' piece of state from the project store.
 */
const getCurrentProjectId = (state: ProjectState): number =>
  state.currentProjectId;
/**
 * This selector selects the 'loaded' piece of state from the project store.
 */
const getLoaded = (state: ProjectState): boolean => state.loaded;
/**
 * This selector selects the 'project members' piece of state from the project store.
 */
const getProjectMembers = (state: ProjectState): any => state.projectMembers;
/**
 * This selector selects the 'error message' piece of state from the project store.
 */
const getErrorMessage = (state: ProjectState): string => state.errorMessage;
/**
 * This selector selects the 'success message' piece of state from the project store.
 */
const getSuccessMessage = (state: ProjectState): string => state.successMessage;
/**
 * This selector selects the 'project lock' piece of state from the project store.
 */
const getProjectLock = (state: ProjectState): ProjectLock => state.projectLock;

/**
 * This selector selects the whole project state.
 */
export const selectProjectState =
  createFeatureSelector<ProjectState>('project');
/**
 * This selector selects the id of the currently open project from the project store.
 */
export const selectCurrentProjectId = createSelector(
  selectProjectState,
  getCurrentProjectId
);
/**
 * This selector selects the 'loaded' value from the project store.
 */
export const selectLoaded = createSelector(selectProjectState, getLoaded);
/**
 * This selector selects the error message from the project store.
 */
export const selectErrorMessage = createSelector(
  selectProjectState,
  getErrorMessage
);
/**
 * This selector selects the success message from the project store.
 */
export const selectSuccessMessage = createSelector(
  selectProjectState,
  getSuccessMessage
);
/**
 * This selector selects the project lock (if existing) for the currently open project from the project store.
 */
export const selectProjectLock = createSelector(
  selectProjectState,
  getProjectLock
);
/**
 * This selector selects the members of the currently open project from the project store.
 */
export const selectProjectMembers = createSelector(
  selectProjectState,
  getProjectMembers
);
/**
 * This selector takes a user id an selects the corresponding project member of the currently open project from the project store.
 */
export const selectProjectMember = () =>
  createSelector(selectProjectMembers, (members, props: { userId: number }) =>
    members.find((member: User) => member.id === props.userId)
  );
/**
 * This selector takes a project id an selects the corresponding project from the project store if the user is a member in that project.
 */
export const selectProject = () =>
  createSelector(
    selectEntities,
    (entities: Dictionary<Project>, props: { projectId: number }) =>
      entities[props.projectId]
  );
/**
 * This selector takes a project id an selects the corresponding project from
 * the project store as a project view if the user is a member in that project.
 */
export const selectProjectView = () =>
  createSelector(
    selectProject(),
    selectProjectMembers,
    (project: Project, members: User[]): ProjectView =>
      project && {
        id: project.id,
        title: project.title,
        end_date: project.end_date,
        icon: project.icon,
        supervisor: project.supervisor,
        color: project.color,
        members
      }
  );
/**
 * This selector selects the currently open project from the project store.
 */
export const selectCurrentProjectView = () =>
  createSelector(
    selectAllProjects,
    selectCurrentProjectId,
    selectProjectMembers,
    (
      projects: Project[],
      currentProjectId: number,
      members: User[]
    ): ProjectView => {
      const project = projects.find((p: Project) => p.id === currentProjectId);
      return (
        project && {
          id: project.id,
          title: project.title,
          end_date: project.end_date,
          icon: project.icon,
          supervisor: project.supervisor,
          color: project.color,
          members
        }
      );
    }
  );

/**
 * This selector selects the websocket message from the project store.
 */
export const selectWebsocketMessage = createSelector(
  selectProjectState,
  (state: ProjectState) => state.websocketMessage
);

/**
 * The default project store selectors provided by the project adapter.
 */
export const {
  selectAll: selectAllProjects,
  selectEntities,
  selectIds,
  selectTotal
} = projectAdapter.getSelectors(selectProjectState);

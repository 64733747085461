import { Injectable } from '@angular/core';
import { httpOptions } from '../miscellaneous/globals';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ProjectStoreService } from './project-store.service';
import { RequirementLock } from '../models/requirement-models';
import { AppConfigurationService } from '../configuration/app-configuration.service';

/**
 * The requirement lock service.
 */
@Injectable({
  providedIn: 'root'
})
export class RequirementLockService {
  /**
   * The constructor of the RequirementLockService.
   * @param http Dependency injection of the HttpClient.
   * @param projectStoreService Dependency injection of the ProjectsService.
   * @param configService Dependency injection of the AppConfigurationService.
   */
  constructor(
    private http: HttpClient,
    private projectStoreService: ProjectStoreService,
    private configService: AppConfigurationService
  ) {}

  /**
   * Fetches the requirement locks of a specific project from the backend.
   * @param projectID The project ID for which the requirement locks are to be fetched.
   * @returns An observable containing the fetched requirement locks.
   */
  public getRequirementLocks(projectID: number): Observable<RequirementLock[]> {
    return this.http.get<RequirementLock[]>(
      this.configService.url + 'project/' + projectID + '/requirement-locks'
    );
  }

  /**
   * Initiates the creation of a requirement lock in the backend.
   * @param data The data describing the requirement lock to be created.
   * @returns An observable containing the result of the creation.
   */
  public createRequirementLockOnServer(
    projectId: number,
    data: any
  ): Observable<any> {
    const body = JSON.stringify(data);
    return this.http.post(
      this.configService.url +
        'projects/' +
        projectId +
        '/requirements/' +
        data.requirement +
        '/lock/',
      body,
      httpOptions
    );
  }

  /**
   * Initiates the deletion of a requirement lock in the backend. A deletion is only an update of the requirement that will
   * not delete it but set the removed property to true.
   * @param requirementId The ID of the requirement for which its lock is to be deleted.
   * @returns An observable containing the result of the deletion.
   */
  public deleteRequirementLockOnServer(
    projectId: number,
    requirementId: number
  ): Observable<any> {
    const options = {
      headers: httpOptions.headers,
      body: {
        requirementId
      }
    };
    return this.http.delete(
      this.configService.url +
        'projects/' +
        projectId +
        '/requirements/' +
        requirementId +
        '/lock/',
      options
    );
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AuthStoreModule } from './auth-store/auth-store.module';
import { environment } from '../../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { ProjectStoreModule } from './project-store/project-store.module';
import { StoreModule } from '@ngrx/store';
import * as fromRouter from './router-store';
import { routerReducer } from '@ngrx/router-store';
import { AppStoreModule } from './app-store/app-store.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forRoot(routerReducer),
    StoreModule.forFeature(fromRouter.routerFeatureKey, routerReducer),
    EffectsModule.forRoot([]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    AuthStoreModule,
    ProjectStoreModule,
    AppStoreModule
  ]
})
export class RootStoreModule {}

import { Injectable } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { RouterService } from '../services/router.service';
/**
 * This injectable is used to check whether the token of a user is expired. If it is not, it redirects the user to their projects page.
 */
@Injectable()
export class IsLoggedIn {
  /**
   * The constructor of the IsLoggedIn Injectable.
   * @param {RouterService} routerService Dependency injection for the RouterService
   * @param {AuthService} authService Dependency Injection for the AuthService
   */
  constructor(
    private routerService: RouterService,
    private authService: AuthService
  ) {}

  /**
   * Checks whether the token of the user is expired. If not, it redirects the user to their projects page.
   */
  resolve(): void {
    if (!this.authService.checkTokenExpired()) {
      this.routerService.dispatchGoAction('/projects');
    }
  }
}

import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { WebsocketMessage } from '../../../shared/models/websocketMessage';
import { Term } from '../../../shared/models/glossary-models';

/**
 * Action for the creation of a term. Takes a term object.
 */
export const createTermRequest = createAction(
  '[Glossary] Create Term Request',
  props<{ term: Term }>()
);
/**
 * Action that is triggered if the creation of a term was successful.
 */
export const createTermSuccess = createAction(
  '[Glossary] Create Term Success',
  props<{ term: Term }>()
);
/**
 * Action that is triggered if the creation of a term failed.
 */
export const createTermFailure = createAction(
  '[Glossary] Create Term Failure',
  props<{ error: string }>()
);
/**
 * Action for the update of a term. Takes a Update<Term> object.
 */
export const updateTermRequest = createAction(
  '[Glossary] Update Term Request',
  props<{ term: Update<Term> }>()
);
/**
 * Action that is triggered if the update of a term was successful.
 */
export const updateTermSuccess = createAction(
  '[Glossary] Update Term Success',
  props<{ term: Term }>()
);
/**
 * Action that is triggered if the update of a term failed.
 */
export const updateTermFailure = createAction(
  '[Glossary] Update Term Failure',
  props<{ error: string }>()
);
/**
 * Action for the deletion of a term. Takes the term id.
 */
export const deleteTermRequest = createAction(
  '[Glossary] Delete Term Request',
  props<{ id: number }>()
);
/**
 * Action that is triggered if the deletion of a term was successful.
 */
export const deleteTermSuccess = createAction(
  '[Glossary] Delete Term Success',
  props<{ id: number }>()
);
/**
 * Action that is triggered if the deletion of a term failed.
 */
export const deleteTermFailure = createAction(
  '[Glossary] Delete Term Failure',
  props<{ error: string }>()
);
/**
 * Action for the retrieval of all terms. Takes a project id.
 */
export const retrieveAllTerms = createAction(
  '[Glossary] Retrieve All Terms',
  props<{ projectId: number }>()
);
/**
 * Action that is triggered if the retrieval of all terms was successful.
 */
export const setAllTerms = createAction(
  '[Glossary] Set All Terms',
  props<{ terms: Term[] }>()
);
/**
 * Action to set the websocket message.
 */
export const setWebsocketMessage = createAction(
  '[Glossary] Set Websocket Message',
  props<{ websocketMessage: WebsocketMessage }>()
);
/**
 * Action to clear all terms from the store.
 */
export const clearGlossary = createAction('[Glossary] Clear Glossary');

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfigurationService } from '../configuration/app-configuration.service';

/**
 * The app service.
 */
@Injectable({
  providedIn: 'root'
})
export class AppService {
  /**
   * The constructor for the app service.
   * @param http Dependency injection for the HttpClient.
   * @param configService Dependency injection of the AppConfigurationService
   */
  constructor(
    private http: HttpClient,
    private configService: AppConfigurationService
  ) {}

  /**
   * Fetches the app data from the backend.
   * @returns An observable emitting the app data.
   */
  public getAppData(): Observable<any> {
    return this.http.get<any>(this.configService.url + 'appdata/');
  }

  /**
   * Returns all available project colors as hex codes array.
   */
  public getProjectColors(): string[] {
    return [
      '#e53935',
      '#c62828',
      '#d81b60',
      '#ad1457',
      '#8e24aa',
      '#6a1b9a',
      '#5e35b1',
      '#4527a0',
      '#3949ab',
      '#283593',
      '#1e88e5',
      '#1565c0',
      '#00acc1',
      '#00838f',
      '#00897b',
      '#00695c',
      '#43a047',
      '#2e7d32',
      '#c0ca33',
      '#9e9d24',
      '#fdd835',
      '#f9a825',
      '#fb8c00',
      '#ef6c00',
      '#6d4c41',
      '#4e342e',
      '#757575',
      '#424242',
      '#546e7a',
      '#37474f',
      '#000000'
    ];
  }
}

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => {
    // no need to log this to console, logged by defaultErrorLogger
    // console.error(err);

    // remove loading animation
    const loaderBox = document.getElementById('loader-box');
    while (loaderBox.lastChild) {
      loaderBox.removeChild(loaderBox.lastChild);
    }

    // show error to user
    const errMsg = document.createElement('p');
    errMsg.textContent = 'Scone failed to load. Please try again later.';
    loaderBox.appendChild(errMsg);
    const errMsg2 = document.createElement('p');
    errMsg2.textContent =
      'Please contact an administrator if this error persists.';
    loaderBox.appendChild(errMsg2);
  });

import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { WebsocketMessage } from '../../../shared/models/websocketMessage';
import { Term } from '../../../shared/models/glossary-models';

/**
 * The interface of the glossary store state.
 */
export interface GlossaryState extends EntityState<Term> {
  /**
   * Indicates whether the data is currently being loaded.
   */
  isLoading: boolean;
  /**
   * Indicates whether the data has been loaded successfully.
   */
  loaded: boolean;
  /**
   * The last error message that was received from the server.
   */
  errorMessage: string;
  /**
   * The last success message that was received from the server.
   */
  successMessage: string;
  /**
   * The last websocket message that was received.
   */
  websocketMessage: WebsocketMessage;
}

/**
 * The adapter of the glossary store.
 */
export const glossaryAdapter: EntityAdapter<Term> = createEntityAdapter<Term>();

/**
 * The initial state of the glossary store.
 */
export const initialGlossaryState: GlossaryState =
  glossaryAdapter.getInitialState({
    isLoading: false,
    loaded: false,
    errorMessage: null,
    successMessage: null,
    websocketMessage: null
  });

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as AuthActions from './auth.actions';
import { AuthService } from '../../shared/services/auth.service';
import { LocalStorageService } from '../../shared/services/local-storage.service';
import { Router } from '@angular/router';

/**
 * The effects for the auth actions.
 */
@Injectable()
export class AuthEffects {
  /**
   * The constructor of the auth effects.
   * @param router Dependency injection of the Router.
   * @param actions$ Dependency injection of the Actions.
   * @param authService Dependency injection of the AuthService.
   * @param localStorageService Dependency injection of the LocalStorageService.
   */
  constructor(
    private router: Router,
    private actions$: Actions,
    private authService: AuthService,
    private localStorageService: LocalStorageService
  ) {}

  /**
   * The effect that gets called when a sign up action has been dispatched.
   * Creates a new user account with the data provided by the action.
   */
  signUpRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.signUpRequest),
      switchMap((action) =>
        this.authService.createUserAccount(action.user).pipe(
          map((user) => AuthActions.signUpSuccess({ user })),
          catchError((error) => of(AuthActions.signUpFailure({ error })))
        )
      )
    )
  );

  /**
   * The effect that gets called when a login request action has been dispatched.
   * Logs in with the data provided by the action.
   */
  loginRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginRequest),
      switchMap((action) =>
        this.authService
          .login({ username: action.username, password: action.password })
          .pipe(
            map(({ token, user }) => AuthActions.loginSuccess({ token, user })),
            catchError((error) => of(AuthActions.loginFailure({ error })))
          )
      )
    )
  );

  /**
   * The effect that gets called when a login success action has been dispatched.
   * Sets the user and the JWT data as provided by the action.
   */
  loginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.loginSuccess),
        tap((action) => {
          this.localStorageService.setLoggedInUser({
            token: action.token,
            user: action.user
          });
          this.router.navigate(['projects']);
        })
      ),
    { dispatch: false }
  );

  /**
   * The effect that gets called when a refresh request action has been dispatched
   * to refresh the JWT.
   */
  refreshRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.refreshRequest),
      switchMap(() =>
        this.localStorageService.getLoggedInUser().pipe(
          map((user) => AuthActions.refreshSuccess({ data: JSON.parse(user) })),
          catchError((error) => of(AuthActions.refreshFailure({ error })))
        )
      )
    )
  );

  /**
   * The effect that gets called when a logout request action has been dispatched.
   * Performs a logout for the currently logged in user.
   */
  logoutRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logoutRequest),
      switchMap(() =>
        this.authService.logout().pipe(
          map(() => AuthActions.logoutSuccess()),
          catchError((error) => of(AuthActions.logoutFailure({ error })))
        )
      )
    )
  );

  /**
   * The effect that gets called when a logout success action has been dispatched.
   * Removes all data from the local storage and navigates to the landing page.
   */
  logoutSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.logoutSuccess),
        tap(() => {
          this.localStorageService.cleanAll();
          this.router.navigate(['./']);
        })
      ),
    { dispatch: false }
  );
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { GlossaryActions } from './index';
import { catchError, map, switchMap } from 'rxjs/operators';
import { GlossaryService } from '../../../shared/services/glossary.service';
import { of } from 'rxjs';
import { Term } from '../../../shared/models/glossary-models';

/**
 * The effects for the glossary actions.
 */
@Injectable()
export class GlossaryEffects {
  /**
   * The constructor of the glossary effects.
   * @param actions$ Dependency injection of the Actions.
   * @param glossaryService Dependency injection of the GlossaryService.
   */
  constructor(
    private actions$: Actions,
    private glossaryService: GlossaryService
  ) {}

  /**
   * The effect that gets called when a create term request action has been dispatched.
   * Creates a new term with the data provided by the action.
   */
  createTermRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlossaryActions.createTermRequest),
      switchMap((action) =>
        this.glossaryService.createTermOnServer(action.term).pipe(
          map((term: Term) => GlossaryActions.createTermSuccess({ term })),
          catchError((error) =>
            of(GlossaryActions.createTermFailure({ error }))
          )
        )
      )
    )
  );

  /**
   * The effect that gets called when an update term request action has been dispatched.
   * Updates the term with the data provided by the action.
   */
  updateTermRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlossaryActions.updateTermRequest),
      switchMap((action) =>
        this.glossaryService.updateTermOnServer(action.term).pipe(
          map((term: Term) => GlossaryActions.updateTermSuccess({ term })),
          catchError((error) =>
            of(GlossaryActions.updateTermFailure({ error }))
          )
        )
      )
    )
  );

  /**
   * The effect that gets called when a delete term request action has been dispatched.
   * Deletes the term with the data provided by the action.
   */
  deleteTermRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlossaryActions.deleteTermRequest),
      switchMap((action) =>
        this.glossaryService.deleteTermOnServer(action.id).pipe(
          map(() => GlossaryActions.deleteTermSuccess({ id: action.id })),
          catchError((error) =>
            of(GlossaryActions.deleteTermFailure({ error }))
          )
        )
      )
    )
  );

  /**
   * The effect that gets called when a retrieve all terms action has been dispatched.
   * Retrieves all terms from the server and sets them.
   */
  retrieveAllTerms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlossaryActions.retrieveAllTerms),
      switchMap((action) =>
        this.glossaryService
          .fetchTermsFromServer(action.projectId)
          .pipe(map((terms: Term[]) => GlossaryActions.setAllTerms({ terms })))
      )
    )
  );
}

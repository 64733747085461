import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { User } from '../../shared/models/user';
import { WebsocketMessage } from '../../shared/models/websocketMessage';
import { Project, ProjectLock } from '../../shared/models/project-models';

/**
 * Action for the creation of a project. Takes a project object.
 */
export const createProjectRequest = createAction(
  '[Projects] Create Project Request',
  props<{ project: Project }>()
);

/**
 * Action that is triggered if the creation of a project was successful.
 */
export const createProjectSuccess = createAction(
  '[Projects] Create Project Success',
  props<{ project: Project }>()
);

/**
 * Action that is triggered if the creation of a project failed.
 */
export const createProjectFailure = createAction(
  '[Projects] Create Project Failure',
  props<{ error: string }>()
);

/**
 * Action for the update of a project. Takes an object of type Update<Project>.
 */
export const updateProjectRequest = createAction(
  '[Projects] Update Project Request',
  props<{ project: Update<Project> }>()
);

/**
 * Action that is triggered if the update of a project was successful.
 */
export const updateProjectSuccess = createAction(
  '[Projects] Update Project Success',
  props<{ project: Project }>()
);

/**
 * Action that is triggered if the update of a project failed.
 */
export const updateProjectFailure = createAction(
  '[Projects] Update Project Failure',
  props<{ error: string }>()
);
/**
 * Action for the deletion of a project. Takes the project id.
 */
export const deleteProjectRequest = createAction(
  '[Projects] Delete Project Request',
  props<{ projectId: number }>()
);
/**
 * Action that is triggered if the deletion of a project was successful.
 */
export const deleteProjectSuccess = createAction(
  '[Projects] Delete Project Success',
  props<{ projectId: number }>()
);
/**
 * Action that is triggered if the deletion of a project failed.
 */
export const deleteProjectFailure = createAction(
  '[Projects] Delete Project Failure',
  props<{ error: string }>()
);
/**
 * Action for the retrieval of all project in which a user is a member.
 */
export const retrieveAllProjects = createAction(
  '[Projects] Retrieve All Projects'
);
/**
 * Action that is triggered if the retrieval of all projects was successful.
 */
export const setAllProjects = createAction(
  '[Projects] Set All Projects',
  props<{ projects: Project[] }>()
);
/**
 * Action that is triggered if the retrieval of all projects failed.
 */
export const retrieveAllProjectsFailure = createAction(
  '[Projects] Retrieve All Projects Failure',
  props<{ error: string }>()
);
/**
 * Action for adding a user to a project. Takes a email and a project id.
 */
export const addProjectMemberRequest = createAction(
  '[Projects] Add Project Member Request',
  props<{ email: string; projectId: number }>()
);
/**
 * Action that is triggered if the adding of a user to a project was successful.
 */
export const addProjectMemberSuccess = createAction(
  '[Projects] Add Project Member Success',
  props<{ user: User }>()
);
/**
 * Action that is triggered if the adding of a user to a project failed.
 */
export const addProjectMemberFailure = createAction(
  '[Projects] Add Project Member Failure',
  props<{ error: string }>()
);
/**
 * Action for the retrieval of all members of a project. Takes a project id.
 */
export const retrieveProjectMembersRequest = createAction(
  '[Projects] Retrieve Project Members Request',
  props<{ projectId: number }>()
);
/**
 * Action that is triggered if the retrieval of all members of a project was successful.
 */
export const retrieveProjectMembersSuccess = createAction(
  '[Projects] Retrieve Project Members Success',
  props<{ projectId: number; projectMembers: User[] }>()
);
/**
 * Action that is triggered if the retrieval of all members of a project failed.
 */
export const retrieveProjectMembersFailure = createAction(
  '[Projects] Retrieve Project Members Failure',
  props<{ error: string }>()
);
/**
 * Action for the creation of a project lock. Takes a project lock object.
 */
export const createProjectLockRequest = createAction(
  '[Projects] Create Project Lock Request',
  props<{ projectLock: ProjectLock }>()
);
/**
 * Action that is triggered if the creation of a project lock was successful.
 */
export const createProjectLockSuccess = createAction(
  '[Projects] Create Project Lock Success',
  props<{ projectLock: ProjectLock }>()
);
/**
 * Action that is triggered if the creation of a project lock failed.
 */
export const createProjectLockFailure = createAction(
  '[Projects] Create Project Lock Failure',
  props<{ error: string }>()
);
/**
 * Action for the deletion of a project lock. Takes a project id.
 */
export const deleteProjectLockRequest = createAction(
  '[Projects] Delete Project Lock Request',
  props<{ projectId: number }>()
);
/**
 * Action that is triggered if the deletion of a project lock was successful.
 */
export const deleteProjectLockSuccess = createAction(
  '[Projects] Delete Project Lock Success',
  props<{ projectId: number }>()
);
/**
 * Action that is triggered if the deletion of a project lock failed.
 */
export const deleteProjectLockFailure = createAction(
  '[Projects] Delete Project Lock Failure',
  props<{ error: string }>()
);
/**
 * Action for the retrieval of a project lock. Takes a project id.
 */
export const retrieveProjectLock = createAction(
  '[Projects] Retrieve Project Lock',
  props<{ projectId: number }>()
);
/**
 * Action that is triggered if the retrieval of a project lock was successful.
 */
export const setProjectLock = createAction(
  '[Projects] Set Project Lock',
  props<{ projectLock: ProjectLock }>()
);
/**
 * Action for navigating into a project, i.e. opening a project. Takes a project id.
 */
export const openProjectRequest = createAction(
  '[Projects] Open Project Request',
  props<{ projectId: number }>()
);
/**
 * Action that is triggered if the opening of a project was successful.
 */
export const openProjectSuccess = createAction(
  '[Projects] Open Project Success',
  props<{ projectId: number; projectContent: any }>()
);
/**
 * Action that is triggered if the opening of a project failed.
 */
export const openProjectFailure = createAction(
  '[Projects] Open Project Failure',
  props<{ error: string }>()
);
/**
 * Action to set the websocket message for the project store.
 */
export const setWebsocketMessage = createAction(
  '[Projects] Set Websocket Message',
  props<{ websocketMessage: WebsocketMessage }>()
);
/**
 * Action to clear the currently open project from the store.
 */
export const clearProject = createAction('[Projects] Clear Project');
/**
 * Action to clear all projects from the store.
 */
export const clearProjects = createAction('[Projects] Clear Projects');

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AuthSelectors, AuthState } from '../../store/auth-store/';
import { ProjectSelectors, ProjectState } from '../../store/project-store/';
import {
  RequirementSelectors,
  RequirementState
} from '../../store/project-store/requirement-root-store/requirement-store/';
import {
  GlossarySelectors,
  GlossaryState
} from '../../store/project-store/glossary-store';
import {
  RequirementGroupSelectors,
  RequirementGroupState
} from '../../store/project-store/requirement-root-store/requirement-group-store';
import { WebsocketMessage } from '../models/websocketMessage';

/**
 * The snackbar service.
 */
@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  /**
   * The constructor of the snackbar service.
   * @param authStore Dependency injection of the AuthStore.
   * @param projectStore Dependency injection of the ProjectStore.
   * @param requirementStore Dependency injection of the RequirementStore.
   * @param requirementGroupStore Dependency injection of the RequirementGroupStore.
   * @param glossaryStore Dependency injection of the GlossaryStore.
   */
  constructor(
    private authStore: Store<AuthState>,
    private projectStore: Store<ProjectState>,
    private requirementStore: Store<RequirementState>,
    private requirementGroupStore: Store<RequirementGroupState>,
    private glossaryStore: Store<GlossaryState>
  ) {}

  // AUTH

  /**
   * Calls the selectAuthErrorMessage selector in the AuthStore and
   * returns an observable of generic type string.
   * @returns Observable that emits the current
   * value of errorMessage (slice of AuthState) from the AuthStore.
   */
  public selectAuthErrorMessage(): Observable<any> {
    return this.authStore.select(AuthSelectors.selectErrorMessage);
  }

  /**
   * Calls the selectAuthSuccessMessage selector in the AuthStore and
   * returns an observable of generic type string.
   * @returns Observable that emits the current
   * value of successMessage (slice of AuthState) from the AuthStore.
   */
  public selectAuthSuccessMessage(): Observable<any> {
    return this.authStore.select(AuthSelectors.selectSuccessMessage);
  }

  // PROJECT

  /**
   * Selects the error message from the store.
   * @returns An observable containing the error message if there is one.
   */
  public selectProjectErrorMessage(): Observable<string> {
    return this.projectStore.select(ProjectSelectors.selectErrorMessage);
  }

  /**
   * Selects the success message from the store.
   * @returns An observable containing the success message if there is one.
   */
  public selectProjectSuccessMessage(): Observable<string> {
    return this.projectStore.select(ProjectSelectors.selectSuccessMessage);
  }

  /**
   * Selects the RequirementsErrorMessage from the store if there is one.
   * @returns The error message if there is one.
   */
  public selectRequirementErrorMessage(): Observable<string> {
    return this.requirementStore.select(
      RequirementSelectors.selectErrorMessage
    );
  }

  /**
   * Selects the RequirementsSuccessMessage from the store if there is one.
   * @returns The success message if there is one.
   */
  public selectRequirementSuccessMessage(): Observable<string> {
    return this.requirementStore.select(
      RequirementSelectors.selectSuccessMessage
    );
  }

  /**
   * Selects the RequirementGroupsErrorMessage from the store if there is one.
   * @returns The error message if there is one.
   */
  public selectRequirementGroupErrorMessage(): Observable<string> {
    return this.requirementGroupStore.select(
      RequirementGroupSelectors.selectErrorMessage
    );
  }

  /**
   * Selects the RequirementGroupsSuccessMessage from the store if there is one.
   * @returns The success message if there is one.
   */
  public selectRequirementGroupSuccessMessage(): Observable<string> {
    return this.requirementGroupStore.select(
      RequirementGroupSelectors.selectSuccessMessage
    );
  }

  /**
   * Selects the error message from the store.
   * @returns The error message if there is one.
   */
  public selectGlossaryErrorMessage(): Observable<string> {
    return this.glossaryStore.select(GlossarySelectors.selectErrorMessage);
  }

  /**
   * Selects the success message from the store.
   * @returns The success message if there is one.
   */
  public selectGlossarySuccessMessage(): Observable<string> {
    return this.glossaryStore.pipe(
      select(GlossarySelectors.selectSuccessMessage)
    );
  }

  /**
   * Selects the requirements websocket message from the store.
   * @returns The websocket message if there is one.
   */
  public selectRequirementWebsocketMessage(): Observable<WebsocketMessage> {
    return this.requirementStore.select(
      RequirementSelectors.selectWebsocketMessage
    );
  }

  /**
   * Selects the glossary websocket message from the store.
   * @returns The websocket message if there is one.
   */
  public selectGlossaryWebsocketMessage(): Observable<WebsocketMessage> {
    return this.glossaryStore.select(GlossarySelectors.selectWebsocketMessage);
  }

  /**
   * Selects the analysis websocket message from the store.
   * @returns The websocket message if there is one.
   */
  public selectProjectWebsocketMessage(): Observable<WebsocketMessage> {
    return this.projectStore.select(ProjectSelectors.selectWebsocketMessage);
  }
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, switchMap, tap } from 'rxjs/operators';
import { ProjectService } from '../../shared/services/project.service';
import * as ProjectActions from './project.actions';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { Project, ProjectLock } from '../../shared/models/project-models';

/**
 * The effects for the project actions.
 */
@Injectable()
export class ProjectEffects {
  /**
   * The constructor of the project effects.
   * @param actions$ Dependency injection of the Actions.
   * @param projectService Dependency injection of the ProjectService.
   * @param router Dependency injection of the Router.
   */
  constructor(
    private actions$: Actions,
    private projectService: ProjectService,
    private router: Router
  ) {}

  /**
   * The effect that gets called when a retrieve all projects action has been dispatched.
   * Retrieves all projects from the server and sets them.
   */
  retrieveAllProjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.retrieveAllProjects),
      exhaustMap((action) =>
        this.projectService.getUsersProjects().pipe(
          map((projects: Project[]) =>
            ProjectActions.setAllProjects({ projects })
          ),
          catchError((error) =>
            of(ProjectActions.retrieveAllProjectsFailure({ error }))
          )
        )
      )
    )
  );

  /**
   * The effect that gets called when an open project request action has been dispatched.
   * Retrieves the full project data from the server and opens the project.
   */
  openProjectRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.openProjectRequest),
      switchMap((action) =>
        this.projectService.getFullProject(action.projectId).pipe(
          map((projectContent: any) =>
            ProjectActions.openProjectSuccess({
              projectId: action.projectId,
              projectContent
            })
          ),
          catchError((error) =>
            of(ProjectActions.openProjectFailure({ error }))
          )
        )
      )
    )
  );

  /**
   * The effect that gets called when an open project failure action has been dispatched.
   * Navigates to the project list.
   */
  openProjectFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProjectActions.openProjectFailure),
        tap(() => this.router.navigate(['/projects']))
      ),
    { dispatch: false }
  );

  /**
   * The effect that gets called when a create project action has been dispatched.
   * Creates a project on the server with the data provided by the action.
   */
  createProjectRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.createProjectRequest),
      switchMap((action) =>
        this.projectService.createProjectOnServer(action.project).pipe(
          map((project) => ProjectActions.createProjectSuccess({ project })),
          catchError((error) =>
            of(ProjectActions.createProjectFailure({ error }))
          )
        )
      )
    )
  );

  /**
   * The effect that gets called when an add project member request action has been dispatched.
   * Adds a user to the project.
   */
  addProjectMemberRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.addProjectMemberRequest),
      switchMap((action) =>
        this.projectService
          .addProjectMember(action.email, action.projectId)
          .pipe(
            map((user) => {
              return ProjectActions.addProjectMemberSuccess({
                user
              });
            }),
            catchError((error) =>
              of(ProjectActions.addProjectMemberFailure({ error }))
            )
          )
      )
    )
  );

  /**
   * The effect that gets called when a create project lock action has been dispatched.
   * Creates a project lock on the server with the data provided by the action.
   */
  createProjectLockRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.createProjectLockRequest),
      switchMap((action) =>
        this.projectService.createProjectLockOnServer(action.projectLock).pipe(
          map((projectLock: ProjectLock) =>
            ProjectActions.createProjectLockSuccess({ projectLock })
          ),
          catchError((error) =>
            of(ProjectActions.createProjectLockFailure({ error }))
          )
        )
      )
    )
  );

  /**
   * The effect that gets called when a delete project lock request action has been dispatched.
   * Deletes the project lock from the server.
   */
  deleteProjectLockRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.deleteProjectLockRequest),
      switchMap((action) =>
        this.projectService.deleteProjectLockOnServer(action.projectId).pipe(
          map((projectId: number) =>
            ProjectActions.deleteProjectLockSuccess({ projectId })
          ),
          catchError((error) =>
            of(ProjectActions.deleteProjectLockFailure({ error }))
          )
        )
      )
    )
  );

  /**
   * The effect that gets called when a delete project request action has been dispatched.
   * Deletes the project from the server.
   */
  deleteProjectRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.deleteProjectRequest),
      switchMap((action) =>
        this.projectService.deleteProjectOnServer(action.projectId).pipe(
          map((projectId: number) =>
            ProjectActions.deleteProjectSuccess({ projectId })
          ),
          catchError((error) =>
            of(ProjectActions.deleteProjectFailure({ error }))
          )
        )
      )
    )
  );

  /**
   * The effect that gets called when a delete project success action has been dispatched.
   * Navigates to the project list.
   */
  deleteProjectSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProjectActions.deleteProjectSuccess),
        tap(() => this.router.navigate(['/projects']))
      ),
    { dispatch: false }
  );
}

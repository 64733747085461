import { createSelector } from '@ngrx/store';
import {
  requirementGroupAdapter,
  RequirementGroupState
} from './requirement-group.state';
import { Dictionary } from '@ngrx/entity';
import {
  selectReferenceTypes,
  selectRequirementGroupTypes
} from '../../../app-store/app.selectors';
import { selectRequirementRootState } from '../requirement-root.selectors';
import { RequirementRootState } from '../';
import {
  RequirementGroup,
  RequirementGroupLink,
  RequirementGroupLinkView,
  RequirementGroupReferenceType,
  RequirementGroupType,
  RequirementGroupView
} from '../../../../shared/models/requirement-group-models';

/**
 * This selector selects the whole requirement group state from the requirement group store.
 */
export const getRequirementGroupState = createSelector(
  selectRequirementRootState,
  (state: RequirementRootState) => state.requirementGroupState
);

/**
 * The default requirement group store selectors provided by the requirement group adapter.
 */
export const {
  selectAll: selectAllRequirementGroups,
  selectEntities: selectRequirementGroupEntities,
  selectIds: selectRequirementGroupIds,
  selectTotal: selectRequirementGroupsTotal
} = requirementGroupAdapter.getSelectors(getRequirementGroupState);
/**
 * This selector selects the root requirement group of the currently open project.
 */
export const selectRootRequirementGroup = createSelector(
  selectAllRequirementGroups,
  (requirementGroups: RequirementGroup[]) =>
    requirementGroups.find((group: RequirementGroup) => group.is_root)
);
/**
 * This selector takes an id and selects the corresponding requirement group from the store if existing.
 */
export const selectRequirementGroup = () =>
  createSelector(
    selectRequirementGroupEntities,
    (requirementGroups: Dictionary<RequirementGroup>, props: { id: number }) =>
      requirementGroups[props.id]
  );
/**
 * This selector takes an id and selects the corresponding requirement group as a trimmed version from the store if existing.
 */
export const selectTrimmedRequirementGroup = createSelector(
  selectRequirementGroupEntities,
  (requirementGroups: Dictionary<RequirementGroup>, props: { id: number }) => {
    const group = requirementGroups[props.id];
    return {
      id: group.id,
      identifier: group.identifier,
      name: group.name
    };
  }
);
/**
 * This selector selects all requirement groups with the removed property set to 'false'.
 */
export const selectUnremovedRequirementGroups = createSelector(
  selectAllRequirementGroups,
  (requirementGroups: RequirementGroup[]) =>
    requirementGroups.filter(
      (requirementGroup: RequirementGroup) => requirementGroup.removed === false
    )
);
/**
 * This selector selects all requirement groups with the removed property set to 'false' as trimmed versions.
 */
export const selectUnremovedTrimmedRequirementGroups = createSelector(
  selectAllRequirementGroups,
  (requirementGroups: RequirementGroup[]) =>
    requirementGroups
      .filter(
        (requirementGroup: RequirementGroup) =>
          requirementGroup.removed === false
      )
      .map((requirementGroup: RequirementGroup) => ({
        id: requirementGroup.id,
        identifier: requirementGroup.identifier,
        name: requirementGroup.name
      }))
);

/**
 * This selector selects the ids of all requirement groups with the removed property set to 'false'.
 */
export const selectUnremovedRequirementGroupIds = createSelector(
  selectUnremovedRequirementGroups,
  (requirementGroups: RequirementGroup[]) =>
    requirementGroups.map((group: RequirementGroup) => group.id)
);
/**
 * This selector takes a requirement group id and selects all subgroups of that group that have the removed property set to 'false'.
 */
export const selectUnremovedSubgroups = createSelector(
  selectUnremovedRequirementGroups,
  (requirementGroups: RequirementGroup[], props: { id: number }) =>
    requirementGroups.filter(
      (requirementGroup: RequirementGroup) =>
        requirementGroup.subgroup_of === props.id
    )
);
/**
 * This selector selects the 'loaded' value from the requirements group store.
 */
export const selectLoaded = createSelector(
  getRequirementGroupState,
  (state: RequirementGroupState) => state.loaded
);
/**
 * This selector selects the error message from the requirement group store.
 */
export const selectErrorMessage = createSelector(
  getRequirementGroupState,
  (state: RequirementGroupState) => state.errorMessage
);
/**
 * This selector selects the success message from the requirement group store.
 */
export const selectSuccessMessage = createSelector(
  getRequirementGroupState,
  (state: RequirementGroupState) => state.successMessage
);
/**
 * This selector takes a requirement group id and selects the corresponding identifier of that group.
 */
export const selectRequirementGroupIdentifier = createSelector(
  selectRequirementGroupEntities,
  (requirementGroups: Dictionary<RequirementGroup>, props: { id: string }) =>
    requirementGroups[props.id].identifier
);
/**
 * This selector selects all requirement group identifiers from the store.
 */
export const selectRequirementGroupIdentifiers = createSelector(
  selectAllRequirementGroups,
  (requirementGroups: RequirementGroup[]) =>
    requirementGroups.map(
      (requirementGroup: RequirementGroup) => requirementGroup.identifier
    )
);
/**
 * This selector takes a requirement group id and selects the corresponding name of that group.
 */
export const selectRequirementGroupName = createSelector(
  selectRequirementGroupEntities,
  (requirementGroups: Dictionary<RequirementGroup>, props: { id: string }) =>
    requirementGroups[props.id].name
);
/**
 * This selector takes a requirement group id and selects all links of that group.
 */
export const selectLinks = () =>
  createSelector(
    selectRequirementGroupEntities,
    selectRequirementGroup(),
    selectReferenceTypes,
    (
      requirementGroups: Dictionary<RequirementGroup>,
      requirementGroup: RequirementGroup,
      referenceTypes: RequirementGroupReferenceType[]
    ): RequirementGroupLinkView[] => {
      return requirementGroup.linked_requirement_groups.map(
        (link: RequirementGroupLink) => {
          return {
            id: link.id,
            first_requirement_group: {
              id: requirementGroup.id,
              identifier: requirementGroup.identifier,
              name: requirementGroup.name
            },
            second_requirement_group: {
              id: link.second_requirement_group,
              identifier: selectRequirementGroupIdentifier.projector(
                requirementGroups,
                {
                  id: link.second_requirement_group
                }
              ),
              name: selectRequirementGroupName.projector(requirementGroups, {
                id: link.second_requirement_group
              })
            },
            reference_type: referenceTypes.find(
              (type: RequirementGroupReferenceType) =>
                type.id === link.reference_type
            )
          };
        }
      );
    }
  );
/**
 * This selector selects all links between requirement groups.
 */
export const selectAllLinks = () =>
  createSelector(
    selectRequirementGroupEntities,
    selectUnremovedRequirementGroups,
    selectReferenceTypes,
    (
      requirementGroupEntities: Dictionary<RequirementGroup>,
      requirementGroups: RequirementGroup[],
      referenceTypes: RequirementGroupReferenceType[]
    ): RequirementGroupLinkView[] => {
      const links: RequirementGroupLinkView[] = [];
      requirementGroups.forEach((requirementGroup: RequirementGroup) => {
        links.push(
          ...requirementGroup.linked_requirement_groups.map(
            (link: RequirementGroupLink) => {
              return {
                id: link.id,
                first_requirement_group: {
                  id: requirementGroup.id,
                  identifier: requirementGroup.identifier,
                  name: requirementGroup.name
                },
                second_requirement_group: {
                  id: link.second_requirement_group,
                  identifier: selectRequirementGroupIdentifier.projector(
                    requirementGroupEntities,
                    {
                      id: link.second_requirement_group
                    }
                  ),
                  name: selectRequirementGroupName.projector(
                    requirementGroupEntities,
                    {
                      id: link.second_requirement_group
                    }
                  )
                },
                reference_type: referenceTypes.find(
                  (type: RequirementGroupReferenceType) =>
                    type.id === link.reference_type
                )
              };
            }
          )
        );
      });
      return links;
    }
  );
/**
 * This selector takes a requirement group id and selects the corresponding requirement group type of that group.
 */
export const selectRequirementGroupType = () =>
  createSelector(
    selectRequirementGroup(),
    selectRequirementGroupTypes,
    (
      requirementGroup: RequirementGroup,
      types: RequirementGroupType[]
    ): RequirementGroupType =>
      requirementGroup &&
      types.find(
        (type: RequirementGroupType) =>
          type.id === requirementGroup.requirement_group_type
      )
  );
/**
 * This selector takes a requirement group id and selects the corresponding requirement group view.
 */
export const selectRequirementGroupView = () =>
  createSelector(
    selectRequirementGroup(),
    selectLinks(),
    selectRequirementGroupType(),
    (
      requirementGroup: RequirementGroup,
      linked_requirements_groups: RequirementGroupLinkView[],
      requirement_group_type: RequirementGroupType
    ): RequirementGroupView =>
      requirementGroup && {
        id: requirementGroup.id,
        identifier: requirementGroup.identifier,
        name: requirementGroup.name,
        is_root: requirementGroup.is_root,
        subgroup_of: requirementGroup.subgroup_of,
        subgroups: requirementGroup.subgroups,
        linked_requirement_groups: linked_requirements_groups,
        is_optional: requirementGroup.is_optional,
        project: requirementGroup.project,
        requirement_group_type
      }
  );
/**
 * This selector selects all requirement groups from the store as requirement group views.
 */
export const selectUnremovedRequirementGroupViews = () =>
  createSelector(
    selectUnremovedRequirementGroups,
    selectRequirementGroupTypes,
    selectAllLinks(),
    (
      requirementGroups: RequirementGroup[],
      requirement_group_types: RequirementGroupType[],
      linked_requirement_groups: RequirementGroupLinkView[]
    ): RequirementGroupView[] =>
      requirementGroups &&
      requirementGroups.map((requirementGroup: RequirementGroup) => ({
        id: requirementGroup.id,
        identifier: requirementGroup.identifier,
        name: requirementGroup.name,
        is_root: requirementGroup.is_root,
        subgroup_of: requirementGroup.subgroup_of,
        subgroups: requirementGroup.subgroups,
        linked_requirement_groups: linked_requirement_groups.filter(
          (link) => link.first_requirement_group['id'] === requirementGroup.id
        ),
        is_optional: requirementGroup.is_optional,
        project: requirementGroup.project,
        requirement_group_type: requirement_group_types.find(
          (type: RequirementGroupType) =>
            type.id === requirementGroup.requirement_group_type
        )
      }))
  );

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { RequirementGroupActions } from './index';
import { RequirementGroupService } from '../../../../shared/services/requirement-group.service';
import { mergeMap, of } from 'rxjs';
import { RequirementGroupStoreService } from '../../../../shared/services/requirement-group-store.service';
import { RequirementGroup } from '../../../../shared/models/requirement-group-models';
import { ProjectStoreService } from '../../../../shared/services/project-store.service';
import { first } from 'rxjs/operators';

/**
 * The effects for the requirement group actions.
 */
@Injectable()
export class RequirementGroupEffects {
  /**
   * The constructor of the requirement group effects.
   * @param actions$ Dependency injection of the Actions.
   * @param requirementGroupService Dependency injection of the RequirementGroupService.
   * @param requirementGroupStoreService Dependency injection of the RequirementGroupStoreService.
   */
  constructor(
    private actions$: Actions,
    private requirementGroupService: RequirementGroupService,
    private requirementGroupStoreService: RequirementGroupStoreService,
    private projectStoreService: ProjectStoreService
  ) {}

  /**
   * The effect that gets called when a retrieve all requirement groups action has been dispatched.
   * Retrieves all requirement groups from the server and sets them.
   */
  retrieveAllRequirementGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RequirementGroupActions.retrieveAllRequirementGroups),
      switchMap((action) =>
        this.requirementGroupService
          .getRequirementGroups(action.projectId)
          .pipe(
            map((requirementGroups: RequirementGroup[]) =>
              RequirementGroupActions.setAllRequirementGroups({
                requirementGroups
              })
            )
          )
      )
    )
  );

  /**
   * The effect that gets called when a create requirement group request action has been dispatched.
   * Creates a new requirement group with the data provided by the action.
   */
  createRequirementGroupRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RequirementGroupActions.createRequirementGroupRequest),
      switchMap((action) =>
        this.requirementGroupService
          .createRequirementGroupOnServer(action.requirementGroup)
          .pipe(
            map((requirementGroup: RequirementGroup) =>
              RequirementGroupActions.createRequirementGroupSuccess({
                requirementGroup
              })
            ),
            catchError((error) =>
              of(
                RequirementGroupActions.createRequirementGroupFailure({ error })
              )
            )
          )
      )
    )
  );

  /**
   * The effect that gets called when a create requirement group success action has been dispatched.
   * Refreshes the requirement groups.
   */
  createRequirementGroupSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RequirementGroupActions.createRequirementGroupSuccess),
        tap((action) =>
          this.requirementGroupStoreService.dispatchRetrieveAllRequirementGroups(
            action.requirementGroup.project
          )
        )
      ),
    { dispatch: false }
  );

  /**
   * The effect that gets called when an update requirement group request action has been dispatched.
   * Updates the requirement group with the data provided by the action.
   */
  updateRequirementGroupRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RequirementGroupActions.updateRequirementGroupRequest),
      switchMap((action) =>
        this.requirementGroupService
          .updateRequirementGroupOnServer(action.requirementGroup)
          .pipe(
            map((requirementGroup: RequirementGroup) =>
              RequirementGroupActions.updateRequirementGroupSuccess({
                requirementGroup
              })
            ),
            catchError((error) =>
              of(
                RequirementGroupActions.updateRequirementGroupFailure({ error })
              )
            )
          )
      )
    )
  );

  /**
   * The effect that gets called when an update requirement group success action has been dispatched.
   * Refreshes the requirement groups.
   */
  updateRequirementGroupSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RequirementGroupActions.updateRequirementGroupSuccess),
        tap((action) =>
          this.requirementGroupStoreService.dispatchRetrieveAllRequirementGroups(
            action.requirementGroup.project
          )
        )
      ),
    { dispatch: false }
  );

  /**
   * The effect that gets called when a delete requirement group request action has been dispatched.
   * Deletes the requirement group with the data provided by the action.
   */
  deleteRequirementGroupRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RequirementGroupActions.deleteRequirementGroupRequest),
      switchMap((action) =>
        this.requirementGroupService
          .deleteRequirementGroupOnServer(action.id)
          .pipe(
            map((requirementGroupId: number) =>
              RequirementGroupActions.deleteRequirementGroupSuccess({
                requirementGroupId
              })
            ),
            catchError((error) =>
              of(
                RequirementGroupActions.deleteRequirementGroupFailure({ error })
              )
            )
          )
      )
    )
  );

  /**
   * The effect that gets called when a delete requirement group success action has been dispatched.
   * Refreshes the requirement groups.
   */
  deleteRequirementGroupSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RequirementGroupActions.deleteRequirementGroupSuccess),
        tap((action) =>
          this.requirementGroupStoreService.dispatchRetrieveAllRequirementGroupsOfSelectedProject()
        )
      ),
    { dispatch: false }
  );
}

<h1>LEGAL INFORMATION AND PRIVACY POLICY</h1>

<h2>Contact</h2>
<p>
  Prof. Dr. Georg Rock
  <br />
  g.rock@hochschule-trier.de
</p>

<h2>Privacy Policy</h2>

The protection of your personal data is important to us. We collect such data
only insofar as technically necessary as such data enjoys special protection. We
would like to explain to you hereafter – according to our obligation by law –
which data will be collected as you use our web application and how we use such
data. The way we handle your data is in accordance with the rules of the
EU-General Data Protection Regulation (GDPR), the German Federal Data Protection
Act (BDSG) as well as the State Data Protection Act of Rhineland-Palatine
(LDSG). If we refer via links to other providers, such providers may have other
principles and rules concerning data collection, processing and data use.

<h3>Legal Basis</h3>
Art. 6 sec. 1 lit. (a) GDPR shall be the legal basis if and insofar we obtain
approval of the affected person for the processing of personal data. Art. 6 sec.
1 lit. (b) GDPR shall be the legal basis when we process personal data which is
essential for the performance of a contract of which the affected person is a
contracting party. This shall also apply for processing activities which are
necessary for the execution of pre-contractual measures. Art. 6 sec. 1 lit. (c)
GDPR shall be the legal basis if it is necessary to process personal data to
fulfil a legal obligation which has to be satisfied by the service provider.
Art. 6 sec. 1 lit. (d) GDPR shall be the legal basis if vital interests of the
affected person or another person require the processing of personal data. Art.
6 sec. 1 lit. (f) GDPR shall be the legal basis if the data processing is
essential for the protection of legitimate interests of the service provider or
a third person and if such legitimate interests are not predominated by
fundamental rights and liberties of the affected person.

<h3>Scope of Processing of Personal Data</h3>
We generally process personal data of our users only and insofar as this is
necessary for the provision of operating a web application including its content
and our activities for the specific purpose indicated. Processing of personal
data of our users only happens after we have received the individual user‘s
consent. This shall not apply if obtaining user’s prior consent is not feasible
for practical reasons and if the processing of data is permitted by rule of law.

<h3>Appropriation and Time of Data Storage</h3>
If you put personal data at our disposal due to your consent, we will use such
data only for the purpose covered by your prior consent. Data storage beyond
that purpose may be possible if EU or national rules of law or other rules and
regulations which are binding for us, permit such storage. We will respect your
protection requiring interests according to the rules of law. All personal data
of an affected person will be deleted or locked as soon as the purpose is
omitted. This shall also take place if a stipulated blocking period expires.

<h3>Creation of Log Files</h3>
<h4>Description and Scope of Data Processing</h4>
On each request of the web application to the central server our system
automatically collects and records data and information coming from the user’s
computer system. Following data will be collected:
<ul>
  <li>IP-address</li>
  <li>Date and time of the request</li>
  <li>Requested Resource</li>
  <li>Information about the success of the request</li>
</ul>

<h4>Purpose of Data Processing</h4>
The temporary storage of the IP-address by the system is necessary to facilitate
the delivery of our web application to user’s computer. We use all access data
mentioned above exclusively in a non-personalised format for the continuous
improvement of our web application by use analysis as well as to resolve any
problems.

<h4>Timespan of Data Storage</h4>
The data mentioned above will be deleted as soon as the storage is not necessary
any longer to reach the intended purpose of their collection. This is the case
within 3 months with respect to data collection for the provision of the web
application, after analysis and potential problems have been solved.

<h4>No Possibility to Object</h4>
The logging of your IP-address is absolutely essential for the provision and
operation of our web application as well as the data storage in log files.
Hence, there is no possibility for the user to object.

<h4>Data Transfer</h4>
We do not pass on to third persons the data mentioned above.

<h3>Account and account related data</h3>
If you choose to register in the application the following personal data are
collected and stored to the extent to which they are technically required:
<ul>
  <li>E-mail address</li>
  <li>First name and last name</li>
  <li>Hashed password</li>
</ul>

Additionally the following data linked to your user account are stored:
<ul>
  <li>Whether the to the account linked e-mail has been confirmed</li>
  <li>Date and time of first sign in</li>
  <li>Date and time of last sign in</li>
  <li>
    If Requirements, Requirement Groups, and Glossary Terms have been created
    using your account, your first and last name are saved as the author's name
  </li>
</ul>

<h4>No Possibility to Object</h4>
Collection of the above mentioned data is essential for the operation of our web
application. Hence, when you create an account you need to agree to this privacy
policy and the herein described use of your data and there is no possibility to
object.

<h4>Timespan of Data Storage</h4>
The data mentioned above will be deleted as soon as the the user requests the
deletion of this data.

<h4>Data Transfer</h4>
We do not pass on to third persons the data mentioned above.

<h2>Rights</h2>
If we process your personal data you are the „aggrieved party“ in the sense of
the GDPR. As a consequence you are entitled to the following rights (towards the
responsible person):

<h3>Right of Information</h3>
If you have claimed your right of correction, deletion or restriction of
processing toward the responsible person, such person is obliged to inform all
persons or entities which have received your personal data about the correction
or deletion of data or restriction of processing unless this is impossible or
only feasible with unreasonable effort. You have the right toward the
responsible person to be informed about such receivers.

<h3>Right of Correction</h3>
You have a right of correction and/or completion of your personal data against
the responsible person if your processed personal data is not correct or
incomplete. The responsible person has to carry out the correction immediately.

<h3>Right of Restriction of Data Processing</h3>
You have the right to ask for the restriction of data processing of your
personal data under the following prerequisites:
<ul>
  <li>
    if you contest the correctness of your personal data: for the time span
    which enables the responsible person to check the correctness of your
    personal data.
  </li>
  <li>
    if data processing is unlawful and if you decline the deletion of your
    personal data but instead ask for the restriction of use of your personal
    data
  </li>
  <li>
    if the responsible person does not use your personal data for the data
    processing any longer but you need it for the enforcement, exercise or
    defence of legal claims or
  </li>
  <li>
    if you have revoked against data processing according to Art. 21 sec. 1 GDPR
    but it not certain yet if the responsible person’s legitimate reasons
    prevail your reasons.
  </li>
</ul>
If the processing of your personal data has been restricted, such data may –
apart from being stored – only be processed with your consent or for the
enforcement, exercise or defence of legal claims or for the protection of rights
of another person or legal entity or because of an important public interest of
the EU or a member state. If the restriction of data processing was restricted
under the prerequisites mentioned beforehand, you will be informed by the
responsible person prior to the suspension of the restriction.

<h3>Right of Deletion</h3>
<h4>a) Obligation to Delete</h4>
You have the right to ask the responsible person that your personal data will be
deleted immediately. The responsible person is obliged to delete such data
immediately presupposed that one of the following factors applies: - The purpose
for which your personal data has been collected or processed is not relevant or
necessary anymore. - You revoke your consent on which the data processing
according to Art. 6 sec 1 lit. (a) or Art. 9 sec. 2 lit. (a) GDPR has been based
on and there is no other legal basis for the data processing. - You file an
objection according to Art. 21 sec. 1 GDPR against the data processing and there
are no overriding legitimate reasons for the processing or you file an objection
against the data processing according to Art. 21 sec. 2 GDPR. - Your personal
data has been processed unlawfully. - The deletion of your personal data is
necessary for the fulfilment of a legal obligation according to EU law or the
law of a member state also governing the responsible person. - Your personal
data has been collected in connection with offered services of the information
society according to Art. 8 sec. 1 GDPR.

<h4>b) Information for Third Persons</h4>
If the responsible person has made your personal data public and if he is
obliged to delete them according to Art. 17 sec. 1 GDPR he will take appropriate
measures considering the available technology and the costs of implementing such
appropriate measures (including technical aspects) in order to inform the person
responsible for the data processing, which processes your personal data, that
you as the concerned person have claimed to delete all links to your personal
data or of any copies or replications of your personal data.

<h4>c) Exceptions</h4>
The right of deletion as well as the obligations described under 4. b) above
does not exist if the data processing is necessary: - for the execution of the
right of free speech and information; - for the fulfilment of a legal obligation
which requires the data processing according to the law of the EU or a member
state and of which the responsible person is an object to, or for the perception
of a task which is in the public interest or in exercise of public authority
which has been delegated onto the responsible person; - for reasons of public
interest in the domain of public health according to Art. 9 sec. 2 lit. (h) and
(i) as well as Art. 9 sec. 3 GDPR; - for archival purposes which are also in the
public interest, for scientific or historic research purposes or for statistical
purposes according to Art. 89 sec. 1 GDPR, as long as the right mentioned under
a) will presumably make the realisation of the goals of the data processing
impossible or seriously impair such goals, or - to assert, exercise or defend
legal claims.

<h3>Right of Data Transferability</h3>
You have the right to receive all of your personal data which you have
transmitted to the responsible person in a structured, established and
machine-readable format. Additionally, you have the right to transmit such data
to another responsible person without any hindrance by the responsible person
whom you have provided with your personal data, if - data processing is based on
a consent according to Art. 6 sec. 1 lit. (a) GDPR or Art. 9 sec. 2 lit. (a)
GDPR or on a contract according to Art. 6 sec. 1 lit. (b) GDPR and - data
processing takes place by means of automated procedures. When exercising this
right you have also the right to obtain that your personal data will be
transmitted directly from one responsible person to another one if this is
technically feasible. Any freedoms or rights of other persons may not be damaged
by this. The right of data transferability does not apply for the processing of
personal data which is necessary for the perception of a task which is in the
public interest or in exercise of public authority which has been delegated onto
the responsible person.

<h3>Right of Objection</h3>
You have the right to file an objection against the data processing or your
personal data for reasons deriving out of your specific situation at any time
according to Art. 6 sec. 3 lit. (b) GDPR in connection with § 3 LDSG; this shall
also apply with respect to a profiling which is based on these provisions. The
responsible person does not process your personal data any longer, unless he can
prove compulsory reasons for the processing requiring protection which – at the
same time – prevail your interests, rights and freedom or if the procession
serves the enforcement, exercise or defence of legal claims. You have the
possibility to execute your right of objection in connection with the use of
services of the information society – notwithstanding the Directive 2002/58/EG –
while using automated procedures which apply technical specifications.

<h3>Right of Objection of the Privacy Policy of Consent</h3>
You have the right to withdraw your privacy policy of consent at any time. Your
withdrawal does not touch the lawfulness of data processing until the time of
your withdrawal.

<h3>Right of Complaint with Supervisory Authority</h3>
Notwithstanding any other legal remedy by court or administration you have the
right of complaint with a supervisory authority, especially in the member state
where you have your domicile, your place of work or the place where the
potential infringement took place if you are of the opinion that the processing
or your personal data has violated the GDPR. The supervisory authority where you
filed your complaint shall inform the complainant about the current state of
affairs and the result of the complaint including the possibility of filing a
legal remedy at a competent court according to Art. 78 GDPR.

<h3>Timeliness and Validity of the Privacy Policy</h3>
This privacy policy is currently valid and dated September 30th, 2020. It might
become necessary due to further development of our web application or due to the
implementation of new technologies to change this policy. We reserve the right
to change this policy for the future at any time. We recommend to read the
policy from time to time anew.

<br /><br /><br /><br />

import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { AnalysisResult } from '../../../shared/models/project-models';

/**
 * The interface of the analysis store state.
 */
export interface AnalysisState extends EntityState<AnalysisResult> {
  /**
   * Indicates whether the data has been loaded successfully.
   */
  loaded: boolean;
  /**
   * Indicates whether the data is currently being loaded.
   */
  isLoading: boolean;
  /**
   * The last error message that was received from the server.
   */
  errorMessage: string;
  /**
   * The last success message that was received from the server.
   */
  successMessage: string;
  /**
   * The last websocket message that was received.
   */
  websocketMessage: string;
}

/**
 * The adapter of the analysis store.
 */
export const analysisAdapter: EntityAdapter<AnalysisResult> =
  createEntityAdapter<AnalysisResult>();

/**
 * The initial state of the analysis store.
 */
export const initialAnalysisState = analysisAdapter.getInitialState({
  loaded: false,
  isLoading: false,
  errorMessage: null,
  successMessage: null,
  websocketMessage: null
});

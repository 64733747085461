import { createAction, props } from '@ngrx/store';

/**
 * Action for retrieving the app data. Used to fill the store with the general data.
 */
export const retrieveAppData = createAction('[App] Retrieve App Data');

/**
 * Action that is triggered if the app data has been successfully retrieved.
 */
export const setAppData = createAction(
  '[App] Set App Data',
  props<{ appData: any }>()
);

/**
 * Action that is triggered if retrieving the app data was not successful.
 */
export const retrieveAppDataFailure = createAction(
  '[App] Retrieve App Data Failure',
  props<{ error: string }>()
);

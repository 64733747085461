import { glossaryAdapter, GlossaryState } from './glossary.state';
import { createSelector } from '@ngrx/store';
import { selectProjectMembers, selectProjectState } from '../project.selectors';
import { ProjectState } from '../project.state';
import { User } from '../../../shared/models/user';
import { Term, TermView } from '../../../shared/models/glossary-models';

/**
 * This selector selects the 'loaded' piece of state from the glossary store.
 */
const getLoaded = (state: GlossaryState): any => state.loaded;
/**
 * This selector selects the 'error message' piece of state from the glossary store.
 */
const getErrorMessage = (state: GlossaryState): any => state.errorMessage;
/**
 * This selector selects the 'success message' piece of state from the glossary store.
 */
const getSuccessMessage = (state: GlossaryState): any => state.successMessage;
/**
 * This selector selects the whole glossary state from the glossary store.
 */
export const selectGlossaryState = createSelector(
  selectProjectState,
  (state: ProjectState) => state.glossary
);
/**
 * This selector selects all terms that have the removed property set to 'false'.
 */
export const selectUnremovedTerms = createSelector(
  selectGlossaryState,
  (state) => {
    const allTerms = Object.values(state.entities);
    return allTerms.filter((term) => !!term.removed === false);
  }
);
/**
 * This selector selects all terms that have the removed property set to 'false' as term views.
 * Note: member is of type  {user(id), project(id), role}
 */
export const selectTermViews = () =>
  createSelector(
    selectUnremovedTerms,
    selectProjectMembers,
    (terms: Term[], members: any[]): TermView[] =>
      terms.map((term: Term) => ({
        id: term.id,
        project: term.project,
        term: term.term,
        author: members.find((member: any) => member.user.id === term.author)
          .user,
        definition: term.definition,
        alias: term.alias,
        plural: term.plural,
        translation: term.translation
      }))
  );
/**
 * This selector selects the 'loaded' value from the glossary store.
 */
export const selectLoaded = createSelector(selectGlossaryState, getLoaded);
/**
 * This selector selects the websocket message from the glossary store.
 */
export const selectWebsocketMessage = createSelector(
  selectGlossaryState,
  (state: GlossaryState) => state.websocketMessage
);
/**
 * This selector selects the error message from the glossary store.
 */
export const selectErrorMessage = createSelector(
  selectGlossaryState,
  getErrorMessage
);
/**
 * This selector selects the success message from the glossary store.
 */
export const selectSuccessMessage = createSelector(
  selectGlossaryState,
  getSuccessMessage
);
/**
 * The default glossary store selectors provided by the glossary adapter.
 */
export const { selectAll, selectEntities, selectIds, selectTotal } =
  glossaryAdapter.getSelectors(selectGlossaryState);

import { createAction, props } from '@ngrx/store';
import { AnalysisResult } from '../../../shared/models/project-models';
/**
 * Action for the retrieval of all analysis results of a project. Takes a project id.
 */
export const retrieveAllAnalysisResults = createAction(
  '[Analysis] Retrieve All Analysis Results',
  props<{ projectId: number }>()
);
/**
 * Action that is triggered if the retrieval of all analysis results was successful.
 */
export const setAllAnalysisResults = createAction(
  '[Analysis] Set All Analysis Results',
  props<{ analysisResults: AnalysisResult[] }>()
);
/**
 * Action for the deletion of a analysis result. Takes a analysis result id.
 */
export const deleteAnalysisResultRequest = createAction(
  '[Analysis] Delete Analysis Result Request',
  props<{ analysisResultId: number }>()
);
/**
 * Action that is triggered if the deletion of a analysis results was successful.
 */
export const deleteAnalysisResultSuccess = createAction(
  '[Analysis] Delete Analysis Result Success',
  props<{ analysisResultId: number }>()
);
/**
 * Action that is triggered if the deletion of a analysis results failed.
 */
export const deleteAnalysisResultFailure = createAction(
  '[Analysis] Delete Analysis Result Failure',
  props<{ error: string }>()
);
/**
 * Action to clear the analysis results from the store.
 */
export const clearAnalysis = createAction('[Analysis] Clear Analysis');

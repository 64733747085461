import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { reducer } from './project.reducer';
import * as fromProjectState from './project.state';
import { EffectsModule } from '@ngrx/effects';
import { ProjectEffects } from './project.effects';
import { RequirementGroupEffects } from './requirement-root-store/requirement-group-store/requirement-group.effects';
import { RequirementEffects } from './requirement-root-store/requirement-store/requirement.effects';
import { GlossaryEffects } from './glossary-store/glossary.effects';
import { AnalysisEffects } from './analysis-store/analysis.effects';
import { RequirementLockEffects } from './requirement-root-store/requirement-store/requirement-lock-store/requirement-lock.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromProjectState.projectFeatureKey, reducer),
    EffectsModule.forFeature([
      ProjectEffects,
      RequirementEffects,
      RequirementGroupEffects,
      RequirementLockEffects,
      GlossaryEffects,
      AnalysisEffects
    ])
  ]
})
export class ProjectStoreModule {}

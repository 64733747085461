import { AbstractControl, ValidatorFn } from '@angular/forms';

/**
 * A validator for passwords to be used with two form controls. It checks if the password and it's repetition match.
 */
export class PasswordValidator {
  /**
   * Generates a ValidatorFunction that checks if the entries in the first form control and the second match.
   * @param {any} firstControlName The name of the first form control.
   * @param {any} secondControlName The name of the second form control.
   * @returns {ValidatorFn} A ValidatorFunction that checks if the entries in the first form control and the second match.
   */
  static Match(firstControlName, secondControlName): ValidatorFn {
    return (AC: AbstractControl) => {
      let firstControlValue = AC.get(firstControlName).value;
      let secondControlValue = AC.get(secondControlName).value;
      if (firstControlValue != secondControlValue) {
        AC.get(secondControlName).setErrors({ MatchFields: true });
      } else {
        return null;
      }
    };
  }
}

import { createAction, props } from '@ngrx/store';
import { User } from '../../shared/models/user';

/**
 * Action to sign up a user. Takes a user object.
 */
export const signUpRequest = createAction(
  '[Auth] Sign Up Request',
  props<{ user: User }>()
);
/**
 * Action that is triggered if the sign up of a user was successful.
 */
export const signUpSuccess = createAction(
  '[Auth] Sign Up Success',
  props<{ user: User }>()
);
/**
 * Action that is triggered if the sign up of a user failed.
 */
export const signUpFailure = createAction(
  '[Auth] Sign Up Failure',
  props<{ error: string }>()
);
/**
 * Action to sign up a user. Takes a username(email) and a password.
 */
export const loginRequest = createAction(
  '[Auth] Login Request',
  props<{ username: string; password: string }>()
);
/**
 * Action that is triggered if the login of a user was successful.
 */
export const loginSuccess = createAction(
  '[Auth] Login Success',
  props<{ token: any; user: User }>()
);
/**
 * Action that is triggered if the login of a user failed.
 */
export const loginFailure = createAction(
  '[Auth] Login Failure',
  props<{ error: string }>()
);
/**
 * Action to logout a user.
 */
export const logoutRequest = createAction('[Auth] Logout Request');
/**
 * Action that is triggered if the logout of a user was successful.
 */
export const logoutSuccess = createAction('[Auth] Logout Success');
/**
 * Action that is triggered if the logout of a user failed.
 */
export const logoutFailure = createAction(
  '[Auth] Logout Failure',
  props<{ error: string }>()
);
/**
 * Action that is triggered if the page is reloaded by the user using the browser reload functionality.
 */
export const refreshRequest = createAction('[Auth] Refresh Request');
/**
 * Action that is triggered if the refresh was successful.
 */
export const refreshSuccess = createAction(
  '[Auth] Refresh Success',
  props<{ data: any }>()
);
/**
 * Action that is triggered if the refresh failed.
 */
export const refreshFailure = createAction(
  '[Auth] Refresh Failure',
  props<{ error: string }>()
);
/**
 * Action to set the error message of the auth store.
 */
export const setErrorMessage = createAction(
  '[Auth] Set Error Message',
  props<{ error: string }>()
);

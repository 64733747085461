import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { RequirementLock } from '../../../../../shared/models/requirement-models';

/**
 * Action for the creation of a requirement lock. Takes a requirement lock object.
 */
export const createRequirementLockRequest = createAction(
  '[Requirement Locks] Create Requirement Lock Request',
  props<{ projectId: number; requirementLock: RequirementLock }>()
);
/**
 * Action that is triggered if the creation of a requirement lock was successful.
 */
export const createRequirementLockSuccess = createAction(
  '[Requirement Locks] Create Requirement Lock Success',
  props<{ requirementLock: RequirementLock }>()
);
/**
 * Action that is triggered if the creation of a requirement lock failed.
 */
export const createRequirementLockFailure = createAction(
  '[Requirement Locks] Create Requirement Lock Failure',
  props<{ error: string }>()
);
/**
 * Action for the update of a requirement lock. Takes a Update<RequirementLock> object.
 */
export const updateRequirementLockRequest = createAction(
  '[Requirement Locks] Update Requirement Lock Request',
  props<{ requirementLock: Update<RequirementLock> }>()
);
/**
 * Action that is triggered if the update of a requirement lock was successful.
 */
export const updateRequirementLockSuccess = createAction(
  '[Requirement Locks] Update Requirement Lock Success',
  props<{ requirementLock: RequirementLock }>()
);
/**
 * Action that is triggered if the update of a requirement lock failed.
 */
export const updateRequirementLockFailure = createAction(
  '[Requirement Locks] Update Requirement Lock Failure',
  props<{ error: string }>()
);
/**
 * Action for the deletion of a requirement lock. Takes a requirement id.
 */
export const deleteRequirementLockRequest = createAction(
  '[Requirement Locks] Delete Requirement Lock Request',
  props<{ projectId: number; requirementId: number }>()
);
/**
 * Action that is triggered if the deletion of a requirement lock was successful.
 */
export const deleteRequirementLockSuccess = createAction(
  '[Requirement Locks] Delete Requirement Lock Success',
  props<{ requirementId: number }>()
);
/**
 * Action that is triggered if the deletion of a requirement lock failed.
 */
export const deleteRequirementLockFailure = createAction(
  '[Requirement Locks] Delete Requirement Lock Failure',
  props<{ error: string }>()
);
/**
 * Action for the retrieval of all requirement locks in a project. Takes a project id.
 */
export const retrieveAllRequirementLocks = createAction(
  '[Requirement Locks] Retrieve All Requirement Locks',
  props<{ projectId: number }>()
);
/**
 * Action that is triggered if the retrieval of all requirement locks was successful.
 */
export const setAllRequirementLocks = createAction(
  '[Requirement Locks] Set All Requirement Locks',
  props<{ requirementLocks: RequirementLock[] }>()
);

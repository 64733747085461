'use strict';

import { HttpHeaders } from '@angular/common/http';

/**
 * Definition of extra headers to set the content type of the request
 */
export const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

/**
 * The messages that will be used in the store.
 */
export const storeMessages = {
  createTermSuccess: 'New term has been created.',
  updateTermSuccess: 'Term has been updated.',
  deleteTermSuccess: 'Term has been deleted.',
  createRequirementSuccess: 'Requirement has been created.',
  updateRequirementSuccess: 'Requirement has been updated.',
  deleteRequirementSuccess: 'Requirement has been deleted.',
  createRequirementGroupSuccess: 'Requirement Group has been created.',
  updateRequirementGroupSuccess: 'Requirement Group has been updated.',
  deleteRequirementGroupSuccess: 'Requirement Group has been deleted.',
  createRequirementLockSuccess: 'Requirement Lock has been created.',
  updateRequirementLockSuccess: 'Requirement Lock has been updated.',
  deleteRequirementLockSuccess: 'Requirement Lock has been deleted.',
  createProjectSuccess: 'Project has been created.',
  updateProjectSuccess: 'Project has been updated.',
  deleteProjectSuccess: 'Project has been deleted.',
  addProjectMemberSuccess: 'User has been added to this project.',
  createProjectLockSuccess: 'Project has been locked.',
  deleteProjectLockSuccess: 'Project has been unlocked.',
  deleteAnalysisResultSuccess: 'Analysis result has been deleted.'
};

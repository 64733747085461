import { Injectable } from '@angular/core';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { AnalysisActions } from './index';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { AnalysisService } from '../../../shared/services/analysis.service';

/**
 * The effects for the analysis actions.
 */
@Injectable()
export class AnalysisEffects {
  /**
   * The constructor of the analysis effects.
   * @param actions$ Dependency injection of the Actions.
   * @param analysisService Dependency injection of the AnalysisService.
   */
  constructor(
    private actions$: Actions,
    private analysisService: AnalysisService
  ) {}

  /**
   * The effect that gets called when a delete analysis result action has been dispatched.
   * Sends a request to the server to delete an analysis result with the id provided by the action.
   */
  @Effect()
  deleteAnalysisResultRequestEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalysisActions.deleteAnalysisResultRequest),
      exhaustMap((action) =>
        this.analysisService
          .deleteAnalysisResultOnServer(action.analysisResultId)
          .pipe(
            map((analysisResultId: number) =>
              AnalysisActions.deleteAnalysisResultSuccess({
                analysisResultId
              })
            ),
            catchError((error) =>
              of(
                AnalysisActions.deleteAnalysisResultFailure({
                  error
                })
              )
            )
          )
      )
    )
  );
}

import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthStoreService } from '../../shared/services/auth-store.service';

/**
 * Displays the login form.
 */
@Component({
  selector: 'scone-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  /**
   * Login form
   */
  public form: FormGroup;

  /**
   * The constructor of the login component.
   * @param formBuilder Dependency Injection of the angular form builder.
   * @param authStoreService Dependency Injection of the AuthStoreService.
   */
  constructor(
    formBuilder: FormBuilder,
    private authStoreService: AuthStoreService
  ) {
    this.form = formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  /**
   * This function is used to log in the user via the LoginRequestAction of the store using the AuthService.
   * This function is called when the user hits the Login Button of the login form.
   * @example
   * This is a good example
   * login('data')
   *
   * @param data The data the user typed into the login form, email and password.
   */
  login(data: any): void {
    this.authStoreService.dispatchLoginRequest(data);
  }
}

import { Injectable } from '@angular/core';
import {
  RequirementGroupActions,
  RequirementGroupSelectors
} from '../../store/project-store/requirement-root-store/requirement-group-store';
import { filter } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { RequirementGroupState } from '../../store/project-store/requirement-root-store/requirement-group-store/';
import { ProjectStoreService } from './project-store.service';
import {
  RequirementGroup,
  RequirementGroupTrimmed,
  RequirementGroupView
} from '../models/requirement-group-models';

/**
 * The requirement group store service.
 */
@Injectable({
  providedIn: 'root'
})
export class RequirementGroupStoreService {
  /**
   * The constructor of the requirement group store service.
   * @param requirementGroupStore Dependency injection of the RequirementGroupStore.
   * @param projectStoreService Dependency injection of the ProjectStoreService.
   */
  constructor(
    private requirementGroupStore: Store<RequirementGroupState>,
    private projectStoreService: ProjectStoreService
  ) {}

  /**
   * Dispatches a CreateRequirementGroupRequestAction to the store.
   * @param data The data describing the requirement group to be created.
   */
  public dispatchCreateRequirementGroupRequest(data): void {
    this.requirementGroupStore.dispatch(
      RequirementGroupActions.createRequirementGroupRequest({
        requirementGroup: data
      })
    );
  }

  /**
   * Dispatches a RetrieveAllRequirementGroupsAction to the store.
   * @param projectId The id of the project
   */
  public dispatchRetrieveAllRequirementGroups(projectId: number): void {
    this.requirementGroupStore.dispatch(
      RequirementGroupActions.retrieveAllRequirementGroups({ projectId })
    );
  }

  /**
   * Dispatches a RetrieveAllRequirementGroupsAction to the store.
   */
  public dispatchRetrieveAllRequirementGroupsOfSelectedProject(): void {
    this.projectStoreService
      .selectCurrentProjectId()
      .pipe(filter((projectId: number) => projectId !== null))
      .subscribe((projectId: number) => {
        this.requirementGroupStore.dispatch(
          RequirementGroupActions.retrieveAllRequirementGroups({ projectId })
        );
      });
  }

  /**
   * Dispatches an UpdateRequirementGroupRequestAction to the store.
   * @param data The data describing the requirement to be updated.
   */
  public dispatchUpdateRequirementGroupRequest(data: any): void {
    this.requirementGroupStore.dispatch(
      RequirementGroupActions.updateRequirementGroupRequest({
        requirementGroup: data
      })
    );
  }

  /**
   * Dispatches a DeleteRequirementGroupRequestAction to the store.
   * @param id The ID of the requirement group to be deleted.
   */
  public dispatchDeleteRequirementGroupRequest(id: number): void {
    this.requirementGroupStore.dispatch(
      RequirementGroupActions.deleteRequirementGroupRequest({
        id
      })
    );
  }

  /**
   * Selects the requirement groups (including removed ones) from the store.
   * @returns An observable containing the selected requirement groups as an array.
   */
  public selectAllRequirementGroups(): Observable<RequirementGroup[]> {
    return this.requirementGroupStore.select(
      RequirementGroupSelectors.selectAllRequirementGroups
    );
  }

  /**
   * Selects the unremoved requirement groups from the store.
   * @returns An observable containing the selected requirement groups as an array.
   */
  public selectUnremovedRequirementGroups(): Observable<RequirementGroup[]> {
    return this.requirementGroupStore.select(
      RequirementGroupSelectors.selectUnremovedRequirementGroups
    );
  }

  /**
   * Selects the unremoved and trimmed requirement groups from the store.
   * @returns An observable containing the selected trimmed requirement groups as an array.
   */
  public selectUnremovedTrimmedRequirementGroups(): Observable<
    RequirementGroupTrimmed[]
  > {
    return this.requirementGroupStore.select(
      RequirementGroupSelectors.selectUnremovedTrimmedRequirementGroups
    );
  }

  /**
   * Selects the root requirement group from the store.
   * @returns An observable containing the selected root requirement group.
   */
  public selectRootRequirementGroup(): Observable<RequirementGroup> {
    return this.requirementGroupStore.select(
      RequirementGroupSelectors.selectRootRequirementGroup
    );
  }

  /**
   * Selects all requirement group identifiers from the store.
   * @returns An observable emitting the selected identifiers.
   */
  public selectRequirementGroupIdentifiers(): Observable<string[]> {
    return this.requirementGroupStore.select(
      RequirementGroupSelectors.selectRequirementGroupIdentifiers
    );
  }

  /**
   * Selects the unremoved subgroups of a requirement group from the store.
   * @param groupId The ID of the requirement group for which its unremoved subgroups are to be selected.
   * @returns An observable containing the selected requirement groups as an array.
   */
  public selectUnremovedSubgroups(groupId: number): Observable<any[]> {
    return this.requirementGroupStore.select(
      RequirementGroupSelectors.selectUnremovedSubgroups,
      {
        id: groupId
      }
    );
  }

  /**
   * Selects IDs of the unremoved requirement groups from the store.
   * @returns An observable containing the selected requirement group IDs.
   */
  public selectUnremovedRequirementGroupIds(): Observable<any> {
    return this.requirementGroupStore.select(
      RequirementGroupSelectors.selectUnremovedRequirementGroupIds
    );
  }

  /**
   * Selects a requirement group from the store.
   * @param groupId The ID of the requirement group to be selected.
   * @returns An observable containing the selected requirement group.
   */
  public selectRequirementGroup(groupId: number): Observable<RequirementGroup> {
    return this.requirementGroupStore.select(
      RequirementGroupSelectors.selectRequirementGroup(),
      {
        id: groupId
      }
    );
  }

  /**
   * Selects a requirement group view from the store.
   * @param id The ID of the requirement group to be selected.
   * @returns An observable containing the selected requirement group.
   */
  public selectRequirementGroupView(
    id: number
  ): Observable<RequirementGroupView> {
    return this.requirementGroupStore.select(
      RequirementGroupSelectors.selectRequirementGroupView(),
      {
        id
      }
    );
  }

  /**
   * Selects all unremoved requirement group views from the store.
   * @returns An observable containing the selected requirement group views.
   */
  public selectUnremovedRequirementGroupViews(): Observable<any> {
    return this.requirementGroupStore.select(
      RequirementGroupSelectors.selectUnremovedRequirementGroupViews()
    );
  }

  /**
   * Selects the RequirementGroupsErrorMessage from the store.
   * @returns The error message if there is one.
   */
  public selectRequirementGroupsErrorMessage(): Observable<string> {
    return this.requirementGroupStore.select(
      RequirementGroupSelectors.selectErrorMessage
    );
  }

  /**
   * Selects the RequirementGroupsSuccessMessage from the store.
   * @returns The success message if there is one.
   */
  public selectRequirementGroupsSuccessMessage(): Observable<string> {
    return this.requirementGroupStore.select(
      RequirementGroupSelectors.selectSuccessMessage
    );
  }

  /**
   * Selects the property AllRequirementGroupsLoaded from the store which indicates whether all requirement groups(including removed ones)
   * have been loaded or not.
   * @returns An observable containing a boolean that is true if the requirement groups
   * have been loaded and false if not.
   */
  public selectAllRequirementGroupsLoaded(): Observable<boolean> {
    return this.requirementGroupStore.select(
      RequirementGroupSelectors.selectLoaded
    );
  }

  /**
   * Generates a random identifier for a requirement group.
   * @returns Identifier
   */
  public getRandomIdentifier(): string {
    // Number which will always have 4 digits
    let randomIdentifier =
      'RG-' + Math.floor(1000 + Math.random() * 9000).toString();
    this.selectRequirementGroupIdentifiers()
      .subscribe((identifiers: string[]) => {
        for (
          let i = 0;
          i < 1000 && identifiers.includes(randomIdentifier);
          i++
        ) {
          randomIdentifier =
            'RG-' + Math.floor(1000 + Math.random() * 9000).toString();
        }
        randomIdentifier = identifiers.includes(randomIdentifier)
          ? null
          : randomIdentifier;
      })
      .unsubscribe();

    return randomIdentifier;
  }

  /**
   * Determines the group type based on the given ID.
   * @param id The ID of the group type.
   * @returns The group type.
   */
  public getGroupType(id: number): string {
    switch (id) {
      case 1:
        return 'FEATURE';
      case 2:
        return 'XOR';
      case 3:
        return 'OR';
      default:
        return 'FEATURE';
    }
  }

  /**
   * Used to determine the name of a given group type.
   * @param id The ID of the group type.
   * @returns The Name of the group type.
   */
  public getGroupTypeName(id: number): string {
    switch (id) {
      case 1:
        return 'FEATURE';
      case 2:
        return 'ALTERNATIVE';
      case 3:
        return 'SELECTION';
      case 4:
        return 'SELECTION OF';
      default:
        return '';
    }
  }

  /**
   * Used to determine the name of a given group type.
   * @param type The name of the group type.
   * @returns The id of the group type.
   */
  public getGroupTypeId(type: string): number {
    switch (type) {
      case 'FEATURE':
        return 1;
      case 'XOR':
        return 2;
      case 'OR':
        return 3;
      case 'GENOR':
        return 3;
      default:
        return 0;
    }
  }
}

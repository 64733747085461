import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { RequirementActions } from './index';
import { RequirementService } from '../../../../shared/services/requirement.service';
import { Requirement } from '../../../../shared/models/requirement-models';
import { RouterService } from '../../../../shared/services/router.service';

/**
 * The effects for the requirement actions.
 */
@Injectable()
export class RequirementEffects {
  /**
   * The constructor of the requirement effects.
   * @param actions$ Dependency injection of the Actions.
   * @param requirementService Dependency injection of the RequirementService.
   * @param routerService Dependency injection of the RouterService.
   */
  constructor(
    private actions$: Actions,
    private requirementService: RequirementService,
    private routerService: RouterService
  ) {}

  /**
   * The effect that gets called when a create requirement request action has been dispatched.
   * Creates a new requirement with the data provided by the action.
   */
  createRequirementRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RequirementActions.createRequirementRequest),
      switchMap((action) =>
        this.requirementService
          .createRequirementOnServer(action.projectId, action.requirement)
          .pipe(
            map((requirement: Requirement) =>
              RequirementActions.createRequirementSuccess({ requirement })
            ),
            catchError((error) => {
              return of(
                RequirementActions.createRequirementFailure({
                  error: error.error
                })
              );
            })
          )
      )
    )
  );

  /**
   * The effect that gets called when an update requirement request action has been dispatched.
   * Updates the requirement with the data provided by the action.
   */
  updateRequirementRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RequirementActions.updateRequirementRequest),
      switchMap((action) =>
        this.requirementService
          .updateRequirementOnServer(action.requirement)
          .pipe(
            map((requirement) =>
              RequirementActions.updateRequirementSuccess({ requirement })
            ),
            catchError((error) =>
              of(RequirementActions.updateRequirementFailure({ error }))
            )
          )
      )
    )
  );

  /**
   * The effect that gets called when a delete requirement request action has been dispatched.
   * Deletes the requirement with the data provided by the action.
   */
  deleteRequirementRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RequirementActions.deleteRequirementRequest),
      switchMap((action) =>
        this.requirementService.deleteRequirementOnServer(action.id).pipe(
          map(() =>
            RequirementActions.deleteRequirementSuccess({
              requirementId: action.id
            })
          ),
          catchError((error) =>
            of(RequirementActions.deleteRequirementFailure({ error }))
          )
        )
      )
    )
  );

  /**
   * The effect that gets called when a delete requirement group success action has been dispatched.
   * Navigates to the requirement list.
   */
  deleteRequirementSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RequirementActions.deleteRequirementSuccess),
        tap(() => {
          this.routerService.dispatchGoAction(
            this.routerService.stripLastUrlSegment()
          );
        })
      ),
    { dispatch: false }
  );

  /**
   * The effect that gets called when a retrieve all requirements action has been dispatched.
   * Retrieves all requirements from the server and sets them.
   */
  retrieveAllRequirements$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RequirementActions.retrieveAllRequirements),
      switchMap((action) =>
        this.requirementService
          .getRequirements(action.projectId)
          .pipe(
            map((requirements: Requirement[]) =>
              RequirementActions.setAllRequirements({ requirements })
            )
          )
      )
    )
  );
}

import { createFeatureSelector } from '@ngrx/store';
import { getSelectors, RouterReducerState } from '@ngrx/router-store';

/**
 * The feature key for the router store feature.
 */
export const routerFeatureKey = 'router';

/**
 * The interface of the router store state.
 */
export interface RouterState {
  /**
   * The router reducer state.
   */
  router: RouterReducerState<any>;
}

/**
 * The FeatureSelector for the router store.
 */
export const selectRouter = createFeatureSelector<
  RouterState,
  RouterReducerState<any>
>('router');

/**
 * Export of all router store selectors.
 */
export const {
  selectCurrentRoute, // select the current route
  selectFragment, // select the current route fragment
  selectQueryParams, // select the current route query params
  selectQueryParam, // factory function to select a query param
  selectRouteParams, // select the current route params
  selectRouteParam, // factory function to select a route param
  selectRouteData, // select the current route data
  selectUrl // select the current url
} = getSelectors(selectRouter);

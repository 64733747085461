import {
  requirementGroupInitialState,
  RequirementGroupState
} from './requirement-group-store/requirement-group.state';
import { RequirementState } from './requirement-store';
import { initialRequirementState } from './requirement-store/requirement.state';
import { WebsocketMessage } from '../../../shared/models/websocketMessage';

/**
 * The interface of the requirement root store state.
 */
export interface RequirementRootState {
  /**
   * The requirement state.
   */
  requirementState: RequirementState;
  /**
   * The requirement group state.
   */
  requirementGroupState: RequirementGroupState;
  /**
   * The last websocket message that was received.
   */
  websocketMessage: WebsocketMessage;
  /**
   * Indicates whether data is currently being loaded.
   */
  isLoading: boolean;
  /**
   * Indicates whether the data has been loaded successfully.
   */
  loaded: boolean;
}

/**
 * The initial state of the requirement root store.
 */
export const initialRequirementRootState: RequirementRootState = {
  requirementState: initialRequirementState,
  requirementGroupState: requirementGroupInitialState,
  websocketMessage: null,
  isLoading: false,
  loaded: false
};

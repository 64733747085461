<mat-card *ngIf="loggedInUser$ | async as user">
  <h3>User Account</h3>
  <mat-divider></mat-divider>
  <mat-list role="list">
    <mat-list-item role="listitem">
      <span class="info-label">Name:</span>{{ user.first_name }}
      {{ user.last_name }}
    </mat-list-item>
    <mat-list-item role="listitem"
      ><span class="info-label">Email:</span>{{ user.username }}</mat-list-item
    >
    <mat-list-item role="listitem"
      ><span class="info-label">Password:</span>******</mat-list-item
    >
  </mat-list>
</mat-card>

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { httpOptions } from '../miscellaneous/globals';
import { Observable } from 'rxjs/internal/Observable';
import { GlossaryState } from '../../store/project-store/glossary-store';
import { Store } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Term } from '../models/glossary-models';
import { AppConfigurationService } from '../configuration/app-configuration.service';
import { mergeMap } from 'rxjs';
import { ProjectStoreService } from './project-store.service';
import { first } from 'rxjs/operators';

/**
 * The GlossaryService is used interact with the store, to make http requests to the backend and to
 * obtain the synonyms from the external synonym database.
 */
@Injectable({
  providedIn: 'root'
})
export class GlossaryService {
  /**
   * The constructor of the GlossaryService.
   * @param http Dependency injection of the HttpClient
   * @param store Dependency injection of the store.
   * @param configService Dependency inejction of the AppConfigurationService.
   * @param projectStoreService Dependency injection of the ProjectsService.
   */
  constructor(
    private http: HttpClient,
    private store: Store<GlossaryState>,
    private configService: AppConfigurationService,
    private projectStoreService: ProjectStoreService
  ) {}

  /**
   * Fetches the terms from the backend.
   * @param projectId The ID of the project for which the term are to be fetched.
   * @returns An observable containing the fetched terms as an array.
   */
  public fetchTermsFromServer(projectId: number): Observable<Term[]> {
    return this.http.get<Term[]>(
      this.configService.url + 'projects/' + projectId + '/terms/'
    );
  }

  /**
   * Initiates the creation of a term in the backend with the given data.
   * @param data The content of the term to be created.
   * @returns An observable containing the created term.
   */
  public createTermOnServer(data: any): Observable<Term> {
    return this.http.post<Term>(
      this.configService.url + 'projects/' + data.project + '/terms/',
      JSON.stringify(data),
      httpOptions
    );
  }

  /**
   * Initiates the deletion of a term in the backend. A deletion is only an update of the term that will not delete it but
   * set the removed property to true.
   * @param id The ID of the term to be deleted.
   * @returns An observable containing the result of the deletion.
   */
  public deleteTermOnServer(id: number): Observable<any> {
    return this.projectStoreService.selectCurrentProjectId().pipe(
      first(),
      mergeMap((projectId) => {
        return this.http.delete(
          this.configService.url +
            'projects/' +
            projectId +
            '/terms/' +
            id +
            '/',
          httpOptions
        );
      })
    );
  }

  /**
   * Initiates an update of the term in the backend.
   * @param term The changes to the term.
   * @returns An observable containing the result of the update.
   */
  public updateTermOnServer(term: Update<Term>): Observable<any> {
    return this.projectStoreService.selectCurrentProjectId().pipe(
      first(),
      mergeMap((projectId) => {
        return this.http.patch(
          this.configService.url +
            'projects/' +
            projectId +
            '/terms/' +
            term.id +
            '/',
          JSON.stringify(term.changes),
          httpOptions
        );
      })
    );
  }
}

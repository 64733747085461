import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as fromGlobals from '../miscellaneous/globals';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { AuthStoreService } from './auth-store.service';
import { LocalStorageService } from './local-storage.service';
import { AppConfigurationService } from '../configuration/app-configuration.service';

/**
 * The analysis service.
 */
@Injectable({
  providedIn: 'root'
})
export class AnalysisService {
  /**
   * The ID of the current project.
   */
  private projectId: number;

  /**
   * The reference to the Glencoe window if the analysis process has been started.
   */
  private glencoeRef: Window = null;

  /**
   * The constructor of the analysis service.
   * @param http Dependency injection of the HttpClient.
   * @param authStoreService Dependency injection of the AuthStoreService.
   * @param cookieService Dependency injection of the CookieService.
   * @param localStorageService Dependency injection of the LocalStorageService.
   * @param configService Dependency injection of the AppConfigurationService
   */
  constructor(
    private http: HttpClient,
    private authStoreService: AuthStoreService,
    private cookieService: CookieService,
    private localStorageService: LocalStorageService,
    private configService: AppConfigurationService
  ) {}

  /**
   * Fetches the analysis results of a specific project from the backend.
   * @param projectId The project ID for which the analysis results are to be fetched.
   * @returns An observable containing the fetched analysis results.
   */
  public getAnalysisResults(projectId: number): Observable<any[]> {
    return this.http.get<any[]>(
      this.configService.url + 'project/' + projectId + '/analysis-results'
    );
  }

  /**
   * Initiates the deletion of an analysis result in the backend.
   * @param resultId The ID of the analysis result which is to be deleted.
   * @returns An observable containing the result of the deletion.
   */
  deleteAnalysisResultOnServer(resultId: number): Observable<any> {
    return this.http.delete(
      this.configService.url + 'analysis-result/' + resultId,
      fromGlobals.httpOptions
    );
  }

  /**
   * Opens Glencoe and stores the reference of the window. If no URL to Glencoe
   * is provided http://localhost:8080/webapp/ will be used.
   * @param projectId The ID of the project for which an analysis is being conducted.
   * @param url The URL of Glencoe.
   */
  public openGlencoe(
    projectId: number,
    url: string = 'http://localhost:8080/webapp/'
  ): void {
    // TODO hardcoded URL
    this.projectId = projectId;
    this.setCookies();
    if (this.isGlencoeOpen()) {
      this.glencoeRef.close();
    }
    this.glencoeRef = window.open(url);
  }

  /**
   * Closes Glencoe if a reference to it exists.
   */
  public closeGlencoe(): void {
    this.projectId = null;
    this.deleteCookies();
    if (this.isGlencoeOpen()) {
      this.glencoeRef.close();
    }
  }

  /**
   * Indicates whether a reference to a Glencoe window exists. This does not
   * check if Glencoe is open otherwise.
   * @returns True if a reference to a glencoe window exists.
   * False otherwise.
   */
  public isGlencoeOpen(): boolean {
    return this.glencoeRef !== null && !this.glencoeRef.closed;
  }

  /**
   * Deletes the set cookies when the analysis has been terminated.
   */
  public deleteCookies(): void {
    this.cookieService.deleteAll('/');
  }

  /**
   * Sets the cookies containing the meta information for Glencoe.
   */
  public setCookies(): void {
    this.deleteCookies();

    this.cookieService.set(
      'opened_from_scone',
      'True',
      null,
      '/',
      null,
      null,
      'Lax'
    );

    this.cookieService.set(
      'scone_user_id',
      this.authStoreService.getUserID().toString(),
      null,
      '/',
      null,
      null,
      'Lax'
    );

    this.cookieService.set(
      'scone_project_id',
      this.projectId.toString(),
      null,
      '/',
      null,
      null,
      'Lax'
    );

    this.cookieService.set(
      'scone_auth_token',
      this.localStorageService.getToken(),
      null,
      '/',
      null,
      null,
      'Lax'
    );
  }
}

import { Injectable } from '@angular/core';
import { Requirement } from '../models/requirement-models';
import { httpOptions } from '../miscellaneous/globals';
import { mergeMap, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Update } from '@ngrx/entity';
import { ProjectStoreService } from './project-store.service';
import { AppConfigurationService } from '../configuration/app-configuration.service';
import { first } from 'rxjs/operators';

/**
 * The requirement service.
 */
@Injectable({
  providedIn: 'root'
})
export class RequirementService {
  /**
   * The constructor of the RequirementsService.
   * @param http Dependency injection of the HttpClient.
   * @param projectStoreService Dependency injection of the ProjectsService.
   * @param configService Dependency injection of the AppConfigurationService.
   */
  constructor(
    private http: HttpClient,
    private projectStoreService: ProjectStoreService,
    private configService: AppConfigurationService
  ) {}

  /**
   * Fetches the requirements of a specific project from the backend.
   * @param projectId The project ID for which the requirements are to be fetched.
   * @returns An observable containing the fetched requirements.
   */
  public getRequirements(projectId: number): Observable<Requirement[]> {
    return this.http.get<Requirement[]>(
      this.configService.url + 'projects/' + projectId + '/requirements/'
    );
  }

  /**
   * Initiates the creation of a requirement in the backend.
   * @param projectId The id of the project
   * @param data The data describing the requirement to be created.
   * @returns An observable containing the result of the creation.
   */
  public createRequirementOnServer(
    projectId: number,
    data: any
  ): Observable<any> {
    const body = JSON.stringify(data);
    return this.http.post(
      this.configService.url + 'projects/' + projectId + '/requirements/',
      body,
      httpOptions
    );
  }

  public createRequirementLinkOnServer(
    projectId: number,
    data: any
  ): Observable<any> {
    const body = JSON.stringify(data);
    return this.http.post(
      this.configService.url +
        'projects/' +
        projectId +
        '/requirements/' +
        data.first_requirement +
        '/links/',
      body,
      httpOptions
    );
  }

  /**
   * Initiates the update of a requirement in the backend.
   * @param requirement The data describing the new properties of the requirement.
   * @returns An observable containing the result of the update.
   */
  public updateRequirementOnServer(
    requirement: Update<Requirement>
  ): Observable<any> {
    return this.projectStoreService.selectCurrentProjectId().pipe(
      first(),
      mergeMap((projectId) => {
        return this.http.patch(
          this.configService.url +
            'projects/' +
            projectId +
            '/requirements/' +
            requirement.id +
            '/',
          JSON.stringify(requirement.changes),
          httpOptions
        );
      })
    );
  }

  /**
   * Initiates the deletion of a requirement in the backend. A deletion is only an update of the requirement that will
   * not delete it but set the removed property to true.
   * @param id The ID of the requirement to be deleted.
   * @returns An observable containing the result of the deletion.
   */
  public deleteRequirementOnServer(id: number): Observable<any> {
    return this.projectStoreService.selectCurrentProjectId().pipe(
      first(),
      mergeMap((projectId) => {
        return this.http.delete(
          this.configService.url +
            'projects/' +
            projectId +
            '/requirements/' +
            id +
            '/',
          httpOptions
        );
      })
    );
  }
}

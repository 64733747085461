import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule, Provider } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { RootStoreModule } from './store/root-store.module';
import { HttpClientModule } from '@angular/common/http';
import { IsLoggedIn } from './shared/miscellaneous/isLoggedIn';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JWT_OPTIONS, JwtModule } from '@auth0/angular-jwt';
import { DatePipe } from '@angular/common';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { AppConfigurationService } from './shared/configuration/app-configuration.service';
import { jwtOptions } from './jwt-utils';

/**
 * Loads the app configuration from /config via the AppConfigurationService
 * @param configurationService the service itself
 */
export function loadConfig(
  configurationService: AppConfigurationService
): () => Promise<any> {
  return () => configurationService.load();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    CoreModule,
    SharedModule,
    RootStoreModule,
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptions.options
      }
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production
    }),
    StoreRouterConnectingModule.forRoot()
  ],
  providers: [
    IsLoggedIn,
    DatePipe,
    AppConfigurationService,
    {
      provide: APP_INITIALIZER,
      useFactory: loadConfig,
      multi: true,
      deps: [AppConfigurationService]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

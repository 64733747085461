import { createSelector } from '@ngrx/store';
import { requirementAdapter, RequirementState } from './requirement.state';
import { Dictionary } from '@ngrx/entity';
import {
  Priority,
  ProcessingStatus,
  Requirement,
  RequirementLink,
  RequirementLinkView,
  RequirementReferenceType,
  RequirementTrimmed,
  RequirementType,
  RequirementView
} from '../../../../shared/models/requirement-models';
import { selectProjectMembers } from '../../project.selectors';
import {
  selectPriorities,
  selectProcessingStatuses,
  selectReferenceTypes,
  selectRequirementTypes
} from '../../../app-store/app.selectors';
import { User } from '../../../../shared/models/user';
import { RequirementRootState } from '../requirement-root.state';
import { selectRequirementRootState } from '../requirement-root.selectors';
import {
  selectRequirementGroupEntities,
  selectTrimmedRequirementGroup
} from '../requirement-group-store/requirement-group.selectors';
import { RequirementGroup } from '../../../../shared/models/requirement-group-models';

/**
 * This selector selects the whole requirement state from the requirement store.
 */
export const selectRequirementState = createSelector(
  selectRequirementRootState,
  (state: RequirementRootState) => state.requirementState
);

/**
 * The default requirement store selectors provided by the requirement adapter.
 */
export const {
  selectAll: selectAllRequirements,
  selectEntities: selectRequirementEntities,
  selectIds: selectRequirementIds,
  selectTotal: selectRequirementsTotal
} = requirementAdapter.getSelectors(selectRequirementState);

/**
 * This selector takes a id and selects the corresponding requirement from the store if existing.
 */
export const selectRequirement = () =>
  createSelector(
    selectAllRequirements,
    (requirements: Requirement[], props: { id: number }) =>
      requirements.find(
        (requirement: Requirement) => requirement.id === props.id
      )
  );

/**
 * This selector takes a requirement id and selects the requirement type of the requirement with this id from the store.
 */
export const selectRequirementType = () =>
  createSelector(
    selectRequirement(),
    selectRequirementTypes,
    (requirement: Requirement, types: RequirementType[]): RequirementType =>
      requirement &&
      types.find(
        (type: RequirementType) => type.id === requirement.requirement_type
      )
  );

/**
 * This selector takes a requirement id and selects the processing status of the requirement with this id from the store.
 */
export const selectProcessingStatus = () =>
  createSelector(
    selectRequirement(),
    selectProcessingStatuses,
    (
      requirement: Requirement,
      statuses: ProcessingStatus[]
    ): ProcessingStatus =>
      requirement &&
      statuses.find(
        (status: ProcessingStatus) =>
          status.id === requirement.processing_status
      )
  );

/**
 * This selector takes a requirement id and selects the priority of the requirement with this id from the store.
 */
export const selectPriority = () =>
  createSelector(
    selectRequirement(),
    selectPriorities,
    (requirement: Requirement, priorities: Priority[]): Priority =>
      requirement &&
      priorities.find(
        (priority: Priority) => priority.id === requirement.priority
      )
  );

/**
 * This selector takes a requirement id selects the author of the requirement with this id from the store.
 */
export const selectAuthor = () =>
  createSelector(
    selectRequirement(),
    selectProjectMembers,
    (requirement: Requirement, members): User => {
      const found_member = members.find(function (member) {
        return member.user.id === requirement.author;
      });
      return found_member.user;
    }
  );

/**
 * This selector takes a requirement id and selects all links of the requirement with this id from the store.
 */
export const selectLinks = () =>
  createSelector(
    selectRequirementEntities,
    selectRequirement(),
    selectReferenceTypes,
    (
      requirements: Dictionary<Requirement>,
      requirement: Requirement,
      referenceTypes: RequirementReferenceType[]
    ): RequirementLinkView[] => {
      return requirement.linked_requirements.map((link: RequirementLink) => {
        return {
          id: link.id,
          first_requirement: {
            id: requirement.id,
            identifier: requirement.identifier,
            name: requirement.name
          },
          second_requirement: {
            id: link.second_requirement,
            identifier: selectRequirementIdentifier.projector(requirements, {
              id: link.second_requirement
            }),
            name: selectRequirementName.projector(requirements, {
              id: link.second_requirement
            })
          },
          reference_type: referenceTypes.find(
            (type: RequirementReferenceType) => type.id === link.reference_type
          )
        };
      });
    }
  );

/**
 * This selector selects all existing links from the store.
 */
export const selectAllLinks = () =>
  createSelector(
    selectUnremovedRequirements,
    selectReferenceTypes,
    (
      requirements: Requirement[],
      referenceTypes: RequirementReferenceType[]
    ): RequirementLinkView[] => {
      const links: RequirementLinkView[] = [];
      requirements.forEach((requirement) => {
        links.push(
          ...requirement.linked_requirements.map((link: RequirementLink) => {
            return {
              id: link.id,
              first_requirement: {
                id: requirement.id,
                identifier: requirement.identifier,
                name: requirement.name
              },
              second_requirement: {
                id: link.second_requirement,
                identifier: selectRequirementIdentifierFromArray.projector(
                  requirements,
                  {
                    id: link.second_requirement
                  }
                ),
                name: selectRequirementNameFromArray.projector(requirements, {
                  id: link.second_requirement
                })
              },
              reference_type: referenceTypes.find(
                (type: RequirementReferenceType) =>
                  type.id === link.reference_type
              )
            };
          })
        );
      });
      return links;
    }
  );

/**
 * This selector takes a requirement id and selects the corresponding requirement view from the store.
 */
export const selectRequirementView = () =>
  createSelector(
    selectRequirementGroupEntities,
    selectRequirement(),
    selectAuthor(),
    selectRequirementType(),
    selectProcessingStatus(),
    selectPriority(),
    selectLinks(),
    (
      requirementGroups: Dictionary<RequirementGroup>,
      requirement: Requirement,
      author: User,
      requirement_type: RequirementType,
      processing_status: ProcessingStatus,
      priority: Priority,
      linked_requirements: RequirementLinkView[]
    ): RequirementView =>
      requirement && {
        id: requirement.id,
        identifier: requirement.identifier,
        name: requirement.name,
        description: requirement.description,
        note: requirement.note,
        author,
        creation_date: requirement.creation_date,
        alteration_date: requirement.alteration_date,
        requirement_type,
        processing_status,
        priority,
        requirement_group: selectTrimmedRequirementGroup.projector(
          requirementGroups,
          { id: requirement.requirement_group }
        ),
        is_optional: requirement.is_optional,
        lock: requirement.lock,
        linked_requirements,
        refines: requirement.refines
      }
  );

/**
 * This selector selects the requirement views of all requirements with the removed property set to 'false'.
 */
export const selectUnremovedRequirementViews = () =>
  createSelector(
    selectRequirementGroupEntities,
    selectUnremovedRequirements,
    selectProjectMembers,
    selectRequirementTypes,
    selectProcessingStatuses,
    selectPriorities,
    selectAllLinks(),
    (
      requirementGroups: Dictionary<RequirementGroup>,
      requirements: Requirement[],
      users: { id: number; user: User; project: number; role: number }[], // TODO make this a model class
      requirement_types: RequirementType[],
      processing_statuses: ProcessingStatus[],
      priorities: Priority[],
      linked_requirements: RequirementLinkView[]
    ): RequirementView[] =>
      requirements &&
      requirements.map((requirement: Requirement) => ({
        id: requirement.id,
        identifier: requirement.identifier,
        name: requirement.name,
        description: requirement.description,
        note: requirement.note,
        author: users.find((user) => user.user.id === requirement.author).user,
        creation_date: requirement.creation_date,
        alteration_date: requirement.alteration_date,
        requirement_type: requirement_types.find(
          (type) => type.id === requirement.requirement_type
        ),
        processing_status: processing_statuses.find(
          (status) => status.id === requirement.processing_status
        ),
        priority: priorities.find(
          (priority) => priority.id === requirement.priority
        ),
        requirement_group: selectTrimmedRequirementGroup.projector(
          requirementGroups,
          { id: requirement.requirement_group }
        ),
        is_optional: requirement.is_optional,
        lock: requirement.lock,
        linked_requirements: linked_requirements.filter(
          (link) => link.first_requirement['id'] === requirement.id
        ),
        refines: requirement.refines
      }))
  );

/**
 * This selector selects the trimmed version of all requirements with the removed property set to 'false'.
 */
export const selectUnremovedTrimmedRequirements = createSelector(
  selectAllRequirements,
  (requirements: Requirement[]): RequirementTrimmed[] =>
    requirements.map((req: Requirement) => ({
      id: req.id,
      identifier: req.identifier,
      name: req.name
    }))
);

/**
 * This selector takes a requirement id and selects the identifier of the requirement with this id from the store.
 */
export const selectRequirementIdentifier = createSelector(
  selectRequirementEntities,
  (requirements: Dictionary<Requirement>, props: { id: string }) =>
    requirements[props.id].identifier
);

/**
 * This selector takes a requirement id and selects the name of the requirement with this id from the store.
 */
export const selectRequirementName = createSelector(
  selectRequirementEntities,
  (requirements: Dictionary<Requirement>, props: { id: string }) =>
    requirements[props.id].name
);

/**
 * This selector takes a requirement id selects the author of the requirement with this id from the store.
 */
export const selectRequirementIdentifierFromArray = createSelector(
  selectAllRequirements,
  (requirements: Requirement[], props: { id: string }) =>
    requirements.find((req) => req.id === +props.id).identifier
);

/**
 * This selector takes a requirement id selects the name of the requirement with this id from the store.
 */
export const selectRequirementNameFromArray = createSelector(
  selectAllRequirements,
  (requirements: Requirement[], props: { id: string }) =>
    requirements.find((req) => req.id === +props.id).name
);

/**
 * This selector selects all identifiers of requirements.
 */
export const selectRequirementIdentifiers = createSelector(
  selectAllRequirements,
  (requirements: Requirement[]) =>
    requirements.map((requirement: Requirement) => requirement.identifier)
);

/**
 * This selector selects all unremoved requirements.
 */
export const selectUnremovedRequirements = createSelector(
  selectAllRequirements,
  (requirements: Requirement[]) =>
    requirements.filter(
      (requirement: Requirement) => requirement.removed === false
    )
);

/**
 * This selector selects all unremoved
 * requirements that do refine another requirement.
 */
export const selectUnremovedRefiningRequirements = createSelector(
  selectAllRequirements,
  (requirements: Requirement[]) =>
    requirements.filter(
      (requirement: Requirement) =>
        requirement.removed === false && requirement.refines !== null
    )
);

/**
 * This selector takes a requirement id and selects all ids of unremoved
 * requirements that do refine another requirement.
 */
export const selectUnremovedRefiningRequirementIDsByRequirement =
  createSelector(
    selectAllRequirements,
    (requirements: Requirement[], props: { id: number }) =>
      requirements
        .filter(
          (requirement: Requirement) =>
            requirement.removed === false && requirement.refines === props.id
        )
        .map((requirement) => requirement.id)
  );

/**
 * This selector selects all unremoved requirements that do not refine another requirement.
 */
export const selectUnremovedNotRefiningRequirements = createSelector(
  selectAllRequirements,
  (requirements: Requirement[]) =>
    requirements.filter(
      (requirement: Requirement) =>
        requirement.removed === false && requirement.refines === null
    )
);

/**
 * This selector takes a requirement group id and selects all unremoved
 * requirements contained in that group that do not refine another
 * requirement.
 */
export const selectUnremovedNotRefiningRequirementIDsByGroup = createSelector(
  selectAllRequirements,
  (requirements: Requirement[], props: { id: number }) =>
    requirements
      .filter(
        (requirement: Requirement) =>
          requirement.removed === false &&
          requirement.requirement_group === props.id &&
          requirement.refines === null
      )
      .map((requirement) => requirement.id)
);

/**
 * This selector takes a requirement group id and selects all unremoved
 * requirements contained in that group.
 */
export const selectUnremovedRequirementIDsByGroup = createSelector(
  selectAllRequirements,
  (requirements: Requirement[], props: { id: number }) =>
    requirements
      .filter(
        (requirement: Requirement) =>
          requirement.removed === false &&
          requirement.requirement_group === props.id
      )
      .map((requirement: Requirement) => requirement.id)
);

/**
 * This selector selects the 'requirements loaded' value from the requirements store.
 */
export const selectRequirementsLoaded = createSelector(
  selectRequirementState,
  (state: RequirementState) => state.loaded
);

/**
 * This selector selects the 'is loading' value from the store.
 */
export const selectRootIsLoading = createSelector(
  selectRequirementRootState,
  (state: RequirementRootState) => state.isLoading
);

/**
 * This selector selects the error message from the store.
 */
export const selectErrorMessage = createSelector(
  selectRequirementState,
  (state: RequirementState) => state.errorMessage
);

/**
 * This selector selects the success message from the store.
 */
export const selectSuccessMessage = createSelector(
  selectRequirementState,
  (state: RequirementState) => state.successMessage
);

/**
 * This selector selects the current websocket message from the store.
 */
export const selectWebsocketMessage = createSelector(
  selectRequirementRootState,
  (state: RequirementRootState) => state.websocketMessage
);

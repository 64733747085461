import { Injectable } from '@angular/core';
import { httpOptions } from '../miscellaneous/globals';
import { HttpClient } from '@angular/common/http';
import { mergeMap, Observable } from 'rxjs';
import { ProjectStoreService } from './project-store.service';
import { Update } from '@ngrx/entity';
import { RequirementGroup } from '../models/requirement-group-models';
import { AppConfigurationService } from '../configuration/app-configuration.service';
import { first } from 'rxjs/operators';

/**
 * The requirement group service.
 */
@Injectable({
  providedIn: 'root'
})
export class RequirementGroupService {
  /**
   * The constructor of the RequirementGroupService.
   * @param http Dependency injection of the HttpClient.
   * @param projectStoreService Dependency injection of the ProjectStoreService.
   * @param configService Dependency injection of the AppConfigurationService.
   */
  constructor(
    private http: HttpClient,
    private projectStoreService: ProjectStoreService,
    private configService: AppConfigurationService
  ) {}

  /**
   * Fetches the requirement groups of a specific project from the backend.
   * @param projectId The project ID for which the requirement groups are to be fetched.
   */
  public getRequirementGroups(
    projectId: number
  ): Observable<RequirementGroup[]> {
    return this.http.get<RequirementGroup[]>(
      this.configService.url + 'projects/' + projectId + '/requirement-groups/'
    );
  }

  /**
   * Initiates the creation of a requirement group in the backend.
   * @param data The data describing the requirement group to be created.
   * @returns An observable containing the result of the creation.
   */
  public createRequirementGroupOnServer(data): Observable<any> {
    const body = JSON.stringify(data);
    return this.http.post(
      this.configService.url +
        'projects/' +
        data.project +
        '/requirement-groups/',
      body,
      httpOptions
    );
  }

  /**
   * Initiates the update of a requirement group in the backend.
   * @param requirementGroup The changed to the requirement group.
   * @returns An observable containing the result of the update.
   */
  public updateRequirementGroupOnServer(
    requirementGroup: Update<RequirementGroup>
  ): Observable<any> {
    return this.projectStoreService.selectCurrentProjectId().pipe(
      first(),
      mergeMap((id) => {
        return this.http.patch(
          this.configService.url +
            'projects/' +
            id +
            '/requirement-groups/' +
            requirementGroup.id +
            '/',
          JSON.stringify(requirementGroup.changes),
          httpOptions
        );
      })
    );
  }

  /**
   * Initiates the deletion of a requirement group in the backend. A deletion is only an update of the requirement that will
   * not delete it but set the removed property to true.
   * @param id The id of the requirement group to be deleted.
   * @returns An observable containing the result of the deletion.
   */
  public deleteRequirementGroupOnServer(id: number): Observable<any> {
    return this.projectStoreService.selectCurrentProjectId().pipe(
      first(),
      mergeMap((pid) => {
        return this.http.delete(
          this.configService.url +
            'projects/' +
            pid +
            '/requirement-groups/' +
            id +
            '/',
          httpOptions
        );
      })
    );
  }
}

import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LocalStorageService } from './local-storage.service';
import { httpOptions } from '../miscellaneous/globals';
import { User } from '../models/user';
import { AppConfigurationService } from '../configuration/app-configuration.service';

/**
 * Model for a server response.
 */
interface Response {
  /**
   * Contains the JWT.
   */
  access?: string;
}

/**
 * The auth service.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  /**
   *  This service is provided by auth0/angular2-jwt and contains helper functions
   */
  private jwtHelperService: JwtHelperService = new JwtHelperService();

  /**
   * The constructor of the AuthService.
   * @param http Dependency injection of the HttpClient.
   * @param localStorageService Dependency injection of the LocalStorageService.
   * @param configService Dependency injection of AppConfigurationService.
   */
  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private configService: AppConfigurationService
  ) {}

  /**
   * Used to log in a user.
   * @param data The users login data. Consists of e-mail address and password.
   * @returns User observable
   */
  login(data): Observable<{ token: any; user: User }> {
    return this.http.post<{ token: any; user: User }>(
      this.configService.url + 'login/',
      JSON.stringify(data),
      httpOptions
    );
  }

  /**
   * Used to log out the user.
   */
  logout(): Observable<boolean> {
    return of(true);
  }

  /**
   * Initiates the creation of a user account in the backend.
   * @param data The data that describes the user account to be created.
   * @returns The result of the creation.
   */
  public createUserAccount(data): Observable<any> {
    return this.http.post(
      this.configService.url + 'sign-up/',
      JSON.stringify(data),
      httpOptions
    );
  }

  // ----------------------------------------------
  // JWT TOKEN
  // ----------------------------------------------

  /**
   * Requests a new JWT token from the server and saves
   * it in the local storage of the browser.
   */
  public refreshToken(): void {
    const loginExpired = this.checkTokenExpired();
    const refreshToken = this.localStorageService.getRefreshToken();
    const refreshTokenExpired = this.checkRefreshTokenExpired();
    if (refreshToken && loginExpired && !refreshTokenExpired) {
      const body = { refresh: refreshToken };
      this.http
        .post(this.configService.url + 'token/refresh/', body, httpOptions)
        .subscribe((response: Response) => {
          this.localStorageService.setToken('auth_token', response.access);
        })
        .unsubscribe();
    }
  }

  /**
   * Checks if the currently in the local storage saved
   * token is still valid or expired.
   * @returns Returns true if the token in the local storage
   * is expired and returns false if the token is still valid.
   */
  public checkTokenExpired(): boolean {
    return this.jwtHelperService.isTokenExpired(
      localStorage.getItem('auth_token')
    );
  }

  /**
   * Checks if the currently in the local storage saved
   * refresh token is still valid or expired.
   * @returns Returns true if the token in the local storage
   * is expired and returns false if the token is still valid.
   */
  public checkRefreshTokenExpired(): boolean {
    return this.jwtHelperService.isTokenExpired(
      localStorage.getItem('refresh_token')
    );
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { SnackbarService } from '../../shared/services/snackbar.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { WebsocketMessage } from '../../shared/models/websocketMessage';

/**
 * Used to show snackbar messages.
 */
@Component({
  selector: 'scone-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent implements OnInit, OnDestroy {
  /**
   * The queue containing all not yet sent messages.
   */
  private messageQueue: string[] = [];

  /**
   * Indicates whether the messages in the queue are currently being sent.
   */
  private sendingInProgress = false;

  /**
   * The duration of displaying of the snackbar message.
   */
  private displayDuration = 3500;

  /**
   * An observable containing an error message if there is one.
   * Used to show the error message to the user.
   */
  private authErrorMessage$: Subscription;

  /**
   * An observable containing an success message if there is one.
   * Used to show the success message to the user.
   */
  private authSuccessMessage$: Subscription;

  /**
   * An observable containing an error message if there is one.
   * Used to show the error message to the user.
   */
  private projectErrorMessage$: Subscription;

  /**
   * An observable containing an success message if there is one.
   * Used to show the success message to the user.
   */
  private projectSuccessMessage$: Subscription;

  /**
   * An observable containing an error message if there is one.
   * Used to show the error message to the user.
   */
  private requirementErrorMessage$: Subscription;

  /**
   * An observable containing an success message if there is one.
   * Used to show the success message to the user.
   */
  private requirementSuccessMessage$: Subscription;

  /**
   * An observable containing an error message if there is one.
   * Used to show the error message to the user.
   */
  private requirementGroupErrorMessage$: Subscription;

  /**
   * An observable containing an success message if there is one.
   * Used to show the success message to the user.
   */
  private requirementGroupSuccessMessage$: Subscription;

  /**
   * An observable containing an error message if there is one.
   * Used to show the error message to the user.
   */
  private glossaryErrorMessage$: Subscription;

  /**
   * An observable containing an success message if there is one.
   * Used to show the success message to the user.
   */
  private glossarySuccessMessage$: Subscription;

  /**
   * An observable containing an websocket message if there is one.
   * Used to show the websocket message to the user.
   */
  private requirementsWebsocketMessage$: Subscription;

  /**
   * An observable containing an websocket message if there is one.
   * Used to show the websocket message to the user.
   */
  private glossaryWebsocketMessage$: Subscription;

  /**
   * An observable containing an websocket message if there is one.
   * Used to show the websocket message to the user.
   */
  private projectWebsocketMessage$: Subscription;

  /**
   * The constructor of the snackbar component.
   * @param snackbarService Dependency injection of the SnackbarService.
   * @param snackBar Dependency injection of the MatSnackBar.
   */
  constructor(
    private snackbarService: SnackbarService,
    private snackBar: MatSnackBar
  ) {}

  /**
   * The lifecycle hook that is called after Angular has initialized all data-bound properties.
   * Subscribes to messages from the store which get displayed if required.
   */
  ngOnInit(): void {
    this.authErrorMessage$ = this.snackbarService
      .selectAuthErrorMessage()
      .subscribe((error: string) => {
        if (error) {
          const errorMessage = this.parseErrorMessage(error);
          this.queueMessage(errorMessage);
        }
      });

    this.authSuccessMessage$ = this.snackbarService
      .selectAuthSuccessMessage()
      .subscribe((success: string) => {
        if (success) {
          this.queueMessage(success);
        }
      });

    this.projectErrorMessage$ = this.snackbarService
      .selectProjectErrorMessage()
      .subscribe((error: string) => {
        if (error) {
          const errorMessage = this.parseErrorMessage(error);
          this.queueMessage(errorMessage);
        }
      });

    this.projectSuccessMessage$ = this.snackbarService
      .selectProjectSuccessMessage()
      .subscribe((success: string) => {
        if (success) {
          this.queueMessage(success);
        }
      });

    this.requirementErrorMessage$ = this.snackbarService
      .selectRequirementErrorMessage()
      .subscribe((error: string) => {
        if (error) {
          const errorMessage = this.parseErrorMessage(error);
          this.queueMessage(errorMessage);
        }
      });

    this.requirementSuccessMessage$ = this.snackbarService
      .selectRequirementSuccessMessage()
      .subscribe((success: string) => {
        if (success) {
          this.queueMessage(success);
        }
      });

    this.requirementGroupErrorMessage$ = this.snackbarService
      .selectRequirementGroupErrorMessage()
      .subscribe((error: string) => {
        if (error) {
          const errorMessage = this.parseErrorMessage(error);
          this.queueMessage(errorMessage);
        }
      });

    this.requirementGroupSuccessMessage$ = this.snackbarService
      .selectRequirementGroupSuccessMessage()
      .subscribe((success: string) => {
        if (success) {
          this.queueMessage(success);
        }
      });

    this.glossaryErrorMessage$ = this.snackbarService
      .selectGlossaryErrorMessage()
      .subscribe((error: string) => {
        if (error) {
          const errorMessage = this.parseErrorMessage(error);
          this.queueMessage(errorMessage);
        }
      });

    this.glossarySuccessMessage$ = this.snackbarService
      .selectGlossarySuccessMessage()
      .subscribe((success: string) => {
        if (success) {
          this.queueMessage(success);
        }
      });

    this.requirementsWebsocketMessage$ = this.snackbarService
      .selectRequirementWebsocketMessage()
      .subscribe((websocketMessage: WebsocketMessage) => {
        if (websocketMessage) {
          this.queueMessage(websocketMessage.message);
        }
      });

    this.glossaryWebsocketMessage$ = this.snackbarService
      .selectGlossaryWebsocketMessage()
      .subscribe((websocketMessage: WebsocketMessage) => {
        if (websocketMessage) {
          this.queueMessage(websocketMessage.message);
        }
      });

    this.projectWebsocketMessage$ = this.snackbarService
      .selectProjectWebsocketMessage()
      .subscribe((websocketMessage: WebsocketMessage) => {
        if (websocketMessage) {
          this.queueMessage(websocketMessage.message);
        }
      });
  }

  /**
   * Parsed the given error message to extract a string that can be displayed
   * in a snackbar message.
   * @param error The error message from the backend that shall be parsed.
   */
  private parseErrorMessage(error): string {
    let errorMessage =
      'An error occurred. Please report it to an administrator. ' +
      'It might be necessary to reload the page.';

    if ('status' in error && error.status === 403) {
      errorMessage = 'You have no permission to do this.';
    } else if (typeof error === 'string') {
      errorMessage = error;
    } else if ('error' in error && typeof error.error === 'string') {
      errorMessage = error.error;
    } else if ('detail' in error && typeof error.detail === 'string') {
      errorMessage = error.detail;
    }

    return errorMessage;
  }

  /**
   * Adds a new message to the queue of messages to be displayed.
   * @param message The new message that will be added to the queue.
   */
  private queueMessage(message: string): void {
    this.messageQueue.push(message);
    if (!this.sendingInProgress) {
      this.sendNextMessage();
    }
  }

  /**
   * Displays the next not yet displayed message from the queue if there is any.
   * After this, calls itself again. This will empty the queue gradually once called.
   */
  private sendNextMessage(): void {
    if (this.messageQueue.length === 0) {
      this.sendingInProgress = false;
      return;
    }

    this.sendingInProgress = true;
    this.showSnackbarMessage(this.messageQueue.shift());
    setTimeout(() => this.sendNextMessage(), this.displayDuration);
  }

  /**
   * Used to show messages in a snackbar.
   * @param message The message to be displayed.
   */
  private showSnackbarMessage(message: string): void {
    this.snackBar.open(message, 'Close', {
      panelClass: ['scone-snackbar'],
      duration: this.displayDuration,
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }

  /**
   * Called to terminate all active subscriptions.
   */
  ngOnDestroy(): void {
    this.authErrorMessage$.unsubscribe();
    this.authSuccessMessage$.unsubscribe();
    this.projectErrorMessage$.unsubscribe();
    this.projectSuccessMessage$.unsubscribe();
    this.requirementErrorMessage$.unsubscribe();
    this.requirementSuccessMessage$.unsubscribe();
    this.glossaryErrorMessage$.unsubscribe();
    this.glossarySuccessMessage$.unsubscribe();
    this.requirementsWebsocketMessage$.unsubscribe();
    this.glossaryWebsocketMessage$.unsubscribe();
    this.projectWebsocketMessage$.unsubscribe();
  }
}

import { Component, OnInit } from '@angular/core';
import { AuthStoreService } from '../../shared/services/auth-store.service';
import { Observable } from 'rxjs';
import { User } from '../../shared/models/user';

/**
 * The component for the user account.
 */
@Component({
  selector: 'scone-user-account',
  templateUrl: './user-account.component.html',
  styleUrls: ['./user-account.component.scss']
})
export class UserAccountComponent implements OnInit {
  /**
   * The currently logged in user.
   */
  public loggedInUser$: Observable<User>;

  /**
   * The constructor of the UserAccountComponent.
   * @param authStoreService Dependency injection of the AuthStoreService.
   */
  constructor(private authStoreService: AuthStoreService) {}

  /**
   * The lifecycle hook that is called after Angular has initialized all data-bound properties.
   * It sets the currently logged in user.
   */
  ngOnInit() {
    this.loggedInUser$ = this.authStoreService.selectAuthUser();
  }
}

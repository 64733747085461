import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAuthState from './auth.state';
import { User } from '../../shared/models/user';

/**
 * This selector selects the error message from the auth store piece of state.
 */
const getErrorMessage = (state: fromAuthState.AuthState): string =>
  state.errorMessage;
/**
 * This selector selects the success message from the auth store piece of state.
 */
const getSuccessMessage = (state: fromAuthState.AuthState): string =>
  state.successMessage;
/**
 * This selector selects the 'isAuthenticated' value from the auth store piece of state.
 */
const getIsAuthenticated = (state: fromAuthState.AuthState): boolean =>
  state.isAuthenticated;
/**
 * This selector selects the 'isLoading' value from the auth store piece of state.
 */
const getIsLoading = (state: fromAuthState.AuthState): boolean =>
  state.isLoading;
/**
 * This selector selects the user from the auth store piece of state.
 */
const getUser = (state: fromAuthState.AuthState): User => state.user;

/**
 * This selector selects the whole auth state from the auth store.
 */
export const selectAuthState = createFeatureSelector<fromAuthState.AuthState>(
  fromAuthState.authFeatureKey
);

/**
 * This selector selects the error message from the auth store.
 */
export const selectErrorMessage = createSelector(
  selectAuthState,
  getErrorMessage
);

/**
 * This selector selects the success message from the auth store.
 */
export const selectSuccessMessage = createSelector(
  selectAuthState,
  getSuccessMessage
);

/**
 * This selector selects the 'isAuthenticated' value from the auth store.
 */
export const selectIsAuthenticated = createSelector(
  selectAuthState,
  getIsAuthenticated
);

/**
 * This selector selects the user from the auth store.
 */
export const selectAuthUser = createSelector(selectAuthState, getUser);

import { createAction, props } from '@ngrx/store';
import { Requirement } from '../../../../shared/models/requirement-models';
import { Update } from '@ngrx/entity';
import { WebsocketMessage } from '../../../../shared/models/websocketMessage';

/**
 * Action for the creation of a requirement. Takes a requirement object.
 */
export const createRequirementRequest = createAction(
  '[Requirements] Create Requirement Request',
  props<{ projectId: number; requirement: Requirement }>()
);
/**
 * Action that is triggered if the creation of a requirement was successful.
 */
export const createRequirementSuccess = createAction(
  '[Requirements] Create Requirement Success',
  props<{ requirement: Requirement }>()
);
/**
 * Action that is triggered if the creation of a requirement failed.
 */
export const createRequirementFailure = createAction(
  '[Requirements] Create Requirement Failure',
  props<{ error: string }>()
);
/**
 * Action for the update of a requirement. Takes a Update<Requirement> object.
 */
export const updateRequirementRequest = createAction(
  '[Requirements] Update Requirement Request',
  props<{ requirement: Update<Requirement> }>()
);
/**
 * Action that is triggered if the update of a requirement was successful.
 */
export const updateRequirementSuccess = createAction(
  '[Requirements] Update Requirement Success',
  props<{ requirement: Requirement }>()
);
/**
 * Action that is triggered if the update of a requirement failed.
 */
export const updateRequirementFailure = createAction(
  '[Requirements] Update Requirement Failure',
  props<{ error: string }>()
);
/**
 * Action for the deletion of a requirement. Takes a requirement id.
 */
export const deleteRequirementRequest = createAction(
  '[Requirements] Delete Requirement Request',
  props<{ id: number }>()
);
/**
 * Action that is triggered if the deletion of a requirement was successful.
 */
export const deleteRequirementSuccess = createAction(
  '[Requirements] Delete Requirement Success',
  props<{ requirementId: number }>()
);
/**
 * Action that is triggered if the deletion of a requirement failed.
 */
export const deleteRequirementFailure = createAction(
  '[Requirements] Delete Requirement Failure',
  props<{ error: string }>()
);
/**
 * Action for the retrieval of all requirements in a project. Takes a project id.
 */
export const retrieveAllRequirements = createAction(
  '[Requirements] Retrieve All Requirements',
  props<{ projectId: number }>()
);
/**
 * Action that is triggered if the retrieval of all requirement was successful.
 */
export const setAllRequirements = createAction(
  '[Requirements] Set All Requirements',
  props<{ requirements: Requirement[] }>()
);
/**
 * Action to set the websocket message for the requirement store.
 */
export const setWebsocketMessage = createAction(
  '[Requirements] Set Websocket Message',
  props<{ websocketMessage: WebsocketMessage }>()
);
/**
 * Action to clear the requirement store.
 */
export const clearRequirementStore = createAction(
  '[Requirements] Clear Requirement Store'
);

import { Action, createReducer, on, State } from '@ngrx/store';
import * as AuthActions from './auth.actions';
import { AuthState, initialState } from './auth.state';

/**
 * The auth reducers.
 */
const authReducer = createReducer(
  initialState,
  on(AuthActions.signUpRequest, (state) => ({
    ...state,
    errorMessage: null,
    isLoading: true
  })),
  on(AuthActions.signUpSuccess, (state, action) => ({
    ...state,
    user: action.user,
    successMessage:
      'Your account has been successfully created. Please confirm your email address.',
    errorMessage: null,
    isLoading: false
  })),
  on(AuthActions.signUpFailure, (state, action) => ({
    ...state,
    errorMessage: action.error,
    isLoading: false
  })),
  on(AuthActions.loginRequest, (state) => ({
    ...state,
    errorMessage: null,
    isLoading: true
  })),
  on(AuthActions.loginSuccess, (state, action) => ({
    ...state,
    isAuthenticated: true,
    user: action.user,
    errorMessage: null,
    isLoading: false
  })),
  on(AuthActions.loginFailure, (state, action) => ({
    ...state,
    isAuthenticated: false,
    errorMessage: action.error,
    isLoading: false
  })),
  on(AuthActions.logoutRequest, (state) => ({
    ...state,
    errorMessage: null,
    isLoading: true
  })),
  on(AuthActions.logoutSuccess, (state) => ({
    ...state,
    user: null,
    isAuthenticated: false,
    errorMessage: null,
    successMessage: 'You have been successfully logged out.',
    isLoading: false
  })),
  on(AuthActions.logoutFailure, (state, action) => ({
    ...state,
    errorMessage: action.error,
    isLoading: false
  })),
  on(AuthActions.refreshRequest, (state) => ({
    ...state,
    errorMessage: null,
    isLoading: true
  })),
  on(AuthActions.refreshSuccess, (state, action) => ({
    ...state,
    user: action.data,
    isAuthenticated: true,
    errorMessage: null,
    isLoading: false
  })),
  on(AuthActions.refreshFailure, (state, action) => ({
    ...state,
    isAuthenticated: false,
    errorMessage: action.error,
    isLoading: false
  })),
  on(AuthActions.setErrorMessage, (state, action) => ({
    ...state,
    errorMessage: action.error,
    successMessage: null
  }))
);

/**
 * Used to invoke the auth reducers with the given state and action.
 * @param state The current auth state before the dispatched action.
 * @param action The dispatched action that will be used by the reducer to change the current auth state.
 */
export function reducer(state: AuthState | undefined, action: Action) {
  return authReducer(state, action);
}

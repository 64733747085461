import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { UserAccountComponent } from './user-account/user-account.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { IsLoggedIn } from '../shared/miscellaneous/isLoggedIn';
import { LicenseInformationComponent } from './license-information/license-information.component';

const routes: Routes = [
  {
    path: '',
    component: LandingPageComponent,
    resolve: [IsLoggedIn]
  },
  {
    path: 'login',
    component: LandingPageComponent,
    resolve: [IsLoggedIn]
  },
  {
    path: 'sign-up',
    component: LandingPageComponent,
    resolve: [IsLoggedIn]
  },
  {
    path: 'account',
    component: UserAccountComponent,
    canActivate: []
  },
  {
    path: 'license-information',
    component: LicenseInformationComponent
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CoreRoutingModule {}

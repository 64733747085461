import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { httpOptions } from '../miscellaneous/globals';
import { jwtOptions } from '../../jwt-utils';

interface Config {
  beHost: string;
}

@Injectable({
  providedIn: 'root'
})
export class AppConfigurationService {
  public beHost: string;
  public useEncryption: boolean;

  public url: string;
  public wsUrl: string;

  constructor(private http: HttpClient) {}

  load(): Promise<any> {
    if (environment.production) {
      this.useEncryption = true;
      return this.http
        .get<Config>('/config', { responseType: 'json' })
        .toPromise()
        .then((data) => {
          this.beHost = data.beHost;
          this.setUrls();
          jwtOptions.updateAllowedDomains([this.beHost]);

          return data;
        });
    } else {
      this.beHost = 'localhost:8000';
      this.useEncryption = false;
      this.setUrls();
      jwtOptions.updateAllowedDomains([this.beHost]);

      return Promise.resolve();
    }
  }

  setUrls(): void {
    // noinspection HttpUrlsUsage HTTP used on purpose here for dev-environment
    this.url =
      (this.useEncryption ? 'https://' : 'http://') + this.beHost + '/';
    this.wsUrl = (this.useEncryption ? 'wss://' : 'ws://') + this.beHost + '/';
  }
}

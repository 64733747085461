import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../store/app-store/app.state';
import * as AppSelectors from '../../store/app-store/app.selectors';
import * as AppActions from '../../store/app-store/app.actions';
import {
  Priority,
  ProcessingStatus,
  RequirementReferenceType,
  RequirementType
} from '../models/requirement-models';
import { ProjectIcon } from '../models/project-models';
import { RequirementGroupType } from '../models/requirement-group-models';

/**
 * The app store service.
 */
@Injectable({
  providedIn: 'root'
})
export class AppStoreService {
  /**
   * The constructor of the app store service.
   * @param store Dependency injection of the AppStore.
   */
  constructor(private store: Store<AppState>) {}

  /**
   * Dispatches a RetrieveAppDataAction to the store.
   */
  public dispatchRetrieveAppData(): void {
    this.store.dispatch(AppActions.retrieveAppData());
  }

  /**
   * Selects the project icons from the store.
   * @returns An observable emitting project icons.
   */
  public selectProjectIcons(): Observable<ProjectIcon[]> {
    return this.store.pipe(select(AppSelectors.selectProjectIcons));
  }

  /**
   * Selects the processing status for requirements from the store.
   * @returns An observable emitting processing status.
   */
  public selectProcessingStatuses(): Observable<ProcessingStatus[]> {
    return this.store.pipe(select(AppSelectors.selectProcessingStatuses));
  }

  /**
   * Selects the priorities from the store.
   * @returns An observable emitting priorities.
   */
  public selectPriorities(): Observable<Priority[]> {
    return this.store.pipe(select(AppSelectors.selectPriorities));
  }

  /**
   * Selects the requirement types from the store.
   * @returns An observable emitting requirement types.
   */
  public selectRequirementTypes(): Observable<RequirementType[]> {
    return this.store.pipe(select(AppSelectors.selectRequirementTypes));
  }

  /**
   * Selects the reference types for links from the store.
   * @returns An observable emitting reference types.
   */
  public selectReferenceTypes(): Observable<RequirementReferenceType[]> {
    return this.store.pipe(select(AppSelectors.selectReferenceTypes));
  }

  /**
   * Selects the requirement group types from the store.
   * @returns An observable emitting requirement group types.
   */
  public selectRequirementGroupTypes(): Observable<RequirementGroupType[]> {
    return this.store.pipe(select(AppSelectors.selectRequirementGroupTypes));
  }

  /**
   * Selects isLoading from the store which indicates whether loading is currently in progress.
   * @returns An observable emitting isLoading as boolean.
   */
  public selectIsLoading(): Observable<boolean> {
    return this.store.pipe(select(AppSelectors.selectIsLoading));
  }

  /**
   * Selects loaded from the store which indicates whether the loading has been finished without errors.
   * @returns An observable emitting loaded as boolean.
   */
  public selectLoaded(): Observable<boolean> {
    return this.store.pipe(select(AppSelectors.selectLoaded));
  }

  /**
   * Selects the error message from the store which describes potential errors that happened
   * when communicating with the backend.
   * @returns An observable emitting the error messages as string.
   */
  public selectErrorMessage(): Observable<string> {
    return this.store.pipe(select(AppSelectors.selectErrorMessage));
  }
}

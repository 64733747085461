import { AppState } from './app.state';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  Priority,
  ProcessingStatus,
  RequirementReferenceType,
  RequirementType
} from '../../shared/models/requirement-models';
import { ProjectIcon } from '../../shared/models/project-models';
import { RequirementGroupType } from '../../shared/models/requirement-group-models';

/**
 * This selector selects the 'project icons' piece of state.
 */
const getProjectIcons = (state: AppState): ProjectIcon[] => state.projectIcons;
/**
 * This selector selects the 'processing status' piece of state.
 */
const getProcessingStatus = (state: AppState): ProcessingStatus[] =>
  state.processingStatus;
/**
 * This selector selects the 'priorities' piece of state.
 */
const getPriorities = (state: AppState): Priority[] => state.priorities;
/**
 * This selector selects the 'requirement types' piece of state.
 */
const getRequirementTypes = (state: AppState): RequirementType[] =>
  state.requirementTypes;
/**
 * This selector selects the 'reference types' piece of state.
 */
const getReferenceTypes = (state: AppState): RequirementReferenceType[] =>
  state.referenceTypes;
/**
 * This selector selects the 'requirement group types' piece of state.
 */
const getRequirementGroupTypes = (state: AppState): RequirementGroupType[] =>
  state.requirementGroupTypes;
/**
 * This selector selects the 'is loading' value from the app store piece of state.
 */
const getIsLoading = (state: AppState): boolean => state.isLoading;
/**
 * This selector selects the 'loaded' value from the app store piece of state.
 */
const getLoaded = (state: AppState): boolean => state.loaded;
/**
 * This selector selects the 'error message' piece of state.
 */
const getErrorMessage = (state: AppState): string => state.errorMessage;

/**
 * This selector selects the feature 'app' from the app store.
 */
export const selectAppState = createFeatureSelector<AppState>('app');
/**
 * This selector selects all project icons from the app store.
 */
export const selectProjectIcons = createSelector(
  selectAppState,
  getProjectIcons
);
/**
 * This selector selects all processing statuses from the app store.
 */
export const selectProcessingStatuses = createSelector(
  selectAppState,
  getProcessingStatus
);
/**
 * This selector selects all priorities from the app store.
 */
export const selectPriorities = createSelector(selectAppState, getPriorities);
/**
 * This selector selects all requirement types from the app store.
 */
export const selectRequirementTypes = createSelector(
  selectAppState,
  getRequirementTypes
);
/**
 * This selector selects all reference types from the app store.
 */
export const selectReferenceTypes = createSelector(
  selectAppState,
  getReferenceTypes
);
/**
 * This selector selects all requirement group types from the app store.
 */
export const selectRequirementGroupTypes = createSelector(
  selectAppState,
  getRequirementGroupTypes
);
/**
 * This selector selects the 'is loading' value from the app store.
 */
export const selectIsLoading = createSelector(selectAppState, getIsLoading);
/**
 * This selector selects the 'loaded' value from the app store.
 */
export const selectLoaded = createSelector(selectAppState, getLoaded);
/**
 * This selector selects the error message from the app store.
 */
export const selectErrorMessage = createSelector(
  selectAppState,
  getErrorMessage
);

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRouter from '../../store/router-store';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { RouterState } from '../../store/router-store';

/**
 * The RouterService is used to interact with the store and to process URLs.
 */
@Injectable({
  providedIn: 'root'
})
export class RouterService {
  /**
   * The constructor of the RouterService.
   * @param store Dependency injection for the Store.
   * @param activatedRoute Dependency injection for the ActivatedRoute.
   * @param router Dependency injection for the Router.
   */
  constructor(
    private store: Store<RouterState>,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  /**
   * Selects the URL parameters from the store.
   * @returns An observable containing the URL parameters.
   */
  public selectParams(): Observable<Params> {
    return this.store.select(fromRouter.selectRouteParams);
  }

  /**
   * Selects the URL query parameters from the store.
   * @returns An observable containing the URL parameters.
   */
  public selectQueryParams(): Observable<Params> {
    return this.store.select(fromRouter.selectQueryParams);
  }

  /**
   * Selects the URL from the store.
   * @returns An observable containing the URL parameters.
   */
  public selectURL(): Observable<string> {
    return this.store.select(fromRouter.selectUrl);
  }

  /**
   * Used to get the last segment of the current URL.
   * @returns The last segment of the URL.
   */
  public getLastUrlSegment(): string {
    let segment = '';
    this.store
      .select(fromRouter.selectCurrentRoute)
      .subscribe((route) => {
        segment = route.routeConfig.path;
      })
      .unsubscribe();

    return segment;
  }

  /**
   * Used to strip the last segment of the current URL.
   * @returns The URL without the last segment.
   */
  public stripLastUrlSegment(): string {
    let strippedUrl: string = '';
    this.selectURL()
      .subscribe((url: string) => {
        let segments = url.split('/');
        segments.pop();
        strippedUrl = segments.join('/');
      })
      .unsubscribe();

    return strippedUrl;
  }

  /**
   * Dispatches a GoAction to the store.
   * @param pathToGo The path to which is to be navigated.
   */
  public dispatchGoAction(pathToGo): void {
    this.router.navigate([pathToGo]);
  }
}

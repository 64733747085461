import {
  Priority,
  ProcessingStatus,
  RequirementReferenceType,
  RequirementType
} from '../../shared/models/requirement-models';
import { ProjectIcon } from '../../shared/models/project-models';
import { RequirementGroupType } from '../../shared/models/requirement-group-models';

/**
 * The feature key of the app store.
 */
export const appFeatureKey = 'app';

/**
 * The interface of the app store state.
 */
export interface AppState {
  /**
   * The project icons that the user can choose from when creating a project.
   */
  projectIcons: ProjectIcon[];
  /**
   * The processing statuses that the user can choose from when creating
   * or updating a requirement.
   */
  processingStatus: ProcessingStatus[];
  /**
   * The priorities that the user can choose from when creating or updating
   * a requirement.
   */
  priorities: Priority[];
  /**
   * The requirement types that the that the user can choose from when creating
   * or updating a requirement.
   */
  requirementTypes: RequirementType[];
  /**
   * The reference types that the user can choose from when creating or updating
   * links between requirements or requirement groups.
   */
  referenceTypes: RequirementReferenceType[];
  /**
   * The requirement group types that the that the user can choose from when creating
   * or updating a requirement group.
   */
  requirementGroupTypes: RequirementGroupType[];
  /**
   * Indicates whether the data is currently being loaded.
   */
  isLoading: boolean;
  /**
   * Indicates whether the data has been loaded successfully.
   */
  loaded: boolean;
  /**
   * The last error message that was received from the server.
   */
  errorMessage: string;
}

/**
 * The initial state of the app store.
 */
export const initialAppState: AppState = {
  projectIcons: null,
  processingStatus: null,
  priorities: null,
  requirementTypes: null,
  referenceTypes: null,
  requirementGroupTypes: null,
  isLoading: false,
  loaded: false,
  errorMessage: null
};

import { User } from '../../shared/models/user';

/**
 * The feature key of the auth store.
 */
export const authFeatureKey = 'auth';

/**
 * The interface of the auth store state.
 */
export interface AuthState {
  /**
   * Indicates whether the user is authenticated.
   */
  isAuthenticated: boolean;
  /**
   * The currently logged in user.
   */
  user: User | null;
  /**
   * Indicates whether the data is currently being loaded.
   */
  isLoading: boolean;
  /**
   * The last error message that was received from the server.
   */
  errorMessage: string;
  /**
   * The last success message that was received from the server.
   */
  successMessage: string;
}

/**
 * The initial state of the auth store.
 */
export const initialState: AuthState = {
  isAuthenticated: false,
  user: null,
  isLoading: false,
  errorMessage: null,
  successMessage: null
};

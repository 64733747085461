import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as AppActions from './app.actions';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { AppService } from '../../shared/services/app.service';
import { of } from 'rxjs';

/**
 * The effects for the app actions.
 */
@Injectable()
export class AppEffects {
  /**
   * The constructor of the app effects.
   * @param actions$ Dependency injection of the Actions.
   * @param appService Dependency injection of the AppService.
   */
  constructor(private actions$: Actions, private appService: AppService) {}

  /**
   * The effect that gets called when a retrieve app data action has been dispatched.
   * Retrieves the app data from the server and sets it.
   */
  retrieveAppData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.retrieveAppData),
      exhaustMap(() =>
        this.appService.getAppData().pipe(
          map((appData: any) => AppActions.setAppData({ appData })),
          catchError((error) =>
            of(AppActions.retrieveAppDataFailure({ error }))
          )
        )
      )
    )
  );
}

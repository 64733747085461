import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  CanActivateChild
} from '@angular/router';
import { Observable } from 'rxjs';
import { AppStoreService } from '../services/app-store.service';
import { AuthService } from '../services/auth.service';
import { AuthStoreService } from '../services/auth-store.service';
import { RouterService } from '../services/router.service';
import { filter, map, take } from 'rxjs/operators';
import { User } from '../models/user';
import { ProjectStoreService } from '../services/project-store.service';
import { Project } from '../models/project-models';

/**
 * The root guard.
 */
@Injectable({
  providedIn: 'root'
})
export class RootGuard implements CanActivate, CanActivateChild {
  /**
   * The currently active URL.
   */
  currentURL: string;

  /**
   * The constructor of the root guard.
   * @param appStoreService Dependency injection of the AppStoreService.
   * @param authService Dependency injection of the AuthService.
   * @param authStoreService Dependency injection of the AuthStoreService.
   * @param projectStoreService Dependency injection of the ProjectStoreService.
   * @param routerService Dependency injection of the RouterService.
   */
  constructor(
    private appStoreService: AppStoreService,
    private authService: AuthService,
    private authStoreService: AuthStoreService,
    private projectStoreService: ProjectStoreService,
    private routerService: RouterService
  ) {}

  /**
   * Determines whether the URL can be opened.
   * @param route The activated route snapshot.
   * @param state The router state snapshot.
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | boolean | UrlTree {
    return this.check(route, state);
  }

  /**
   * Determines whether a child route can be opened. Always true.
   * @param route The activated route snapshot.
   * @param state The router state snapshot.
   */
  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }

  /**
   * Performs the activate checks for a URL. Returns true if URL can be accessed.
   * @param route The activated route snapshot.
   * @param state The router state snapshot.
   */
  check(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (state.url === '/') {
      return true;
    } else if (
      state.url === '/privacy-policy' ||
      state.url === '/license-information'
    ) {
      return this.checkAuthStore(false);
    }

    if (route.url[0]) {
      if (route.url[0].path === 'help') {
        return this.checkAuthStore(false);
      }
    }

    if (route.url[0]) {
      const canActivate = this.checkAuthStore(true);
      if (!canActivate) {
        this.routerService.dispatchGoAction('./');
      } else {
        this.checkAppStore();
        if (route.url[0].path === 'projects') {
          this.checkProjectListStore();
        } else if (route.url[0].path === 'project') {
          this.checkProjectListStore();
          this.checkProjectStore();
        }
      }
    }
    return true;
  }

  /**
   * Checks whether a user is already authenticated in the AuthStore.
   *
   * @returns Returns true if user is already logged in. Returns false
   * if no user is logged in or if the login has expired.
   */
  checkAuthStore(blockAccess: boolean): boolean {
    let canAccess = true;
    this.authStoreService
      .selectIsAuthenticated()
      .subscribe((authenticated: boolean) => {
        const loginExpired = this.authService.checkTokenExpired();
        if (!loginExpired) {
          if (!authenticated) {
            this.authStoreService.dispatchRefreshRequest();
          }
        } else {
          if (!this.authService.checkRefreshTokenExpired()) {
            this.authService.refreshToken();
          } else if (blockAccess) {
            this.authStoreService.dispatchSetErrorMessage(
              'You are not allowed to open this page. Please log in to continue.'
            );
            canAccess = false;
          }
        }
      })
      .unsubscribe();
    return canAccess;
  }

  /**
   * Checks whether the app store has already been filled. If not, loads the data.
   */
  checkAppStore(): void {
    this.appStoreService
      .selectLoaded()
      .subscribe((loaded: boolean) => {
        if (!loaded) {
          this.appStoreService.dispatchRetrieveAppData();
        }
      })
      .unsubscribe();
  }

  /**
   * Checks whether the project list store has already been filled. If not, loads the data.
   */
  checkProjectListStore(): void {
    this.authStoreService
      .selectAuthUser()
      .pipe(
        filter((user: User) => user !== null),
        map((user) => user.id)
      )
      .subscribe((userId: number) => {
        // first check if projects are already loaded. If not, load them into the store.
        this.projectStoreService
          .selectAllProjects()
          .pipe(
            filter((projects: Project[]) => projects.length === 0),
            take(1)
          )
          .subscribe(() =>
            this.projectStoreService.dispatchRetrieveAllProjects()
          )
          .unsubscribe();
      })
      .unsubscribe();
  }

  /**
   * Checks whether the project store has already been filled. If not, loads the data.
   */
  checkProjectStore(): void {
    this.routerService
      .selectURL()
      .subscribe((url: string) => {
        const projectId: number = +url.split('/')[2];
        this.projectStoreService
          .selectCurrentProjectId()
          .subscribe((currentProjectId: number) => {
            if (projectId !== currentProjectId) {
              this.projectStoreService.dispatchOpenProject(projectId);
            }
          })
          .unsubscribe();
      })
      .unsubscribe();
  }
}

import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PasswordValidator } from '../../shared/validators/password.validator';
import { AuthStoreService } from '../../shared/services/auth-store.service';

/**
 * Displays the sign up form.
 */
@Component({
  selector: 'scone-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent {
  /**
   * Holds the input of the sign up fields.
   */
  public form: FormGroup;

  /**
   * The constructor of the SignUpComponent.
   * @param authStoreService Dependency injection of the AuthService.
   * @param formBuilder Dependency injection of the FormBuilder.
   */
  constructor(
    private authStoreService: AuthStoreService,
    formBuilder: FormBuilder
  ) {
    this.form = formBuilder.group(
      {
        first_name: ['', Validators.required],
        last_name: ['', Validators.required],
        username: [
          '',
          [
            Validators.required,
            Validators.pattern(
              '^[a-zA-Z0-9_.+-]+@(hochschule-trier|fh-trier)\\.de$'
            ) // somethingWithNumbers@(hochschule-trier|fh-trier).de
          ]
        ],
        password1: [
          '',
          [
            Validators.required,
            Validators.pattern(
              // prettier-ignore
              '^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d!$%@#£€*?&"§&/()=+#-_.:,;<>]{12,}$'
            ) // At least 12 characters in length, min one number, min one letter
          ]
        ],
        password2: ['', Validators.required],
        confirmation: [false, Validators.requiredTrue]
      },
      { validator: PasswordValidator.Match('password1', 'password2') }
    );
  }

  /**
   * Used to parse the input of the user and to sign him up. This will reset the input form as well.
   * @param form The form group which contains the input of the user.
   * @param formDirective The directive which will be used to reset the form after the data has been parsed.
   */
  createUserAccount(form, formDirective): void {
    let _form = form;
    _form.email = form.username;
    _form.is_active = false;
    this.authStoreService.dispatchSignUp(_form);
    formDirective.resetForm();
    this.form.reset();
  }
}

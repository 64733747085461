import { analysisAdapter, AnalysisState } from './analysis.state';
import { createSelector } from '@ngrx/store';
import { User } from '../../../shared/models/user';
import { selectProjectMembers, selectProjectState } from '../project.selectors';
import { ProjectState } from '../project.state';
import {
  AnalysisResult,
  AnalysisResultView
} from '../../../shared/models/project-models';

/**
 * This selector selects the whole analysis state from the project store.
 */
export const selectAnalysisState = createSelector(
  selectProjectState,
  (state: ProjectState) => state.analysis
);

/**
 * The default analysis store selectors provided by the analysis adapter.
 */
export const {
  selectAll: selectAllAnalysisResults,
  selectEntities,
  selectIds,
  selectTotal
} = analysisAdapter.getSelectors(selectAnalysisState);

/**
 * This selector selects the analysis result views from the analysis store.
 */
export const selectAllAnalysisResultViews = () =>
  createSelector(
    selectAllAnalysisResults,
    selectProjectMembers,
    (
      analysisResults: AnalysisResult[],
      members: User[]
    ): AnalysisResultView[] =>
      analysisResults.map((result: AnalysisResult) => {
        return {
          id: result.id,
          project: result.project,
          date: result.date,
          analyst: members.find((member: User) => member.id === result.analyst),
          features: result.features,
          variant_features: result.variant_features,
          common_features: result.common_features,
          unique_features: result.unique_features,
          dead_features: result.dead_features,
          optional_features: result.optional_features,
          false_optional_features: result.false_optional_features,
          atomic_sets: result.atomic_sets,
          max_children_per_feature: result.max_children_per_feature,
          avg_children_per_feature: result.avg_children_per_feature,
          constraints: result.constraints,
          possible_configurations: result.possible_configurations,
          consistent: result.consistent,
          variability: result.variability,
          height: result.height,
          gfm: result.gfm
        };
      })
  );

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as ProjectSelectors from '../../store/project-store/project.selectors';
import * as ProjectActions from '../../store/project-store/project.actions';
import { GlobalSelectors, ProjectState } from '../../store/project-store';
import { WebsocketMessage } from '../models/websocketMessage';
import {
  Project,
  ProjectLock,
  ProjectView,
  Statistics
} from '../models/project-models';

/**
 * The project store service.
 */
@Injectable({
  providedIn: 'root'
})
export class ProjectStoreService {
  /**
   * The constructor of the project store service.
   * @param projectStore Dependency injection of the ProjectStore.
   */
  constructor(private projectStore: Store<ProjectState>) {}

  /**
   * Dispatches an OpenProject action to the project store.
   * @param projectId The ID of the project that is to be opened.
   */
  public dispatchOpenProject(projectId: number): void {
    this.projectStore.dispatch(
      ProjectActions.openProjectRequest({ projectId })
    );
  }

  /**
   * Dispatches an UpdateProjectRequest action to the projectStore.
   * @param project The data describing the project to be created.
   */
  public dispatchUpdateProject(project): void {
    this.projectStore.dispatch(
      ProjectActions.updateProjectRequest({ project })
    );
  }

  /**
   * Dispatches a DeleteProjectRequest action to the projectStore.
   * @param projectId The ID of the project to be deleted.
   */
  public dispatchDeleteProject(projectId: number): void {
    this.projectStore.dispatch(
      ProjectActions.deleteProjectRequest({ projectId })
    );
  }

  /**
   * Dispatches a RetrieveAllProjects action to the projectStore.
   */
  public dispatchRetrieveAllProjects(): void {
    this.projectStore.dispatch(ProjectActions.retrieveAllProjects());
  }

  /**
   * Dispatches an RetrieveProjectMembersRequest action to the projectStore.
   * @param projectId The ID of the project for which its members are to
   * be retrieved.
   */
  public dispatchRetrieveProjectMembersRequest(projectId: number): void {
    this.projectStore.dispatch(
      ProjectActions.retrieveProjectMembersRequest({
        projectId
      })
    );
  }

  /**
   * Dispatches an AddProjectMemberRequest action to the projectStore.
   * @param data The data describing the new project member.
   */
  public dispatchAddProjectMemberRequest(data): void {
    this.projectStore.dispatch(
      ProjectActions.addProjectMemberRequest({
        email: data.user,
        projectId: data.project
      })
    );
  }

  /**
   * Dispatches a CreateProjectRequest action to the projectStore.
   * @param data The data describing the new project.
   */
  public dispatchCreateProject(data): void {
    this.projectStore.dispatch(
      ProjectActions.createProjectRequest({
        project: data
      })
    );
  }

  /**
   * Dispatches a CreateProjectLockRequest a to the project store.
   * @param projectLock The project lock that is to be created.
   */
  public dispatchCreateProjectLockRequest(projectLock: ProjectLock): void {
    this.projectStore.dispatch(
      ProjectActions.createProjectLockRequest({ projectLock })
    );
  }

  /**
   * Dispatches a DeleteProjectLockRequest action to the projectStore.
   * @param projectId The ID of the project for which its project lock is to be deleted.
   */
  public dispatchDeleteProjectLockRequest(projectId: number): void {
    this.projectStore.dispatch(
      ProjectActions.deleteProjectLockRequest({ projectId })
    );
  }

  /**
   * Dispatches a SetWebsocketMessage action to the projectStore.
   * @param message The message to be set.
   */
  public dispatchSetWebsocketMessage(message: string): void {
    const timestamp = new Date();
    const websocketMessage: WebsocketMessage = { timestamp, message };
    this.projectStore.dispatch(
      ProjectActions.setWebsocketMessage({ websocketMessage })
    );
  }

  /**
   * Dispatches a ClearProject action to the projectStore.
   */
  public dispatchClearProject(): void {
    this.projectStore.dispatch(ProjectActions.clearProject());
  }

  /**
   * Selects all projects from the projectStore.
   * @returns An observable emitting the projects.
   */
  public selectAllProjects(): Observable<Project[]> {
    return this.projectStore.select(ProjectSelectors.selectAllProjects);
  }

  /**
   * Selects a project from the projectStore.
   * @param projectId The ID of the project to be selected.
   */
  public selectProject(projectId): Observable<Project> {
    return this.projectStore.select(ProjectSelectors.selectProject(), {
      projectId
    });
  }

  /**
   * Selects a project from the projectStore.
   * @param projectId The ID of the project to be selected.
   */
  public selectProjectView(projectId): Observable<ProjectView> {
    return this.projectStore.select(ProjectSelectors.selectProjectView(), {
      projectId
    });
  }

  /**
   * Selects current project view from the projectStore.
   */
  public selectCurrentProjectView(): Observable<ProjectView> {
    return this.projectStore.select(
      ProjectSelectors.selectCurrentProjectView()
    );
  }

  /**
   * Selects the ID of the project that is currently open from the project projectStore.
   * @returns An observable which emits the ID of the project.
   */
  public selectCurrentProjectId(): Observable<number> {
    return this.projectStore.select(ProjectSelectors.selectCurrentProjectId);
  }

  /**
   * Selects the loaded property from the project projectStore.
   * @returns An observable which emits a boolean.
   */
  public selectLoaded(): Observable<boolean> {
    return this.projectStore.select(ProjectSelectors.selectLoaded);
  }

  /**
   * Selects the members of a project from the projectStore.
   * @returns An observable emitting the members of the project.
   */
  public selectProjectMembers(): Observable<any> {
    return this.projectStore.select(ProjectSelectors.selectProjectMembers);
  }

  /**
   * Selects one member of a project from the projectStore.
   * @param userId The ID of the member to be selected.
   * @returns An observable emitting the member of the project.
   */
  public selectProjectMember(userId: number): Observable<any> {
    return this.projectStore.select(ProjectSelectors.selectProjectMember(), {
      userId
    });
  }

  /**
   * Selects the members of a project from the projectStore.
   * @returns An observable emitting the members of the project.
   */
  public selectProjectLock(): Observable<any> {
    return this.projectStore.select(ProjectSelectors.selectProjectLock);
  }

  /**
   * Selects the statistics of the project from the projectStore.
   * @returns An observable emitting the statistics of the project.
   */
  public selectStatistics(): Observable<Statistics> {
    return this.projectStore.select(GlobalSelectors.selectStatistics());
  }
}

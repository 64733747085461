import { Actions, createEffect, ofType } from '@ngrx/effects';
import { RequirementLockService } from '../../../../../shared/services/requirement-lock.service';
import { Injectable } from '@angular/core';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { RequirementLockActions } from './index';
import { of } from 'rxjs';
import { RequirementLock } from '../../../../../shared/models/requirement-models';

/**
 * The effects for the requirement lock actions.
 */
@Injectable()
export class RequirementLockEffects {
  /**
   * The constructor of the requirement lock effects.
   * @param actions$ Dependency injection of the Actions.
   * @param requirementLockService Dependency injection of the RequirementLockService.
   */
  constructor(
    private actions$: Actions,
    private requirementLockService: RequirementLockService
  ) {}

  /**
   * The effect that gets called when a retrieve all requirement locks action has been dispatched.
   * Retrieves all requirement locks from the server and sets them.
   */
  retrieveRequirementLockRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RequirementLockActions.retrieveAllRequirementLocks),
      switchMap((action) =>
        this.requirementLockService.getRequirementLocks(action.projectId).pipe(
          map((requirementLocks: RequirementLock[]) =>
            RequirementLockActions.setAllRequirementLocks({
              requirementLocks
            })
          )
        )
      )
    )
  );

  /**
   * The effect that gets called when a create requirement lock request action has been dispatched.
   * Creates a new requirement lock with the data provided by the action.
   */
  createRequirementLockRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RequirementLockActions.createRequirementLockRequest),
      switchMap((action) =>
        this.requirementLockService
          .createRequirementLockOnServer(
            action.projectId,
            action.requirementLock
          )
          .pipe(
            map((requirementLock: RequirementLock) =>
              RequirementLockActions.createRequirementLockSuccess({
                requirementLock
              })
            ),
            catchError((error) =>
              of(RequirementLockActions.createRequirementLockFailure({ error }))
            )
          )
      )
    )
  );

  /**
   * The effect that gets called when a delete requirement lock request action has been dispatched.
   * Deletes the requirement lock with the data provided by the action.
   */
  deleteRequirementLockRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RequirementLockActions.deleteRequirementLockRequest),
      switchMap((action) =>
        this.requirementLockService
          .deleteRequirementLockOnServer(action.projectId, action.requirementId)
          .pipe(
            map(() =>
              RequirementLockActions.deleteRequirementLockSuccess({
                requirementId: action.requirementId
              })
            ),
            catchError((error) =>
              of(RequirementLockActions.deleteRequirementLockFailure({ error }))
            )
          )
      )
    )
  );

  /**
   * The effect that gets called when a retrieve all requirement locks action has been dispatched.
   * Retrieves all requirement locks from the server and sets them.
   */
  retrieveAllRequirementLocks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RequirementLockActions.retrieveAllRequirementLocks),
      switchMap((action) =>
        this.requirementLockService.getRequirementLocks(action.projectId).pipe(
          map((requirementLocks: RequirementLock[]) =>
            RequirementLockActions.setAllRequirementLocks({
              requirementLocks
            })
          )
        )
      )
    )
  );
}

<div id="page-content">
  <mat-tab-group id="login-tab">
    <mat-tab label="Login">
      <scone-login></scone-login>
    </mat-tab>
    <mat-tab label="Sign Up">
      <scone-sign-up></scone-sign-up>
    </mat-tab>
  </mat-tab-group>

  <mat-divider></mat-divider>

  <div class="feature-scroll">
    <div class="feature">
      <div fxFlex="50">
        <img class="logo" src="assets/logo-light-new.png" />
      </div>
      <div
        style="
          padding-top: -10px;
          font-weight: 300;
          margin-left: 20px;
          padding-right: 40px;
          margin-bottom: 40px;
          margin-top: auto;
          margin-bottom: auto;
        "
      >
        scone is an easy to use web application which enables you to manage
        requirements within projects. Furthermore it provides the option to
        enrich your requirements with variance informations.
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="feature">
      <div>
        <h2 class="feature-heading">Create Projects</h2>
        <p class="feature-description">
          <span style="font-weight: 400">scone</span> gives you the possibility
          to create your own projects and manage requirements within them. You
          can customize your project by selecting an icon and a color.
        </p>
      </div>
      <div>
        <img class="feature-image" src="assets/project-list.png" />
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="feature">
      <div>
        <h2 class="feature-heading">Collaborate in real-time</h2>
        <p class="feature-description">
          You can add as many project members to your project as you like and
          work together at the same time. Project members receive an immediate
          notification when changes are made.
        </p>
      </div>
      <div style="float: right; padding-top: 25px; width: 800px">
        <img src="assets/user-icon.png" style="width: 100px" />
        <img src="assets/user-icon.png" style="width: 90px" />
        <img src="assets/user-icon.png" style="width: 80px" />
        <img src="assets/user-icon.png" style="width: 70px" />
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="feature">
      <div style="width: 800px">
        <img class="feature-image" src="assets/create-requirement.png" />
      </div>
      <div>
        <h2 class="feature-heading">Create Requirements</h2>
        <p
          style="
            font-size: 1em;
            font-weight: 300;
            margin-left: 20px;
            padding-right: 40px;
            margin-top: 20px;
          "
        >
          Create Requirements with custom unique IDs and variance information.
        </p>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="feature">
      <div>
        <h2 class="feature-heading">Analyse your project</h2>
        <p
          style="
            font-size: 1em;
            font-weight: 300;
            margin-left: 20px;
            padding-right: 40px;
            margin-top: 20px;
          "
        >
          Leverage the advantages of the synergies of scone and
          <a target="_blank" href="https://glencoe.hochschule-trier.de"
            >Glencoe</a
          >.
        </p>
      </div>
      <div style="width: 800px">
        <img class="feature-image" src="assets/glencoe-logo-grey.png" />
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="feature">
      <div>
        <h2 class="feature-heading">Immediate Feedback</h2>
        <p class="feature-description">
          While creating a requirement and typing in an ID for your requirement,
          <span style="font-weight: 400">scone</span> will check against all
          other IDs in your project and will give you immediate feedback if this
          ID already exists.
        </p>
      </div>
      <div class="col-8">
        <img class="feature-image" src="assets/screen_6.png" />
      </div>
    </div>

    <mat-divider></mat-divider>
  </div>

  <mat-divider></mat-divider>

  <div class="legal">
    <a routerLink="license-information">License Information</a>
    <a routerLink="privacy-policy">Privacy Policy</a>
  </div>
</div>

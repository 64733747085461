import { Action, createReducer, on } from '@ngrx/store';
import { AppState, initialAppState } from './app.state';
import * as AppActions from './app.actions';

/**
 * The app reducers.
 */
const appReducer = createReducer(
  initialAppState,
  on(AppActions.retrieveAppData, (state) => ({
    ...state,
    isLoading: true,
    loaded: false,
    errorMessage: null
  })),
  on(AppActions.setAppData, (state, action) => ({
    ...state,
    projectIcons: action.appData.project_icons,
    processingStatus: action.appData.processing_status,
    priorities: action.appData.priorities,
    requirementTypes: action.appData.requirement_types,
    referenceTypes: action.appData.reference_types,
    requirementGroupTypes: action.appData.requirement_group_types,
    isLoading: false,
    loaded: true,
    errorMessage: null
  })),
  on(AppActions.retrieveAppDataFailure, (state, action) => ({
    ...initialAppState,
    errorMessage: action.error
  }))
);

/**
 * Used to invoke the app reducers with the given state and action.
 * @param state The current app state before the dispatched action.
 * @param action The dispatched action that will be used by the reducer to change the current app state.
 */
export function reducer(state: AppState | undefined, action: Action) {
  return appReducer(state, action);
}

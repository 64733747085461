import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreRoutingModule } from './core-routing.module';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { LicenseInformationComponent } from './license-information/license-information.component';
import { LoginComponent } from './login/login.component';
import { NavigationComponent } from './navigation/navigation.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { UserAccountComponent } from './user-account/user-account.component';
import { SharedModule } from '../shared/shared.module';
import { SnackbarComponent } from './snackbar/snackbar.component';

@NgModule({
  declarations: [
    LandingPageComponent,
    LicenseInformationComponent,
    LoginComponent,
    NavigationComponent,
    PageNotFoundComponent,
    PrivacyPolicyComponent,
    SignUpComponent,
    UserAccountComponent,
    SnackbarComponent
  ],
  imports: [CommonModule, SharedModule, CoreRoutingModule],
  exports: [NavigationComponent, SnackbarComponent]
})
export class CoreModule {}
